// @flow
import BeritAutocomplete from 'components/BeritAutocomplete'
import './BeritSelectObject.scss'

type BeritSelectObjectProps = {
  autoSelect?: boolean,
  className?: string,
  control: Control,
  disableClearable?: boolean,
  emptyOption?: {
    [key: string]: any,
  },
  getOptionDisabled?: any => boolean,
  label?: string,
  name: string,
  noOptionsText?: string,
  options: any[],
  rules?: string | Rules,
  selectOnFocus?: boolean,
  variant?: 'standard' | 'outlined' | 'filled',
}

const BeritSelectObject = ({
  autoSelect = false,
  className = '',
  control,
  disableClearable = true,
  emptyOption,
  getOptionDisabled = () => false,
  label,
  name,
  noOptionsText,
  options,
  rules,
  selectOnFocus = false,
  variant = 'standard',
  ...beritAutocompleteProps
}: BeritSelectObjectProps) => {
  return (
    <BeritAutocomplete
      {...beritAutocompleteProps}
      autoSelect={autoSelect}
      className={`BeritSelectObject ${className}`}
      control={control}
      disableClearable={disableClearable}
      emptyOption={emptyOption}
      getOptionDisabled={getOptionDisabled}
      label={label}
      name={name}
      noOptionsText={noOptionsText}
      options={options}
      selectOnFocus={selectOnFocus}
      rules={rules}
    />
  )
}

export default (BeritSelectObject: BeritSelectObjectProps => React$Node)
