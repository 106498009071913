// @flow
import { MDBRow } from 'mdbreact/dist/mdbreact.esm'

import DireccionFormContainer from './DireccionFormContainer'
import AddButton from 'components/AddButton'

import './DirectionFormContainer.scss'

type DireccionesProps = {
  type: 'rfc' | 'sucursal',
  form: Form,
  label: string,
}

const direccionInicial = {
  calleAvenida: '',
  colonia: '',
  cp: '',
  delMun: '',
  edificioPlaza: '',
  estado: '',
  indicaciones: '',
  nombre: '',
  numExt: '',
  numInt: '',
  pais: 'México',
  piso: '',
}

const inicial = {
  sucursal: {
    ...direccionInicial,
    sucursalId: 0,
  },
  rfc: {
    ...direccionInicial,
    rfc: '',
    rfcId: 0,
    razonSocial: '',
  },
}
const Direcciones = ({ type, form, label }: DireccionesProps) => {
  const { setValue, watch, control } = form
  const direcciones = watch(type)

  const addItem = () => {
    setValue(type, [...direcciones, inicial[type]])
  }
  return (
    <div className="DirectionFormHandler">
      <MDBRow style={{ display: 'flex', justifyContent: 'space-between' }} className="m-1">
        <h5>
          <strong>{label}</strong>
        </h5>
        <AddButton label={`Agregar ${label}`} action={addItem} />
      </MDBRow>
      <div className="direccionesDiv">
        {direcciones?.map((direccion, index) => (
          <DireccionFormContainer
            key={index}
            control={control}
            index={index}
            direccion={direccion}
            type={type}
            // onRemove={() => removeItem(dir.reference)}
          />
        ))}
      </div>
    </div>
  )
}

export default (Direcciones: DireccionesProps => React$Node)
