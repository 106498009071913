// @flow
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import { Link as RRLink } from 'react-router-dom'

type AddButtonProps = {
  action?: (e: any) => void,
  label: string,
  to?: string,
  size?: string,
}

const AddButton = ({ action, label, to, size = 'fa-2x' }: AddButtonProps) => {
  return (
    <>
      {to ? (
        <Tooltip title={label} arrow>
          <RRLink to={to} className="text-white">
            <i className="fas fa-plus-circle fa-2x" />
          </RRLink>
        </Tooltip>
      ) : (
        <Tooltip title={label} arrow>
          <Link style={{ color: 'rgb(43,72,230)' }} onClick={action}>
            <i className={`fas fa-plus-circle ${size}`} />
          </Link>
        </Tooltip>
      )}
    </>
  )
}

/**
 * == AddButton Props ===
 * - action: This prop is the method that will be executed when the button gonna be clicked(must be a Function)
 * - label: Text that is showed on the tooltip(must be a string)
 * - to: This prop is the path to follow when the button gonna be clicked:
 * e.g.: '/admin/cotizaciones/crear' (must be a string)
 * - size: The size for the button(must be a string)
 */
export default (AddButton: AddButtonProps => React$Node)
