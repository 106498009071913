// @flow
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Controller } from 'react-hook-form'
import './BeritSelect.scss'

type BeritSelectProps = {
  className?: string,
  control: Control,
  defaultValue?: string | number,
  fullWidth?: boolean,
  includeEmpty?: boolean,
  label?: string,
  name: string,
  options: {
    text: string,
    value: any,
  }[],
  rules?: string | Rules,
  variant?: 'standard' | 'outlined' | 'filled',
}

const BeritSelect = ({
  className = '',
  control,
  defaultValue,
  fullWidth = true,
  label,
  name,
  options,
  rules,
  includeEmpty = false,
  variant = 'standard',
  ...beritSelectProps
}: BeritSelectProps) => (
  <Controller
    control={control}
    defaultValue={defaultValue}
    name={name}
    rules={typeof rules === 'string' ? { required: rules } : rules}
    render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => {
      return (
        <TextField
          className={`BeritSelect ${className}`}
          error={invalid}
          fullWidth={fullWidth}
          helperText={error && error.message}
          label={label}
          inputRef={ref}
          select
          variant={variant}
          {...field}
          {...beritSelectProps}
        >
          {includeEmpty && <MenuItem value={rules?.valueAsNumber ? 0 : ''} />}
          {options.map((val, idx) => (
            <MenuItem key={idx} value={val.value}>
              {val.text}
            </MenuItem>
          ))}
        </TextField>
      )
    }}
  />
)

export default (BeritSelect: BeritSelectProps => React$Node)
