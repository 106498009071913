// @flow

import { CLEAN_SUCCESS } from 'redux/constants'

type SuccessReducerTypes = SuccessState

export type SuccessAction = {|
  +type: string,
|}

const successReducer = (state: SuccessState = {}, action: SuccessAction) => {
  const { type } = action
  const matches = /(.*)_(SUCCESS)/.exec(type)

  if (type === CLEAN_SUCCESS) {
    return {}
  }

  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    [requestName]: requestState === 'SUCCESS',
  }
}

export default (successReducer: (state: SuccessState, action: SuccessAction) => SuccessReducerTypes)
