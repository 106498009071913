// @flow
import Typography from '@material-ui/core/Typography'
import { MDBCardHeader, MDBCol, MDBContainer, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import BeritCrearEditCards from './BeritCrearEditCards'
import BeritInput from './BeritInput'
import BeritProgressButton from './BeritProgressButton'
import CreateEntitySkeleton from './CreateEntitySkeleton'
import Direcciones from './Direcciones'
import VolverA from './VolverA'
import { getLoading } from 'redux/selectors'
import { numberUtils, useAdd, useGetById, useToast, useUpdate } from 'utils'

const ProveedoresCrear = () => {
  const toast = useToast()
  const { id } = useParams()
  const proveedorId = id !== undefined ? parseInt(id) : -1
  const isEdit = proveedorId > 0
  const proveedorAdd = useAdd('proveedores')
  const proveedorGetById = useGetById('proveedores')
  const proveedorUpdate = useUpdate('proveedores')
  const isFetching = useSelector(getLoading(['PROVEEDOR_GETBYID']))
  const form = useForm({
    defaultValues: {
      nombreComercial: '',
      giro: '',
      rfc: [],
      sucursal: [],
    },
  })
  const { handleSubmit, control, reset, setValue } = form

  useEffect(() => {
    if (isEdit) {
      proveedorGetById(proveedorId)
        .then(proveedor => {
          const proveedorEdited = {
            ...proveedor,
            sucursal: proveedor.sucursal.map(sucursal => ({
              ...sucursal,
              cp: numberUtils.zeroFill(sucursal.cp, 5),
            })),
          }
          reset(proveedorEdited)
        })
        .catch(err => {
          toast(`ERROR: ID no encontrado en la base de datos`, err)
        })
    } else {
      setValue('rfc', [])
      setValue('sucursal', [])
    }
    return () => {
      reset({})
    }
  }, [proveedorId, isEdit, reset, proveedorGetById, setValue, toast])

  const onSubmit = (proveedorForm: ProveedorForm) => {
    if (isEdit) {
      proveedorUpdate(proveedorId, proveedorForm)
    } else {
      proveedorAdd(proveedorForm)
    }
  }

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  if (isFetching) {
    return <CreateEntitySkeleton />
  }

  const tabsContent = {
    general: {
      label: 'Información general',
      children: (
        <>
          <MDBRow>
            <MDBCol md="6">
              <BeritInput
                control={control}
                label="* Nombre del proveedor"
                name="nombreComercial"
                rules="El nombre es obligatorio"
              />
            </MDBCol>
            <MDBCol md="6">
              <BeritInput control={control} name="giro" label="Giro" />
            </MDBCol>
          </MDBRow>
        </>
      ),
    },
    sucursales: {
      children: <Direcciones label="Sucursales" type="sucursal" form={form} />,
    },
    infoFiscal: {
      children: <Direcciones label="Información fiscal" type="rfc" form={form} />,
    },
  }

  return (
    <>
      <MDBRow>
        <VolverA label="Proveedores" to="/admin/proveedores" />
      </MDBRow>
      <MDBContainer>
        <MDBCardHeader className="blue-gradient d-flex justify-content-center align-items-center py-2 mt-3">
          <Typography className="white-text" variant="h5" align="center">
            Proveedores
          </Typography>
        </MDBCardHeader>
        <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
          <BeritCrearEditCards content={tabsContent} />

          <div className="text-center mt-5">
            <BeritProgressButton
              label={isEdit ? 'Editar proveedor' : 'Crear proveedor'}
              entityName="proveedor"
              type="submit"
            />
          </div>
        </form>
      </MDBContainer>
    </>
  )
}

export default (ProveedoresCrear: () => React$Node)
