// @flow
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import AccordionFrame from './AccordionFrame'
import BeritCard from 'components/BeritCard'
import BeritCheckbox from 'components/BeritCheckbox'
import BeritDatePicker from 'components/BeritDatePicker'
import BeritInput from 'components/BeritInput'
import { useToast } from 'utils'

const EnviosEntregasAccordion = () => {
  const [enviosEntregasAcc, setEnviosEntregasAcc] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      lugarDeEntrega: '',
      personaQRecibe: '',
      fechaEnvioCliente: moment(),
      mensajeriaBerit: '',
      remisionesEscaneadas: '',
      paqueteria: '',
      noGuia: '',
      confirmacionAutorizacionEntrega: '',
      entregaAlCliente: '',
      recepcionMaterialAriba: '',
    },
  })
  const toast = useToast()

  const onSubmit = enviosEntregas => console.warn(enviosEntregas)

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  return (
    <AccordionFrame
      accordion={enviosEntregasAcc}
      toggleCollapse={() => setEnviosEntregasAcc(!enviosEntregasAcc)}
      header="Envios/Entregas"
    >
      <BeritCard classes="mb-5">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="lugarDeEntrega"
                label="Lugar de entrega"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="personaQRecibe"
                label="Persona que recibe"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="Esta fecha es obligatoria"
                name="fechaEnvioCliente"
                label="Envio al cliente"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="mensajeriaBerit"
                label="Mensajería Berit"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="remisionesEscaneadas"
                label="Remisiones escaneadas"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="paqueteria"
                label="Paquetería"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="noGuia"
                label="No. de Guia"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritCheckbox
                control={control}
                name="confirmacionAutorizacionEntrega"
                label="Confirmación y autorización de entrega"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="Esta fecha es obligatoria"
                name="entregaAlCliente"
                label="Entrega al cliente"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="Esta fecha es obligatoria"
                name="recepcionMaterialAriba"
                label="No. recepcion de material en ariba"
                type="number"
              />
            </MDBCol>
          </MDBRow>
          <div className="d-flex justify-content-center">
            <MDBBtn type="submit" color="blue">
              Guardar
            </MDBBtn>
          </div>
        </form>
      </BeritCard>
    </AccordionFrame>
  )
}

export default (EnviosEntregasAccordion: () => React$Node)
