// @flow
import { useDispatch } from 'react-redux'

import { MODAL_IMG_SET, MODAL_IMG_TOGGLE } from 'redux/constants'
import { configUtils } from 'utils'
import { productoImg } from 'utils/cotizacionesUtils'

type ImageThumbnailTableCellProps = {
  row: {
    proveedor: {
      id: number,
    },
    imagenes: Imagen[],
    imagen: string,
  },
}

const ImageThumbnailTableCell = ({ row }) => {
  const dispatch = useDispatch()

  const handleToggleModal = () => {
    dispatch({
      type: MODAL_IMG_SET,
      payload: {
        src: `${configUtils.createConfig().beritImg}${row.proveedor.id}/${row.imagenes[0].imagen}`,
      },
    })
    dispatch({ type: MODAL_IMG_TOGGLE })
  }

  return (
    <img
      style={{ height: '30px', width: '30px', borderRadius: '8px' }}
      title={productoImg(row.imagen && `${row.proveedor.id}/${row.imagenes[0].imagen}`)}
      className="beritLazyLoadImg clickable"
      onClick={handleToggleModal}
    />
  )
}

export default (ImageThumbnailTableCell: ImageThumbnailTableCellProps => React$Node)
