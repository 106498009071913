import Skeleton from '@material-ui/core/Skeleton'
import Typography from '@material-ui/core/Typography'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact/dist/mdbreact.esm'

const LiveSearchSkeleton = () => {
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol size="auto">
          <Skeleton animation="wave" width="150px" height="235px" style={{ marginTop: '-45px' }} />
        </MDBCol>
        <MDBCol>
          <Typography variant="h5">
            <Skeleton width="20%" />
          </Typography>
          <Skeleton animation="wave" width="100%" height="140px" />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="auto">
          <Skeleton animation="wave" width="150px" height="235px" style={{ marginTop: '-45px' }} />
        </MDBCol>
        <MDBCol>
          <Typography variant="h5">
            <Skeleton width="20%" />
          </Typography>
          <Skeleton animation="wave" width="100%" height="140px" />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="auto">
          <Skeleton animation="wave" width="150px" height="235px" style={{ marginTop: '-45px' }} />
        </MDBCol>
        <MDBCol>
          <Typography variant="h5">
            <Skeleton width="20%" />
          </Typography>
          <Skeleton animation="wave" width="100%" height="140px" />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default LiveSearchSkeleton
