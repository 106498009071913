// @flow
import Skeleton from '@material-ui/core/Skeleton'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact/dist/mdbreact.esm'

type PedidoCotizacionEditarSkeletonProps = {
  withHeader?: boolean,
}

const PedidoCotizacionEditarSkeleton = ({
  withHeader = false,
}: PedidoCotizacionEditarSkeletonProps) => {
  return (
    <MDBContainer>
      {withHeader && (
        <MDBRow>
          <MDBCol>
            <Skeleton animation="wave" height={140} />
          </MDBCol>
        </MDBRow>
      )}
      <MDBRow>
        <MDBCol>
          <Skeleton variant="rect" animation="wave" height={190} />
          <Skeleton className="mt-3" variant="rect" animation="wave" height={150} width={300} />
        </MDBCol>
        <MDBCol>
          <Skeleton variant="rect" animation="wave" height={70} />
          <Skeleton variant="rect" animation="wave" height={140} className="float-rigth mt-3" />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default (PedidoCotizacionEditarSkeleton: PedidoCotizacionEditarSkeletonProps => React$Node)
