// @flow
import { createSelector } from 'reselect'

import { getClientes, getContactos, getSucursales } from './apiSelectors'
import { getCotizacionesState } from './stateSelectors'

export const getCotizaciones: State => CotizacionesState = createSelector(
  getCotizacionesState,
  cotizaciones => cotizaciones
)

export const getCotizacionBusqueda: State => Busqueda = createSelector(
  getCotizaciones,
  cotizaciones => cotizaciones.busqueda
)

export const getCotizacion: State => Cotizacion = createSelector(
  getCotizaciones,
  cotizaciones => cotizaciones.cotizacion
)

export const getCotizacionProductos: State => ProductoCotizacion[] = createSelector(
  getCotizacion,
  cotizacion => cotizacion.productos
)

export const getCotizacionProductoByBsku: (bsku: string) => State => ?ProductoCotizacion = bsku =>
  createSelector(getCotizacionProductos, cotizacionProductos => {
    const cotizacionProducto = cotizacionProductos.find(
      cotizacionProducto => cotizacionProducto.bsku === bsku
    )
    return cotizacionProducto
  })

export const getCotizacionContacto: State => ?Contacto = createSelector(
  getCotizacion,
  getContactos,
  (cotizacion, contactos) =>
    contactos.find(contacto => contacto.contactoId === cotizacion.contactoId)
)

export const getCotizacionCliente: State => ?Cliente = createSelector(
  getCotizacion,
  getClientes,
  (cotizacion, clientes) => clientes.find(cliente => cliente.clienteId === cotizacion.clienteId)
)

export const getCotizacionSucursal: State => ?Sucursal = createSelector(
  getCotizacion,
  getSucursales,
  (cotizacion, sucursales) =>
    sucursales.find(sucursal => sucursal.sucursalId === cotizacion.sucursalId)
)
