// @flow
import { useState } from 'react'

import AccordionFrame from './../AccordionFrame'
import CostoMaquilero from './CostoMaquilero'
import CostoProveedor from './CostoProveedor'
import CostoTotal from './CostoTotal'

const CostosAccordion = () => {
  const [costosAcc, setCostosAcc] = useState(false)

  return (
    <AccordionFrame
      accordion={costosAcc}
      toggleCollapse={() => setCostosAcc(!costosAcc)}
      header="Costos"
    >
      <CostoProveedor />

      <CostoMaquilero />

      <CostoTotal />
    </AccordionFrame>
  )
}

export default (CostosAccordion: () => React$Node)
