// @flow
import { MDBCol, MDBInputGroup, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MaquilerosFieldsProductRow from './MaquilerosFieldsProductRow'
import BeritPopper from 'components/BeritPopper'
import {
  COSTOS_TECNICAS,
  UPDATE_CALCULADORA_HEADER,
  UPDATE_CALCULADORA_PU_VARIANTES,
} from 'redux/constants'
import { getCotizacionProductoByBsku } from 'redux/selectors'

type CotizacionHeaderCalcProps = {
  bsku: string,
}

const CotizacionHeaderCalc = ({ bsku }: CotizacionHeaderCalcProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElPrecioSugerido, setAnchorElPrecioSugerido] = useState(null)
  const dispatch = useDispatch()
  const { costoProveedor, costoTecnica, porcentaje, numeroTintas } = useSelector(
    getCotizacionProductoByBsku(bsku)
  )

  const updateCalculadoraPrecioUnitarioVariants = value =>
    dispatch({
      type: UPDATE_CALCULADORA_PU_VARIANTES,
      value,
      bsku,
    })

  const updateCalculadora = key => e =>
    dispatch({
      type: UPDATE_CALCULADORA_HEADER,
      key,
      value: Number(e.target.value),
      bsku,
    })

  const costos = costoProveedor ? costoProveedor + costoTecnica : 0
  const precioSugerido1 = costos + (costos * (porcentaje ?? 0)) / 100
  const precioSugerido = precioSugerido1 + costoTecnica * numeroTintas
  const Precio = precio => (Math.round(precio * 100) / 100).toFixed(2)

  const handlePopperOpen = (event, isTecnica = true) =>
    isTecnica ? setAnchorEl(event.currentTarget) : setAnchorElPrecioSugerido(event.currentTarget)
  const handlePopperClose = (event, isTecnica = true) =>
    isTecnica ? setAnchorEl(null) : setAnchorElPrecioSugerido(null)

  return costoProveedor ? (
    <>
      <MDBRow className="my-2 text-center align-items-baseline Calculadora">
        <MDBCol>
          <MDBInputGroup
            label={'Costo proveedor'}
            labelClassName="with-label"
            containerClassName="my-0"
            className="text-center"
            prepend="(&nbsp;$"
            append="&nbsp;+&nbsp;"
            type="number"
            material
            onChange={updateCalculadora('costoProveedor')}
            value={costoProveedor + ''}
          />
        </MDBCol>
        <MDBCol onMouseEnter={handlePopperOpen} onMouseLeave={handlePopperClose}>
          <MDBInputGroup
            label={'Costo técnica'}
            labelClassName="with-label"
            containerClassName="my-0"
            className="text-center"
            prepend="&nbsp;&nbsp;$"
            append="&nbsp;)&nbsp;"
            type="number"
            material
            onChange={updateCalculadora('costoTecnica')}
            value={costoTecnica + ''}
          />
          <BeritPopper anchorEl={anchorEl} height="150px">
            {COSTOS_TECNICAS.map(({ nombre, precio, id }) => (
              <div key={id}>
                <span>
                  {nombre} - ${precio.toFixed(2)}
                </span>
                <br />
              </div>
            ))}
          </BeritPopper>
        </MDBCol>
        <div className="w-100 d-block d-lg-none" />
        <MDBCol>
          <MDBInputGroup
            label={'Porcentaje'}
            labelClassName="with-label"
            containerClassName="my-0"
            className="text-center"
            prepend="&nbsp;+&nbsp;"
            append="%&nbsp;&nbsp;"
            type="number"
            material
            onChange={updateCalculadora('porcentaje')}
            value={porcentaje + ''}
          />
        </MDBCol>
        <MDBCol
          onMouseEnter={e => handlePopperOpen(e, false)}
          onMouseLeave={e => handlePopperClose(e, false)}
        >
          <MDBInputGroup
            label={'Precio sugerido'}
            labelClassName=" with-label"
            containerClassName="mb-3 mt-0"
            className="text-center copy-precio-unitario"
            style={{ cursor: 'pointer' }}
            prepend="=&nbsp;$"
            append={<i style={{ color: 'rgb(36,130,220)' }} className="fas fa-question-circle" />}
            type="number"
            material
            disabled
            onClick={() => {
              updateCalculadoraPrecioUnitarioVariants(precioSugerido.toFixed(2))
            }}
            value={Precio(precioSugerido)}
          />
          <BeritPopper
            anchorEl={anchorElPrecioSugerido}
            isClickable
            onClick={() => {
              updateCalculadoraPrecioUnitarioVariants(precioSugerido)
            }}
          >
            <span>Click para copiar precio</span>
          </BeritPopper>
        </MDBCol>
        <MDBCol className="d-none d-lg-block" />
      </MDBRow>
      <MaquilerosFieldsProductRow bsku={bsku} isCotizacion />
    </>
  ) : (
    <></>
  )
}

export default (CotizacionHeaderCalc: CotizacionHeaderCalcProps => React$Node)
