// @flow
import localforage from 'localforage'
import ReactDOM from 'react-dom'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import ReduxThunk from 'redux-thunk'

import './assets/index.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

import App from './App'
import rootReducer from 'redux/reducers'
// import * as serviceWorker from './serviceWorker'

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage: localforage,
    blacklist: ['api', 'loading', 'success', 'errores'],
  },
  rootReducer
)

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(ReduxThunk)))

const persistor = persistStore(store)

const rootElement = document.getElementById('root')

if (rootElement !== null) {
  ReactDOM.render(<App store={store} persistor={persistor} />, rootElement)
}

// serviceWorker.unregister()
