// @flow
import { MDBBtn, MDBIcon, MDBTooltip } from 'mdbreact/dist/mdbreact.esm'
import { Link } from 'react-router-dom'

import { stringUtils } from 'utils'

type StandardEntityTableCellComponentProps = {
  entity: string,
  customPath?: string,
  id: number,
}

const StandardEntityTableCellComponent = ({
  entity,
  customPath = 'editar',
  id,
}: StandardEntityTableCellComponentProps) => {
  return (
    <div className="actions-container">
      <Link to={`/admin/${entity}/${customPath}/${id}`}>
        <MDBTooltip placement="top">
          <MDBBtn color="primary" floating size="sm">
            <MDBIcon icon="eye" />
          </MDBBtn>
          <div>
            <span>Ver {stringUtils.singularize(entity)}</span>
          </div>
        </MDBTooltip>
      </Link>
      {entity === 'pedidos' && (
        <Link to={`/admin/pedidos/seguimiento/${id}`}>
          <MDBTooltip placement="top">
            <MDBBtn color="warning" floating size="sm">
              <MDBIcon icon="edit" />
            </MDBBtn>
            <div>
              <span>Seguimiento</span>
            </div>
          </MDBTooltip>
        </Link>
      )}
    </div>
  )
}

export default (StandardEntityTableCellComponent: StandardEntityTableCellComponentProps => React$Node)
