// @flow
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import AccordionFrame from './AccordionFrame'
import BeritCard from 'components/BeritCard'
import BeritCheckbox from 'components/BeritCheckbox'
import BeritDatePicker from 'components/BeritDatePicker'
import BeritInput from 'components/BeritInput'
import { useToast } from 'utils'

const ProduccionMaquilaAccordion = () => {
  const [prodMaquila, setProdMaquila] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tallerProduccion: '',
      compraDeTela: '',
      corteTerminado: '',
      bordadoTerminado: '',
      costConfeccionTerminado: '',
      lavadoTerminado: '',
      fechaProductoTerminado: '',
    },
  })
  const toast = useToast()

  const onSubmit = produccionMaquila => console.warn(produccionMaquila)

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  return (
    <AccordionFrame
      accordion={prodMaquila}
      toggleCollapse={() => setProdMaquila(!prodMaquila)}
      header="Producción Maquila"
    >
      <BeritCard classes="mb-5">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules={'Este campo es obligatorio'}
                name="tallerProduccion"
                label="Taller para producción"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritCheckbox control={control} name="compraDeTela" label="Compra de tela" />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritCheckbox control={control} name="corteTerminado" label="Corte terminado" />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritCheckbox control={control} name="bordadoTerminado" label="Bordado terminado" />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritCheckbox
                control={control}
                name="costConfeccionTerminado"
                label="Costura/Confección terminado"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritCheckbox control={control} name="lavadoTerminado" label="Lavado terminado" />
            </MDBCol>
          </MDBRow>
          <BeritDatePicker
            control={control}
            errors={errors}
            rules={'Esta fecha es obligatoria'}
            name="fechaProductoTerminado"
            label="Fecha de producto terminado"
          />
          <div className="d-flex justify-content-center">
            <MDBBtn type="submit" color="blue">
              Guardar
            </MDBBtn>
          </div>
        </form>
      </BeritCard>
    </AccordionFrame>
  )
}

export default (ProduccionMaquilaAccordion: () => React$Node)
