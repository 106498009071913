// @flow
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import AccordionFrame from './AccordionFrame'
import BeritCard from 'components/BeritCard'
import BeritCheckbox from 'components/BeritCheckbox'
import BeritDatePicker from 'components/BeritDatePicker'
import { useToast } from 'utils'

const DisenoAccordion = () => {
  const [disenoAcc, setDisenoAcc] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      envioDisenoCliente: '',
      autorizacionDiseno: '',
      inicioProduccion: moment(),
      produccionAMaquila: '',
      envioOrdenProdTallerBerit: '',
    },
  })
  const toast = useToast()

  const onSubmit = e => console.warn(e)

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  return (
    <AccordionFrame
      accordion={disenoAcc}
      toggleCollapse={() => setDisenoAcc(!disenoAcc)}
      header="Diseño"
    >
      <BeritCard classes="mb-4">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol md="6" xs="12">
              <BeritCheckbox
                control={control}
                name="envioDisenoCliente"
                label="Envío de diseño al cliente"
              />
            </MDBCol>

            <MDBCol md="6" xs="12">
              <BeritCheckbox
                control={control}
                name="autorizacionDiseno"
                label="Autorización del diseño"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="La fecha es obligatoria"
                name="inicioProduccion"
                label="Inicio de producción"
              />
            </MDBCol>
            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="La fecha es obligatoria"
                name="produccionAMaquila"
                label="Envio de producción a maquila"
              />
            </MDBCol>
          </MDBRow>
          <BeritDatePicker
            control={control}
            errors={errors}
            rules="La fecha es obligatoria"
            name="envioOrdenProdTallerBerit"
            label="Envío de orden de producción taller-Berit"
          />
          <div className="d-flex justify-content-center">
            <MDBBtn type="submit" color="blue">
              Guardar
            </MDBBtn>
          </div>
        </form>
      </BeritCard>
    </AccordionFrame>
  )
}

export default (DisenoAccordion: () => React$Node)
