// @flow
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from 'mdbreact/dist/mdbreact.esm'
import 'moment/locale/es-mx'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'

import BeritCard from './BeritCard'
import CotizacionVerProductoImage from './CotizacionVerProductoImage'
import FooterVer from './FooterVer/index'
import HeaderVer from './HeaderVer'
import ItemNotFound from './ItemNotFound'
import LiveSearchInputSkeleton from './LiveSearchInputSkeleton'
import PedidoCotizacionEditarSkeleton from './PedidoCotizacionEditarSkeleton'
import VolverA from './VolverA'
import Copyrights from 'components/Footer'
import VariantsVer from 'components/PedidoVer/VariantsVer'
import VerTotal from 'components/VerTotal'
import {
  COTIZACION_GETBYID_ERROR,
  COTIZACION_GETBYID_REQUEST,
  COTIZACION_GETBYID_SUCCESS,
  COTIZACIONES_SET_COTIZACION,
} from 'redux/constants'
import { getAuthState, getCotizacion, getLoading } from 'redux/selectors'
import { useGetByHash, useGetById } from 'utils'
import './CotizacionHeader.scss'
import './CotizacionVer.scss'
import './CotizacionCondicionesComerciales.scss'

const CotizacionDraft = () => {
  const { cotizacionId, cotizacionHash } = useParams()
  const cotizacion = useSelector(getCotizacion)
  const cotizacionGetById = useGetById('cotizaciones')
  const { getByHash, errorMessage } = useGetByHash('cotizaciones')
  const dispatch = useDispatch()
  const [responseError, setResponseError] = useState('')
  const auth = useSelector(getAuthState, shallowEqual)
  const isLoading = useSelector(
    getLoading(['COTIZACION_GETALL', 'COTIZACION_GETBYID', 'COTIZACION_GETBYHASH'])
  )

  useEffect(() => {
    if (cotizacionHash) {
      getByHash(+cotizacionHash)
      errorMessage && setResponseError(errorMessage)
    } else {
      dispatch({ type: COTIZACION_GETBYID_REQUEST })

      cotizacionGetById(cotizacionId)
        .then(cotizacion => {
          dispatch({
            type: COTIZACIONES_SET_COTIZACION,
            cotizacion: cotizacion,
          })
          dispatch({ type: COTIZACION_GETBYID_SUCCESS })
        })
        .catch(err => {
          dispatch({ type: COTIZACION_GETBYID_ERROR })
          setResponseError(err.description)
        })
    }
  }, [dispatch, auth, cotizacionHash, cotizacionId, cotizacionGetById, errorMessage, getByHash])

  const cotizacionNumero = cotizacion?.cotizacionNumero

  const currentCotizacion: any =
    cotizacion?.cotizacionJson && JSON.parse(cotizacion?.cotizacionJson)

  const cotizacionProductos: any =
    typeof currentCotizacion.productosJson !== 'undefined' &&
    JSON.parse(currentCotizacion.productosJson)

  if (responseError || (cotizacionHash && cotizacionHash?.length !== 10)) {
    return <ItemNotFound error={responseError} />
  }

  if (isLoading) {
    return (
      <MDBContainer className="mt-5">
        <MDBRow>
          <MDBCol size="12">
            <PedidoCotizacionEditarSkeleton withHeader />
          </MDBCol>
          <MDBCol size="12">
            <LiveSearchInputSkeleton />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }

  return (
    <div className="CotizacionVer">
      {cotizacionId && (
        <MDBRow className="mb-3">
          <VolverA label="Cotizaciones" to="/admin/cotizaciones" />
        </MDBRow>
      )}
      <section className="mb-4 print-topbar">
        <MDBCard>
          <MDBCardBody className="d-flex justify-content-end">
            <div>
              <MDBBtn color="primary" onClick={() => window.print()}>
                <MDBIcon icon="print" className="left" /> Imprimir / Guardar PDF
              </MDBBtn>
            </div>
          </MDBCardBody>
        </MDBCard>
      </section>

      <section className="container">
        {currentCotizacion && (
          <HeaderVer cotizacion={currentCotizacion} cotizacionNumero={cotizacionNumero} />
        )}

        <>
          {cotizacionProductos &&
            cotizacionProductos.map((producto, idx) => {
              const cleanedHtml = sanitizeHtml(producto.productoDescripcion, {
                allowedAttributes: {
                  span: ['style'],
                  div: ['style'],
                  p: ['style'],
                  strong: ['style'],
                  h1: ['style'],
                  h2: ['style'],
                  h3: ['style'],
                  h4: ['style'],
                  h5: ['style'],
                  h6: ['style'],
                },
              })

              const createMarkup = () => {
                return { __html: cleanedHtml }
              }

              const getTotals = key => {
                const totalByItem: any =
                  producto.variantes &&
                  producto.variantes
                    .map(variante =>
                      key === 'cantidad' ? variante.cantidad : variante.cantidad * variante[key]
                    )
                    .reduce((acc, curr) => acc + curr)

                const total =
                  totalByItem.length > 0
                    ? Number(totalByItem.reduce((acc, curr) => acc + curr)).toFixed(2)
                    : totalByItem

                return +total
              }

              const importeFinal = getTotals('precioUnitario')
              const cantidadTotal = getTotals('cantidad')

              return (
                <BeritCard key={idx}>
                  <div className="ProductoCotizacionCard">
                    <section className="item">
                      <div className="item-image" size="12" md="2">
                        <CotizacionVerProductoImage producto={producto} />
                      </div>
                      <div className="item-text" size="6" md="5">
                        <h5>Item #{idx + 1}</h5>
                        <hr />
                        <h5 className="dark-grey-text">
                          <strong>{producto.bsku}</strong>
                        </h5>
                        <div dangerouslySetInnerHTML={createMarkup()} />
                      </div>
                    </section>
                    <MDBRow className="mt-3 mb-3 text-center justify-content-around">
                      {cotizacionId && (
                        <MDBCol sm="6" md="3">
                          <strong>Maquilero: </strong>
                          <span>{producto.maquilero?.nombreComercial || 'N/A'}</span>
                        </MDBCol>
                      )}

                      {producto.tecnica && (
                        <MDBCol sm="6" md="3">
                          <strong>Técnica de decorado: </strong>
                          <span>{producto.tecnica || 'N/A'}</span>
                        </MDBCol>
                      )}
                      {producto.posicionDecorado && (
                        <MDBCol sm="6" md="3">
                          <strong>Posición de decorado: </strong>
                          <span>{producto.posicionDecorado || 'N/A'}</span>
                        </MDBCol>
                      )}

                      <MDBCol sm="6" md="3">
                        <strong>Núm. tintas: </strong>
                        <span>{producto.numeroTintas}</span>
                      </MDBCol>
                    </MDBRow>
                    <VariantsVer
                      variantes={producto.variantes}
                      isCotizacion
                      cantidadVariantes={cantidadTotal}
                      total={importeFinal}
                    />
                  </div>
                </BeritCard>
              )
            })}
        </>
      </section>

      <section
        style={{ marginButtom: cotizacionHash !== undefined ? '250px' : '' }}
        className="container"
      >
        <BeritCard>
          <VerTotal productos={cotizacionProductos} isCotizacion />
          <p className="customMutedText text-right">Atentamente</p>
          <p className="vendedorSign text-right">{currentCotizacion?.vendedor?.nombre}</p>
          <p className="vendedorPuesto text-right">{currentCotizacion?.vendedor?.puesto}</p>
        </BeritCard>
      </section>

      {cotizacion && (
        <FooterVer
          cotizacion={currentCotizacion}
          id={cotizacionId}
          hash={cotizacion?.hash}
          status={cotizacion.status}
        />
      )}
      {cotizacionHash && (
        <Copyrights
          style={{
            position: 'fixed',
            width: '100%',
          }}
          className="publicFooter"
          isPublic
        />
      )}
    </div>
  )
}

export default CotizacionDraft
