// @flow
import Modal from '@material-ui/core/Modal'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { productoImg } from 'utils/cotizacionesUtils'

import './BeritLighboxComponent.scss'

type BeritLightboxComponentProps = {
  images: string[],
  handleLightboxState: (isOpen: boolean) => void,
  lightBoxState: boolean,
  autoplay?: boolean,
}

const modalStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.65)',
}

const BeritLightboxComponent = ({
  images,
  handleLightboxState,
  lightBoxState,
  autoplay = false,
}: BeritLightboxComponentProps) => {
  const handleClose = () => handleLightboxState(false)
  const isASingleImage = images.length === 1

  const settings = {
    dots: !isASingleImage,
    infinite: !isASingleImage,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div className="BeritLightboxComponent">
      <Modal style={{ ...modalStyles }} open={lightBoxState} onClose={handleClose}>
        <div
          style={{
            position: 'absolute',
            width: '45%',
          }}
        >
          <Slider
            fade
            autoplay={autoplay}
            arrows={!isASingleImage}
            focusOnSelect
            accessibility
            centerMode
            adaptiveHeight
            {...settings}
          >
            {!isASingleImage ? (
              images.map(image => (
                <div key={image}>
                  <img src={productoImg(image)} style={{ width: '100%', height: '100%' }} />
                </div>
              ))
            ) : (
              <div>
                <img src={productoImg(images[0])} style={{ width: '100%', height: '100%' }} />
              </div>
            )}
          </Slider>
        </div>
      </Modal>
    </div>
  )
}

/**
 * == BeritLightboxComponent Props ===
 * - images: Array of strings that are the images path, e.g: ['path/image', 'path/otherImage'], [Array].
 * - handleLightboxState: This is the setState for the lightbox state, this determinates when is open or closed[Function].
 * - lightBoxState: This is the state for the ligthbox, if its true, it will be showed and the oposite if its false[Boolean].
 * - autoplay: This property activates the lightbox's autoplay function(default on false)[Boolean].
 */
export default (BeritLightboxComponent: BeritLightboxComponentProps => React$Node)
