// @flow

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { COTIZACION_UPDATE_PRODUCTO_PROP } from 'redux/constants'
import { productoImg } from 'utils/cotizacionesUtils'

import './BeritSelectMainImage.scss'

type BeritSelectMainImageProps = {
  images: string[],
  mainImage: string,
  id: number,
}

const BeritSelectMainImage = ({ images, mainImage, id }: BeritSelectMainImageProps) => {
  const dispatch = useDispatch()
  const [selectMainImageState, setSelectMainImageState] = useState({
    images: [],
    image: '',
    currentIndex: 0,
  })
  const { images: arrImages, image, currentIndex } = selectMainImageState
  const imagesLength = images.length

  useEffect(() => {
    setSelectMainImageState(state => ({
      images,
      image: mainImage,
      currentIndex: images.indexOf(mainImage),
    }))
  }, [mainImage, images])

  const handlePosition = (currentPosition, nextPosition) => {
    const newMainImageIndex =
      imagesLength === nextPosition ? 0 : nextPosition < 0 ? imagesLength - 1 : nextPosition
    const newMainImage = arrImages[newMainImageIndex]
    dispatch({
      type: COTIZACION_UPDATE_PRODUCTO_PROP,
      productoId: id,
      key: 'imagen',
      payload: arrImages[newMainImageIndex],
    })
    setSelectMainImageState(state => ({
      ...state,
      image: newMainImage,
      currentIndex: newMainImageIndex,
    }))
  }

  return (
    <div className="BeritSelectMainImage">
      <div className="ImageFrame">
        <div
          className="clickable noSelect chevronLeft"
          onClick={() => handlePosition(currentIndex, currentIndex - 1)}
        >
          <i className="fas fa-chevron-circle-left" />
        </div>
        <img src={productoImg(image)} title={image} alt={image} width={'90%'} />

        <div
          className="clickable noSelect chevronRight"
          onClick={() => handlePosition(currentIndex, currentIndex + 1)}
        >
          <i className="fas fa-chevron-circle-right" />
        </div>
      </div>
    </div>
  )
}

export default (BeritSelectMainImage: BeritSelectMainImageProps => React$Node)
