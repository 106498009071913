// @flow
import { MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'

import BeritCard from 'components/BeritCard'

type Body = {
  children: React$Node,
  label?: string,
}

type BeritCrearEditCardsProps = {
  content: {
    general: Body,
    sucursales: Body,
    infoFiscal: Body,
  },
}

const BeritCrearEditCards = ({ content }: BeritCrearEditCardsProps) => {
  const { general, sucursales, infoFiscal } = content

  return (
    <>
      <MDBRow className="d-flex">
        <MDBCol>
          <BeritCard>
            <h5>
              <strong>{general.label}</strong>
            </h5>
            {general.children}
          </BeritCard>
          <MDBRow>
            <MDBCol>
              <BeritCard>{infoFiscal.children}</BeritCard>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol>
          <BeritCard>
            <div>{sucursales.children}</div>
          </BeritCard>
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default (BeritCrearEditCards: BeritCrearEditCardsProps => React$Node)
