// @flow
import { useCallback, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import CostoFinal from './CostoFinal'
import CotizacionProducto from './CotizacionProducto'
import { getCotizacionProductos, getPedidoProductos } from 'redux/selectors'
import { conditionalUtils } from 'utils'

type CotizacionProductosProps = {
  isEdit?: boolean,
  type: 'cotizacion' | 'pedido',
}

const CotizacionProductos = ({ isEdit = false, type = 'pedido' }: CotizacionProductosProps) => {
  const cotizacionProductos = useSelector(getCotizacionProductos, shallowEqual)
  const pedidoProductos = useSelector(getPedidoProductos, shallowEqual)

  const productos = useMemo(
    () => conditionalUtils.pathCondition(type, 'pedido', pedidoProductos, cotizacionProductos),
    [cotizacionProductos, pedidoProductos, type]
  )

  const getTotals = useCallback(
    (key, tintas = false) => {
      const totalByItem =
        productos.length &&
        productos &&
        productos.map(product =>
          product.variantes
            .map(variantes =>
              tintas
                ? variantes.cantidad * variantes[key] * product.numeroTintas
                : variantes.cantidad * variantes[key]
            )
            .reduce((acc, curr) => acc + curr)
        )

      const total =
        totalByItem.length > 0
          ? Number(totalByItem.reduce((acc, curr) => acc + curr)).toFixed(2)
          : totalByItem

      return Number(total)
    },
    [productos]
  )

  const proveedorTotal = getTotals('proveedorCosto')
  const maquileroTotal = getTotals('maquileroCosto', true)
  const importe = getTotals('precioUnitario')

  return (
    <section className="mb-4">
      {productos.map((producto, productoIndex) => (
        <CotizacionProducto producto={producto} key={productoIndex} productoIndex={productoIndex} />
      ))}

      {importe > 0 && (
        <div className="mb-2">
          <hr />
          <CostoFinal
            importe={importe}
            totalProveedor={proveedorTotal}
            totalMaquilero={maquileroTotal}
          />
        </div>
      )}
    </section>
  )
}

export default (CotizacionProductos: CotizacionProductosProps => React$Node)
