// @flow
import {
  MDBCollapse,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
} from 'mdbreact/dist/mdbreact.esm'
import { useState } from 'react'

import logo from 'assets/img/logo-berit.png'

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const toggleCollapse = () => {
    setNavbarOpen(isOpen => !isOpen)
  }

  return (
    <MDBNavbar color="berit-gradient" dark expand="md" fixed="top" scrolling>
      <MDBContainer>
        <MDBNavbarBrand>
          <img
            style={{ maxHeight: '30px' }}
            src={logo}
            className="img-fluid"
            alt="Berit Soluciones"
          />
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={toggleCollapse} />
        <MDBCollapse id="navbarCollapse" isOpen={navbarOpen} navbar>
          <MDBNavbarNav left>
            <MDBNavItem active>
              <MDBNavLink style={{ color: 'black' }} to="/">
                Home
              </MDBNavLink>
            </MDBNavItem>
            {/*
          <MDBNavItem>
            <MDBNavLink to='#!'>Link</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to='#!'>Profile</MDBNavLink>
          </MDBNavItem>
          */}
          </MDBNavbarNav>
          <MDBNavbarNav right>
            <MDBNavItem active>
              <MDBNavLink style={{ color: 'black' }} to="/admin">
                Admin
              </MDBNavLink>
            </MDBNavItem>

            {/*
          <MDBNavItem>
            <MDBFormInline waves>
              <div className='md-form my-0'>
                <input
                  className='form-control mr-sm-2'
                  type='text'
                  placeholder='Search'
                  aria-label='Search'
                />
              </div>
            </MDBFormInline>
          </MDBNavItem>
        */}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  )
}

export default (Header: () => React$Node)
