// @flow
import { type CombinedReducer, combineReducers } from 'redux'

import apiReducer, { type ApiAction } from './apiReducer'
import authReducer, { type AuthAction } from './authReducer'
import cotizacionesReducer, { type CotizacionesAction } from './cotizacionesReducer'
import errorReducer, { type ErrorsAction } from './errorReducer'
import loadingReducer, { type LoadingAction } from './loadingReducer'
import modalImagesReducer, { type ModalImageAction } from './modalImagesReducer'
import modalReducer, { type ModalAction } from './modalReducer'
import pedidosReducer, { type PedidosAction } from './pedidosReducer'
import successReducer, { type SuccessAction } from './successReducer'
import usuarioReducer, { type UsuarioAction } from './usuarioReducer'

const reducers = {
  api: apiReducer,
  auth: authReducer,
  cotizaciones: cotizacionesReducer,
  errores: errorReducer,
  loading: loadingReducer,
  modal: modalReducer,
  modalImages: modalImagesReducer,
  pedidos: pedidosReducer,
  success: successReducer,
  usuario: usuarioReducer,
}

export type Reducers = typeof reducers

export type Action = {|
  ...ApiAction,
  ...AuthAction,
  ...CotizacionesAction,
  ...ErrorsAction,
  ...LoadingAction,
  ...ModalAction,
  ...ModalImageAction,
  ...PedidosAction,
  ...SuccessAction,
  ...UsuarioAction,
|}

export default (combineReducers<Reducers, Action>(reducers): CombinedReducer<Reducers, Action>)
