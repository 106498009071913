// @flow
import {
  CHANGE_COLOR_TALLA_VARIANTE_PEDIDO,
  PEDIDO_ADD_VARIANTE,
  PEDIDO_CHANGE_PRODUCT_DESCRIPTION,
  PEDIDO_DELETE_PRODUCT,
  PEDIDO_SAVE_SELECTED_PEDIDO,
  PEDIDOS_ADD_PRODUCTO,
  PEDIDOS_CLEAR_PEDIDOS,
  SAVE_BUSQUEDA_RESPONSE_PEDIDOS,
  UPDATE_CALCULADORA_HEADER_PEDIDOS,
  UPDATE_CALCULADORA_PEDIDOS,
  UPDATE_CALCULADORA_PU_VARIANTES_PEDIDOS,
} from 'redux/constants'

const busquedaInicial = {
  productos: [],
  hits: 0,
  isLoading: false,
}

const initState = {
  busqueda: busquedaInicial,
  pedido: {
    clienteId: 0,
    cliente: '',
    codigoPostal: '',
    correo: '',
    direccionFiscal: '',
    entregaDireccion: '',
    entregaRecibe: '',
    contactos: [],
    fechaCreacion: '',
    fechaEntregaClienteFin: '',
    fechaEntregaClienteIni: '',
    fechaEntregaIni: '',
    fechaPedido: '',
    razonSocial: '',
    telefono: '',
    tels: [],
    pedidoId: 0,
    pedidoBerit: 0,
    productos: [],
    ordenCompra: '',
    rfc: '',
    ultimaModificacion: '',
    vendedor: '',
  },
}

type PedidosReducer = PedidosState

export type PedidosAction = {|
  +type: string,
  bsku: string,
  costoProveedor: number,
  costoTecnica: number,
  calc: any,
  descripcion: string,
  key: string,
  porcentaje: number,
  precioSugerido: number,
  producto: ProductoPedido,
  response: BusquedaResponse,
  variante: Variante,
  variantId: number,
  value: number,
  vendedor: string,
  clienteid: number,
  pedido: Pedido,
|}

const pedidosReducer = (state: PedidosState = initState, action: PedidosAction) => {
  switch (action.type) {
    case CHANGE_COLOR_TALLA_VARIANTE_PEDIDO:
      return {
        ...state,
        pedido: {
          ...state.pedido,
          productos: state.pedido.productos.map(producto =>
            producto.bsku === action.bsku
              ? {
                  ...producto,
                  variantes: producto.variantes.map(variante =>
                    variante.variantId === action.variantId
                      ? { ...variante, [action.key]: action.value }
                      : variante
                  ),
                }
              : producto
          ),
        },
      }

    case SAVE_BUSQUEDA_RESPONSE_PEDIDOS:
      return {
        ...state,
        busqueda: {
          ...state.busqueda,
          productos: action.response.productos,
          hits: action.response.hits,
        },
      }

    case PEDIDOS_ADD_PRODUCTO:
      return {
        ...state,
        busqueda: busquedaInicial,
        pedido: {
          ...state.pedido,
          productos: Array.isArray(action.producto)
            ? [...action.producto]
            : [...state.pedido.productos, action.producto],
        },
      }

    case PEDIDO_ADD_VARIANTE:
      return {
        ...state,
        pedido: {
          ...state.pedido,
          productos: state.pedido.productos.map(producto =>
            producto.bsku === action.variante.bsku
              ? producto.variantes
                ? { ...producto, variantes: [...producto.variantes, action.variante] }
                : { ...producto, variantes: [action.variante] }
              : producto
          ),
        },
      }

    case PEDIDO_DELETE_PRODUCT:
      return {
        ...state,
        pedido: {
          ...state.pedido,
          productos: state.pedido.productos.filter(producto => producto.bsku !== action.bsku),
        },
      }

    case PEDIDOS_CLEAR_PEDIDOS:
      return {
        ...state,
        pedido: {
          ...state.pedido,
          productos: [],
        },
      }

    case UPDATE_CALCULADORA_HEADER_PEDIDOS:
      return {
        ...state,
        pedido: {
          ...state.pedido,
          productos: state.pedido.productos.map(producto =>
            producto.bsku === action.bsku
              ? {
                  ...producto,
                  [action.key]: action.value,
                }
              : producto
          ),
        },
      }

    case UPDATE_CALCULADORA_PEDIDOS: {
      return {
        ...state,
        pedido: {
          ...state.pedido,
          productos: state.pedido.productos.map(producto =>
            producto.bsku === action.bsku
              ? {
                  ...producto,
                  variantes: producto.variantes.map(variante =>
                    variante.variantId === action.variantId
                      ? {
                          ...variante,
                          [action.key]:
                            action.key === 'tecnicaDecorado'
                              ? action.value
                              : parseFloat(action.value),
                        }
                      : variante
                  ),
                }
              : producto
          ),
        },
      }
    }

    case UPDATE_CALCULADORA_PU_VARIANTES_PEDIDOS:
      return {
        ...state,
        pedido: {
          ...state.pedido,

          productos: state.pedido.productos.map(producto =>
            producto.bsku === action.bsku
              ? {
                  ...producto,
                  variantes: producto.variantes.map(variante =>
                    variante.bsku === action.bsku
                      ? {
                          ...variante,
                          precioUnitario: action.value,
                        }
                      : variante
                  ),
                }
              : producto
          ),
        },
      }

    case PEDIDO_CHANGE_PRODUCT_DESCRIPTION:
      return {
        ...state,
        pedido: {
          ...state.pedido,
          productos: state.pedido.productos.map(producto =>
            producto.bsku === action.bsku
              ? { ...producto, productoDescripcion: action.descripcion }
              : producto
          ),
        },
      }

    case PEDIDO_SAVE_SELECTED_PEDIDO:
      return {
        ...state,
        pedido: action.pedido,
      }
    default:
      return state
  }
}

export default (pedidosReducer: (state: PedidosState, action: PedidosAction) => PedidosReducer)
