// @flow
import { MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useLocation, useParams } from 'react-router'

import { formatPrecio } from 'utils/cotizacionesUtils'

type CostoFinalProp = {
  importe: number,
  totalProveedor?: number,
  totalMaquilero?: number,
}

const CostoFinal = ({ importe = 0, totalProveedor = 0, totalMaquilero = 0 }: CostoFinalProp) => {
  const { pathname } = useLocation()
  const { cotizacionId } = useParams()

  const costoTotal = totalProveedor + totalMaquilero
  const TotalComponent = ({ value, subtotal, total }) => (
    <ul className="list-unstyled text-right">
      <li>
        <strong>{subtotal}</strong>
        <span className="float-right ml-3">{formatPrecio(value)}</span>
      </li>
      <li>
        <strong>IVA</strong>
        <span className="float-right ml-3">{formatPrecio(value * 0.16)}</span>
      </li>
      <li>
        <strong>{total}</strong>
        <span className="float-right ml-3">{formatPrecio(value * 1.16)}</span>
      </li>
    </ul>
  )

  return (
    <MDBRow>
      {!cotizacionId && pathname !== '/admin/cotizaciones/crear' && (
        <>
          <MDBCol md="3" sm="6" xs="12">
            <TotalComponent
              value={totalProveedor}
              subtotal="Subtotal Proveedor"
              total="Total Proveedor"
            />
          </MDBCol>
          <MDBCol md="3" sm="6" xs="12">
            <TotalComponent
              value={totalMaquilero}
              subtotal="Subtotal Maquilero"
              total="Total Maquilero"
            />
          </MDBCol>
          <MDBCol md="3" sm="6" xs="12">
            <TotalComponent value={costoTotal} subtotal="Subtotal Costos" total="Total Costos" />
          </MDBCol>
        </>
      )}
      <MDBCol md="3" sm="6" xs="12">
        <TotalComponent value={importe} subtotal="Subtotal" total="Total" />
      </MDBCol>
    </MDBRow>
  )
}

export default (CostoFinal: CostoFinalProp => React$Node)
