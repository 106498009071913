// @flow
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'

import { fileUtils, useLazyImage } from 'utils'

type BeritDataTableProps = {
  data: {
    rows: any,
    columns: any,
  },
  entityName: string,
}

const BeritDataTable = ({ data, entityName }: BeritDataTableProps) => {
  const { columns, rows } = data
  const [filterText, setFilterText] = useState('')
  const [memoizedLoad, setMemoizedLoad] = useState(false)
  const handleLazyLoadingImg = useLazyImage('.beritLazyLoadImg', memoizedLoad || !!filterText)

  useEffect(() => {
    if (entityName) {
      setFilterText('')
      entityName === 'productos' && handleLazyLoadingImg()
    }
  }, [entityName, handleLazyLoadingImg])
  const filteredItems = rows.filter(item => {
    return item && filterText
      ? item.nombre?.toLowerCase().includes(filterText) ||
          item.nombres?.toLowerCase().includes(filterText) ||
          item.apellidos?.toLowerCase().includes(filterText) ||
          item.nombreComercial?.toLowerCase().includes(filterText) ||
          item.asignacion?.toLowerCase() === filterText ||
          item.giro?.toString().toLowerCase().includes(filterText) ||
          item.cliente?.toLowerCase().includes(filterText) ||
          item.contactos?.toLowerCase().includes(filterText) ||
          item.productos?.toLowerCase().includes(filterText) ||
          item.sucursal?.toLowerCase().includes(filterText) ||
          item.vendedor?.toLowerCase().includes(filterText) ||
          item.status?.toLowerCase().includes(filterText) ||
          item.usuario?.toLowerCase().includes(filterText) ||
          item.departamento?.toLowerCase().includes(filterText) ||
          item.puesto?.toLowerCase().includes(filterText) ||
          item.entregaRecibe?.toLowerCase().includes(filterText) ||
          item.ordenCompra?.toLowerCase().includes(filterText)
      : item
  })

  const subHeaderComponent = useMemo(
    () => (
      <>
        <TextField
          autoComplete="off"
          id="search"
          label="Filtrar"
          onChange={e => setFilterText(e.target.value.toLowerCase())}
          onKeyUp={_ => entityName === 'productos' && handleLazyLoadingImg()}
          value={filterText}
          variant="standard"
          onFocus={() => setMemoizedLoad(true)}
          onBlur={() => setMemoizedLoad(false)}
        />
        <Button
          onClick={e => fileUtils.downloadTableCSV(data, entityName)}
          className="mt-3"
          color="primary"
        >
          <i className="fas fa-download" />
        </Button>
      </>
    ),
    [filterText, data, entityName, handleLazyLoadingImg]
  )

  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      striped
      pagination
      onChangePage={() => entityName === 'productos' && handleLazyLoadingImg()}
      onSort={() => entityName === 'productos' && handleLazyLoadingImg()}
      defaultSortFieldId="ultimaModificacion"
      defaultSortAsc={false}
      paginationComponentOptions={{
        rowsPerPageText: 'Resultados por página:',
        rangeSeparatorText: 'de',
      }}
      paginationPerPage={50}
      subHeader
      highlightOnHover
      persistTableHead={true}
      noDataComponent={
        <span className="text-muted">No hay resultados para {filterText || entityName}</span>
      }
      subHeaderComponent={subHeaderComponent}
      paginationRowsPerPageOptions={[50, 100, 200]}
    />
  )
}

export default (BeritDataTable: BeritDataTableProps => React$Node)
