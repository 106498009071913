// @flow
import { createSelector } from 'reselect'

import { getUsuarioState } from './stateSelectors'

export const getUsuario: State => Usuario = createSelector(getUsuarioState, usuario => usuario)

export const getUsuarioId: State => number = createSelector(
  getUsuario,
  usuario => usuario.usuarioId
)
