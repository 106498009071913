// @flow
import { MDBBtn, MDBIcon, MDBTooltip } from 'mdbreact/dist/mdbreact.esm'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { MODAL_SET, MODAL_TOGGLE } from 'redux/constants'

type CotizacionTableCellComponentProps = {
  row: {
    status: number | string,
    hash: number,
    id: number,
  },
}

const CotizacionTableCellComponent = ({ row }: CotizacionTableCellComponentProps) => {
  const { status, hash, id } = row
  const history = useHistory()
  const dispatch = useDispatch()
  const isProduction = process.env.NODE_ENV === 'production'

  const toPedido = () => history.push(`/admin/pedidos/crear/${+id}`)

  const onShare = () => {
    dispatch({ type: MODAL_SET, payload: { status, hash } })
    dispatch({ type: MODAL_TOGGLE })
  }
  return (
    <>
      <div className="actions-container">
        {status === 'Borrador' && (
          <>
            <Link to={`/admin/cotizaciones/ver/${id}`}>
              <MDBTooltip placement="top">
                <MDBBtn color="primary" floating size="sm">
                  <MDBIcon icon="eye" />
                </MDBBtn>
                <div>
                  <span>Ver cotización</span>
                </div>
              </MDBTooltip>
            </Link>
            <Link to={`/admin/cotizaciones/editar/${id}`}>
              <MDBTooltip placement="top">
                <MDBBtn color="warning" floating size="sm">
                  <MDBIcon icon="edit" />
                </MDBBtn>
                <div>
                  <span>Editar cotización</span>
                </div>
              </MDBTooltip>
            </Link>
          </>
        )}
        {status === 'Publicado' && (
          <>
            <MDBTooltip placement="top">
              <MDBBtn onClick={onShare} color="info" floating size="sm">
                <MDBIcon icon="share-alt" />
              </MDBBtn>
              <div>
                <span>Compartir cotización</span>
              </div>
            </MDBTooltip>
            <Link to={`/admin/cotizaciones/ver/${id}`}>
              <MDBTooltip placement="top">
                <MDBBtn color="primary" floating size="sm">
                  <MDBIcon icon="eye" />
                </MDBBtn>
                <div>
                  <span>Ver cotización</span>
                </div>
              </MDBTooltip>
            </Link>
            {!isProduction && (
              <MDBTooltip placement="top">
                <MDBBtn color="deep-orange" floating size="sm" onClick={toPedido}>
                  <MDBIcon icon="file-export" />
                </MDBBtn>
                <div>
                  <span>Convertir cotización a Pedido</span>
                </div>
              </MDBTooltip>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default (CotizacionTableCellComponent: CotizacionTableCellComponentProps => React$Node)
