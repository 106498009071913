// @flow
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import { MDBCardBody } from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import PedidoEditarHeader from './PedidoEditarHeader'
import BeritCard from 'components/BeritCard'
import BeritProgressButton from 'components/BeritProgressButton/BeritProgressButtonComponent'
import CotizacionProductos from 'components/CotizacionProductos'
import LiveSearch from 'components/LiveSearch'
import PedidoCotizacionEditarSkeleton from 'components/PedidoCotizacionEditarSkeleton'
import PedidoCotizacionEditarSkeletonBody from 'components/PedidoCotizacionEditarSkeletonBody'
import VolverA from 'components/VolverA'
import {
  COTIZACION_GETBYID_ERROR,
  COTIZACION_GETBYID_REQUEST,
  COTIZACION_GETBYID_SUCCESS,
  PEDIDO_SAVE_SELECTED_PEDIDO,
  PEDIDOS_ADD_PRODUCTO,
  PEDIDOS_CLEAR_PEDIDOS,
} from 'redux/constants'
import { getLoading, getPedido, getPedidoProductos, getUsuarioState } from 'redux/selectors'
import { useGetById } from 'utils'
import useToast from 'utils/hooks/useToast'

import beritBanner from 'assets/img/logo-berit-cafe.png'

import './PedidoForm.scss'

type PedidoFormComponentProps = {
  isEdit?: boolean,
  title: string,
}

const PedidoFormComponent = ({ isEdit = false, title }: PedidoFormComponentProps) => {
  const isLoading = useSelector(
    getLoading(['COTIZACION_GETBYID', 'CLIENTE_GETALL', 'CONTACTO_GETALL', 'EMPLEADO_GETALL'])
  )
  const { pedidoId, cotId } = useParams()
  const cotizacionGetById = useGetById('cotizaciones')
  const pedido = useSelector(getPedido, shallowEqual)
  const [finishDate, setFinishDate] = useState(false)
  const [permisos, setPermisos] = useState({
    canEditFechaPedido: true,
    canEditVendedor: true,
    canEditFechaEntregaIni: true,
    canEditFechaEntregaClienteFin: true,
    canEditClienteId: true,
    canEditRazonSocial: true,
    canEditDireccionFiscal: true,
    canEditRfcId: true,
    canEditCodigoPostal: true,
    canEditContactInfo: true,
    canViewFechaPedido: true,
    canViewVendedor: true,
    canViewFechaEntregaIni: true,
    canViewFechaEntregaClienteFin: true,
    canViewClienteId: true,
    canViewRazonSocial: true,
    canViewDireccionFiscal: true,
    canViewRfcId: true,
    canViewCodigoPostal: true,
    canViewContactInfo: true,
  })
  const dispatch = useDispatch()
  const history = useHistory()
  const usuario = useSelector(getUsuarioState, shallowEqual)
  const form = useForm({
    defaultValues: {
      cliente: {
        id: 0,
        nombre: '',
        asignacion: '',
      },
      contactos: [],
      entregaPersona: '',
      entregaComentarios: '',
      fechaEntregaIni: '',
      hasFechaEntregaFin: false,
      fechaPedido: moment(),
      ordenCompra: '',
      pedidoBerit: 0,
      rfc: {
        id: 0,
        nombre: 'Sin Factura',
        rfcId: 0,
      },
      showFechaEntregaFin: true,
      sucursal: {
        id: 0,
        nombre: '',
      },
      vendedor: usuario,
    },
  })
  const { handleSubmit, watch, reset } = form
  const { fechaEntregaClienteFin } = watch()
  const pedidoProductos = useSelector(getPedidoProductos, shallowEqual)
  const toast = useToast()

  useEffect(() => {
    if (cotId) {
      dispatch({ type: COTIZACION_GETBYID_REQUEST })
      cotizacionGetById(+cotId)
        .then(cotizacion => {
          const cotizacionParsed = JSON.parse(cotizacion.cotizacionJson)
          const { cliente, contactos, fecha, vendedor, productos } = cotizacionParsed
          dispatch({ type: COTIZACION_GETBYID_SUCCESS })

          dispatch({
            type: PEDIDOS_ADD_PRODUCTO,
            producto: productos.map(producto => {
              const stringToHtml = producto.productoDescripcion
              const blocksFromHTML = convertFromHTML(stringToHtml)
              const description = ContentState.createFromBlockArray(blocksFromHTML)
              return {
                ...producto,
                productoDescripcion: convertToRaw(description),
                numeroTintas: 1,
                maquileroId: 1,
              }
            }),
          })

          reset({
            cliente: {
              id: cliente?.id,
              nombre: cliente?.nombreComercial,
              asignacion: cliente?.asignacion,
            },
            contactos,
            fechaPedido: moment(fecha),
            rfc: {
              id: 0,
              rfcId: 0,
              nombre: 'Sin Factura',
              rfc: '',
              razonSocial: '',
              direccion: '',
            },
            showFechaEntregaFin: true,
            hasFechaEntregaFin: false,
            sucursal: cliente.sucursal[0],
            ordenCompra: '',
            pedidoBerit: cotizacion.cotizacionNumero,
            entregaPersona: '',
            fechaEntregaIni: '',
            entregaComentarios: '',
            vendedor,
          })
        })
        .catch(err => {
          dispatch({ type: COTIZACION_GETBYID_ERROR })
          toast(`ERROR: ${err}`, 'error')
        })
    } else {
      dispatch({ type: PEDIDOS_CLEAR_PEDIDOS })
    }
  }, [cotId, reset, toast, dispatch, cotizacionGetById])

  useEffect(() => {
    if (isEdit) {
      setPermisos({
        canEditFechaPedido: false,
        canEditVendedor: false,
        canEditFechaEntregaIni: false,
        canEditFechaEntregaClienteFin: false,
        canEditClienteId: false,
        canEditRazonSocial: false,
        canEditDireccionFiscal: false,
        canEditRfcId: false,
        canEditCodigoPostal: false,
        canEditContactInfo: false,
        canViewFechaPedido: true,
        canViewVendedor: true,
        canViewFechaEntregaIni: true,
        canViewFechaEntregaClienteFin: true,
        canViewClienteId: true,
        canViewRazonSocial: true,
        canViewDireccionFiscal: true,
        canViewRfcId: true,
        canViewCodigoPostal: true,
        canViewContactInfo: true,
      })
    }
  }, [isEdit])

  const onSubmit = pedidoForm => {
    if (pedidoId ? true : pedidoProductos.length > 0) {
      const ventaData = {
        ...pedidoForm,
        pedidoBerit: 2,
        fechaEntregaClienteFin: finishDate ? fechaEntregaClienteFin : null,
        productos: pedidoProductos,
      }
      if (pedidoId) {
        console.warn(ventaData)
      } else {
        dispatch({
          type: PEDIDO_SAVE_SELECTED_PEDIDO,
          pedido: ventaData,
        })
        history.push(`/admin/pedidos/revisar/1`)
      }
    } else {
      toast('ERROR: Por favor, agrega al menos 1 producto a tu pedido', 'error')
    }
  }

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  if (isLoading) {
    return (
      <>
        <PedidoCotizacionEditarSkeleton />
        <PedidoCotizacionEditarSkeletonBody />
      </>
    )
  }

  return (
    <>
      {cotId && <VolverA label="Cotizaciones" to="/admin/cotizaciones" />}
      <BeritCard classes="PedidoForm mb-5 pb-3 p-2" title={title}>
        <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
          <PedidoEditarHeader
            banner={beritBanner}
            pedido={pedido}
            permisos={permisos}
            form={form}
            finishDate={finishDate}
            setFinishDate={setFinishDate}
          />

          {!pedidoId && (
            <div className="mt-3">
              <LiveSearch type="pedido" />
              {pedidoProductos && <CotizacionProductos isEdit={isEdit} type="pedido" />}
            </div>
          )}

          <section className="mb-4">
            <MDBCardBody className="d-flex justify-content-end align-items-center">
              <div>
                <BeritProgressButton
                  label="Guardar y vista previa"
                  entityName="pedido"
                  type="submit"
                />
              </div>
            </MDBCardBody>
          </section>
        </form>
      </BeritCard>
    </>
  )
}

export default (PedidoFormComponent: PedidoFormComponentProps => React$Node)
