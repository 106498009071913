// @flow
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { SuperController } from 'controllers'
import { dispatchError } from 'redux/actions/errorsActions'
import { getApiState, getAuthState } from 'redux/selectors'
import { errorUtils, stringUtils, useToast } from 'utils'

const useGet = (entityName, forceFetch = false) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const auth = useSelector(getAuthState, shallowEqual)
  const api = useSelector(getApiState, shallowEqual)
  const entityList = api[entityName]

  useEffect(() => {
    if (Object.keys(entityList).length === 0) {
      const entityActionName = stringUtils.singularize(entityName).toUpperCase()

      const entityService = new SuperController(auth, stringUtils.singularize(entityName))

      if (entityService) {
        dispatch({ type: `${entityActionName}_GETALL_REQUEST` })
      }

      if (entityService) {
        entityService
          .GetAll()
          .then(entityResponse => {
            if (entityResponse.hits === 0) {
              dispatch({
                type: `${entityActionName}_GETALL_ERROR`,
              })
            } else {
              dispatch({
                type: `${entityActionName}_GETALL_SUCCESS`,
                [entityName]: entityResponse[entityName === 'reportes' ? 'totales' : entityName],
              })
            }
          })
          .catch((error: ErrorResponse) => {
            dispatch(dispatchError(`${entityActionName}_GETALL_ERROR`, error))
            console.error(`${entityName}Service.GetAll error:`, error)
            toast(errorUtils.getDescription(error), 'error')
          })
      }
    }
  }, [auth, dispatch, entityList, entityName, forceFetch, toast])

  return entityList
}

export default useGet
