// @flow
import { MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'

type SubheaderBarProps = {
  label: string,
  size?: string,
}

const SubheaderBar = ({ label, size = '12' }: SubheaderBarProps) => {
  return (
    <MDBRow className="text-center">
      <MDBCol size={size}>
        <h6 className="subheader-gray">{label}</h6>
      </MDBCol>
    </MDBRow>
  )
}

export default (SubheaderBar: SubheaderBarProps => React$Node)
