// @flow
import { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { SuperController } from 'controllers'
import { dispatchError } from 'redux/actions/errorsActions'
import { getAuthState } from 'redux/selectors'
import { errorUtils, stringUtils, useToast } from 'utils'

const useGetById = (entityName: string) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const auth = useSelector(getAuthState, shallowEqual)
  const EntityService = useMemo(
    () => new SuperController(auth, stringUtils.singularize(entityName)),
    [entityName, auth]
  )
  const entityActionName = useMemo(
    () => stringUtils.singularize(entityName).toUpperCase(),
    [entityName]
  )

  const entityGetById = useCallback(
    entityId => {
      if (entityId && EntityService) {
        const response = EntityService.GetById(typeof entityId === 'number' ? +entityId : entityId)
          .then(entityResponse => {
            dispatch({
              type: `${entityActionName}_GETBYID_SUCCESS`,
            })
            return entityResponse[stringUtils.singularize(entityName)]
          })
          .catch((error: ErrorResponse) => {
            dispatch(dispatchError(`${entityActionName}_GETBYID_ERROR`, error))
            console.error(`${entityName}Service.GetById error:`, error)
            toast(errorUtils.getDescription(error), 'error')
          })

        return response
      }
    },
    [dispatch, entityName, toast, EntityService, entityActionName]
  )

  return entityGetById
}

export default useGetById
