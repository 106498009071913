// @flow
import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBStep,
  MDBStepper,
  MDBTooltip,
} from 'mdbreact/dist/mdbreact.esm'
import { useState } from 'react'

import spiderGif from 'assets/img/spider.gif'

const Crawler = () => {
  const [sessionId, setSessionId] = useState('')
  const [activeStep, setActiveStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()

    const data = new FormData()
    data.append('jsessionid', sessionId)

    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
      setActiveStep(3)
    }, 13000)
    // axios.post(`${beritApi}crawlers/forpromotional/`, data, { withCredentials: true })
    //   .then(res => {
    //     setIsLoading(false)
    //     console.warn('res.data', res.data)
    //   })
    //   .catch(err => console.error(err))

    // fetch(`${beritApi}/`)
    console.warn('Submitted')
  }

  const handleSessionChange = e => setSessionId(e.target.value)

  return (
    <div>
      <MDBStepper icon>
        <MDBStep icon="id-card" stepName="Inicio" onClick={() => setActiveStep(1)} />
        <MDBStep icon="search" stepName="Análisis" onClick={() => setActiveStep(2)} />
        <MDBStep icon="check" stepName="Finalizar" onClick={() => setActiveStep(3)} />
      </MDBStepper>
      {activeStep === 1 && (
        <>
          <h3>Paso 1</h3>
          <ul>
            <ol>
              Visita{' '}
              <a href="https://4promotional.net" target="_blank" rel="noreferrer">
                https://4promotional.net
              </a>{' '}
              e inicia sesión.
            </ol>
            <ol>
              Usando el plugin EditThisCookie
              <MDBTooltip>
                <MDBBtn color="info" className="btn-sm p-2 d-inline">
                  <MDBIcon icon="question" />
                </MDBBtn>
                <div>¿Qué es EditThisCookie?</div>
              </MDBTooltip>
              obtén la cookie llamada JSESSIONID.
              <MDBTooltip>
                <MDBBtn color="info" className="btn-sm p-2 d-inline">
                  <MDBIcon icon="question" />
                </MDBBtn>
                <div>¿Qué es JSESSIONID?</div>
              </MDBTooltip>
            </ol>
            <ol>
              Copia y pega el valor de la cookie a continuación y da click en &quot;Ejecutar
              Análisis&quot;.
            </ol>
          </ul>
          <form onSubmit={handleSubmit}>
            <MDBInput label="JSESSIONID" onChange={handleSessionChange} />
            <MDBBtn color="primary" type="submit" disabled={isLoading}>
              {isLoading ? 'Ejecutando...' : 'Ejecutar Análisis'}
            </MDBBtn>
            {isLoading && (
              <p>
                <img src={spiderGif} width="200" />
              </p>
            )}
          </form>
        </>
      )}
      {activeStep === 2 && (
        <>
          <p>
            Es posible que el catálogo de 4Promotional no haya sido actualizado desde la última
            ejecución del Crawler.
          </p>
          <p>
            Aún así, ¿Deseas actualizar la Base de Datos de Berit con la información más reciente de
            4Promotional?
          </p>
          <p>
            El número de productos encontrado en 4promotional.net es el mismo que en la Base de
            Datos de Berit.
          </p>
        </>
      )}
      {activeStep === 3 && (
        <>
          <h2>Éxito</h2>
          <h4>El análisis se ejecutó exitosamente.</h4>
          <ol>
            <li>
              Se encontraron <b>762 productos</b> en el sitio de 4promotional.net.
            </li>
            <li>No se encontró ningún producto nuevo.</li>
            <li>No se encontró ningún producto descontinuado.</li>
            <li>Los precios en la Base de Datos Berit han sido actualizados.</li>
            <li>No se encontró ninguna imagen nueva de producto.</li>
          </ol>
          <p />
        </>
      )}
    </div>
  )
}

export default (Crawler: () => React$Node)
