// @flow
export const ucfirst = (string: string): string => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const singularize = (entityName: string): string => {
  switch (entityName) {
    case 'cotizaciones':
    case 'proveedores':
    case 'sucursales':
      return entityName.slice(0, -2)
    default:
      return entityName.slice(0, -1)
  }
}

export const pluralize = (entityName: string): string => {
  switch (entityName) {
    case 'cotizacion':
    case 'proveedor':
    case 'sucursal':
      return `${entityName}es`
    default:
      return `${entityName}s`
  }
}

export const getCamelCaseWords = (camelCase: string): string =>
  camelCase.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
