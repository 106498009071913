// @flow
import Typography from '@material-ui/core/Typography'
import { MDBCardHeader, MDBCol, MDBContainer, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { memo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import BeritCrearEditCards from './BeritCrearEditCards'
import BeritInput from './BeritInput'
import BeritProgressButton from './BeritProgressButton'
import BeritSelect from './BeritSelect'
import CreateEntitySkeleton from './CreateEntitySkeleton'
import Direcciones from './Direcciones'
import VolverA from './VolverA'
import { getLoading } from 'redux/selectors'
import { numberUtils, useAdd, useGetById, useToast, useUpdate } from 'utils'

const initialForm = {
  defaultValues: {
    asignacion: '',
    giro: '',
    nombreComercial: '',
    rfc: [],
    sucursal: [],
  },
}
const ClientesCrear = () => {
  const toast = useToast()
  const { id } = useParams()
  const clienteId = id !== undefined ? parseInt(id) : -1
  const isEdit = clienteId > 0 && true
  const isFetching = useSelector(getLoading(['CLIENTE_GETBYID']))
  const form = useForm(initialForm)
  const { handleSubmit, control, reset, setValue } = form
  const clienteAdd = useAdd('clientes')
  const clienteGetById = useGetById('clientes')
  const clienteUpdate = useUpdate('clientes')

  useEffect(() => {
    if (isEdit) {
      clienteGetById(clienteId)
        .then(cliente => {
          const clienteEdited = {
            ...cliente,
            sucursal: cliente.sucursal.map(sucursal => ({
              ...sucursal,
              cp: numberUtils.zeroFill(sucursal.cp, 5),
            })),
          }
          reset(clienteEdited)
        })
        .catch(err => {
          toast(`ERROR: ID no encontrado en la base de datos`, err)
        })
    } else {
      setValue('rfc', [])
      setValue('sucursal', [])
    }

    return () => {
      reset({})
    }
  }, [clienteGetById, clienteId, isEdit, reset, toast, setValue])

  const onSubmit = (clienteForm: ClienteForm) => {
    if (isEdit) {
      clienteUpdate(clienteId, clienteForm)
    } else {
      clienteAdd(clienteForm)
    }
  }

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  if (isFetching) {
    return <CreateEntitySkeleton />
  }

  const asignaciones: { text: string, value: string }[] = [
    { value: 'Ventas 1', text: 'Ventas 1' },
    { value: 'Ventas 2', text: 'Ventas 2' },
    { value: 'Ventas 3', text: 'Ventas 3' },
  ]

  const tabsContent = {
    general: {
      label: 'Información general',
      children: (
        <>
          <MDBRow>
            <MDBCol md="6">
              <BeritInput
                control={control}
                label="* Nombre del cliente"
                name="nombreComercial"
                rules="El nombre es obligatorio"
              />
            </MDBCol>
            <MDBCol md="6">
              <BeritInput control={control} name="giro" label="Giro" />
            </MDBCol>
          </MDBRow>
          <BeritSelect
            control={control}
            rules={'Este campo es obligatorio'}
            includeEmpty
            name="asignacion"
            label="Asignación"
            options={asignaciones}
          />
        </>
      ),
    },
    sucursales: {
      children: <Direcciones label="Sucursales" type="sucursal" form={form} />,
    },
    infoFiscal: {
      children: <Direcciones label="Información fiscal" type="rfc" form={form} />,
    },
  }
  return (
    <>
      <MDBRow>
        <VolverA label="Clientes" to="/admin/clientes" />
      </MDBRow>
      <MDBContainer>
        <div>
          <MDBCardHeader className="blue-gradient d-flex justify-content-center align-items-center py-2 mt-3">
            <Typography className="white-text" variant="h5" align="center">
              Cliente
            </Typography>
          </MDBCardHeader>
        </div>

        <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
          <BeritCrearEditCards content={tabsContent} />

          <div className="text-center mt-5">
            <BeritProgressButton
              label={isEdit ? 'Editar cliente' : 'Crear cliente'}
              entityName="cliente"
              type="submit"
            />
          </div>
        </form>
      </MDBContainer>
    </>
  )
}

export default memo((ClientesCrear: () => React$Node))
