// @flow

import { CLEAN_ERRORS_MESSAGES } from 'redux/constants'

type ErrorReducerTypes = ErroresState
export type ErrorsAction = {|
  +type: string,
  description: string,
  entitySubmit: string,
|}

const errorReducer = (state: ErroresState = {}, action: ErrorsAction) => {
  const { type, description } = action
  const matches = /(.*)_(REQUEST|ERROR)/.exec(type)

  if (type === CLEAN_ERRORS_MESSAGES) {
    return {
      ...state,
      [action.entitySubmit]: '',
    }
  }

  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    [requestName]: requestState === 'ERROR' ? description : '',
  }
}

export default (errorReducer: (state: ErroresState, action: ErrorsAction) => ErrorReducerTypes)
