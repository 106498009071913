// @flow
import { convertToRaw, EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import draftToHtml from 'draftjs-to-html'
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBRow,
  MDBSpinner,
} from 'mdbreact/dist/mdbreact.esm'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import BeritInput from './BeritInput'
import BeritProgressButton from './BeritProgressButton'
import BeritSelect from './BeritSelect'
import BeritWysiwygEditor from './BeritWysiwygEditor'
import ImageUploader from './ImageUploader'
import VolverA from './VolverA'
import {
  PRODUCTO_GETBYID_ERROR,
  PRODUCTO_GETBYID_REQUEST,
  PRODUCTO_GETBYID_SUCCESS,
} from 'redux/constants'
import { getLoading } from 'redux/selectors'
import {
  configUtils,
  errorUtils,
  useAdd,
  useGet,
  useGetById,
  usePublish,
  useToast,
  useUpdate,
  useUpdateImagenes,
} from 'utils'

const ProductosCrear = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const productoId = params.bsku ?? ''
  const isEdit = !!productoId
  const [label, setLabel] = useState('Crear Producto')
  const toast = useToast()
  const form = useForm({
    defaultValues: {
      sku: '',
      nombre: '',
      precio: '',
      proveedorId: 0,
      href: '',
      imagenes: [],
    },
  })
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = form

  const productoAdd = useAdd('productos')
  const productoGetById = useGetById('productos')
  const proveedores: Proveedor[] = useGet('proveedores')
  const productoUpdate = useUpdate('productos')
  const updateProductoImagenes = useUpdateImagenes()
  const publishImg = usePublish('img')
  const isFetching = useSelector(getLoading(['PROVEEDOR_GETBYID', 'PRODUCTO_GETBYID']))
  const [editor, setEditor] = useState(EditorState.createEmpty())

  const proveedoresOptions = useMemo(
    () =>
      proveedores
        .filter(proveedor => proveedor.proveedorId !== 0)
        .map(proveedor => ({
          value: proveedor.proveedorId,
          text: proveedor.nombreComercial,
        })),
    [proveedores]
  )

  useEffect(() => {
    if (isEdit) {
      setLabel('Editar Producto')
      dispatch({ type: PRODUCTO_GETBYID_REQUEST })
      productoGetById(productoId)
        .then(productoResponse => {
          dispatch({
            type: PRODUCTO_GETBYID_SUCCESS,
            producto: productoResponse,
          })

          reset({
            ...productoResponse,
            sku: productoResponse.sku,
            imagenes: productoResponse.imagenes[0].imagen
              ? productoResponse.imagenes.map(img => ({
                  source: `${productoResponse.proveedor?.id}/${img.imagen}`,
                  options: {
                    type: 'local',
                    metadata: {
                      id: img.imagenId,
                      poster: `${configUtils.createConfig().beritImg}${
                        productoResponse.proveedor?.id
                      }/${img.imagen}`,
                    },
                  },
                }))
              : [],
          })
          const textDescription = `${productoResponse.descripcion}`
          const htmlDescription = textDescription
          const stateFromHtml = stateFromHTML(htmlDescription)
          const description = EditorState.createWithContent(stateFromHtml)
          setEditor(description)
        })
        .catch((err: ErrorResponse) => {
          console.error('ProductoService.GetById error:', err)
          dispatch({
            type: PRODUCTO_GETBYID_ERROR,
            errorDescription: errorUtils.getDescription(err),
          })
        })
    } else {
      setLabel('Crear Producto')
      reset({
        sku: '',
        nombre: '',
        precio: '',
        proveedorId: '',
        href: '',
        imagenes: [],
      })
      const description = EditorState.createEmpty()
      setEditor(description)
    }
  }, [productoGetById, dispatch, productoId, isEdit, reset])

  const onSubmit = product => {
    const descriptionData = convertToRaw(editor.getCurrentContent())
    const { imagenes, ...producto } = product
    const data = {
      ...producto,
      descripcion: draftToHtml(descriptionData),
      categoriaId: 0,
    }
    const dataImg = {
      imagenes: imagenes.map(img => ({
        imagen: img.file.name,
        imagenId: img.getMetadata('id') || 0,
        href: img.serverId,
      })),
    }
    if (isEdit) {
      const dataUpdate = {
        ...data,
        ...dataImg,
      }
      productoUpdate(productoId, dataUpdate).then(({ productoId }) =>
        updateProductoImagenes({
          productoId,
          proveedorId: data.proveedor.id,
          ...dataImg,
          bsku: data.sku,
        })
      )
    } else {
      productoAdd(data)
        .then(productoId => {
          const data = {
            productoId,
            proveedorId: producto.proveedorId,
            sku: producto.sku,
            ...dataImg,
          }
          publishImg(data)
        })
        .catch(err => console.error(err))
    }
  }

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center">
        <MDBSpinner multicolor />
      </div>
    )
  }

  return (
    <>
      <MDBRow>
        <VolverA label="Productos" to="/admin/productos" />
      </MDBRow>
      <MDBRow className="mb-5">
        <MDBCol lg="8" className="mb-4 mx-auto">
          <MDBCard narrow className="mb-5">
            <MDBCardImage
              className="view view-cascade gradient-card-header blue-gradient"
              cascade
              tag="div"
            >
              <h2 className="h2-responsive mb-2">
                {productoId ? `Editar producto "${watch('nombre')}"` : 'Crear producto'}
              </h2>
            </MDBCardImage>
            <MDBCardBody>
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="needs-validation"
                noValidate
              >
                <BeritInput
                  control={control}
                  errors={errors}
                  rules="El SKU es obligatorio"
                  inputProps={{ maxLength: 11 }}
                  name="sku"
                  icon="barcode"
                  label="SKU"
                />
                <BeritInput
                  control={control}
                  errors={errors}
                  rules="El nombre es obligatorio"
                  name="nombre"
                  icon="address-book"
                  label="Nombre del producto"
                />
                <BeritInput
                  control={control}
                  errors={errors}
                  rules="El precio es obligatorio"
                  name="precio"
                  icon="dollar-sign"
                  label="Precio"
                />
                {!isEdit && (
                  <div className="col-xl-11 d-inline-block">
                    <BeritSelect
                      control={control}
                      includeEmpty
                      rules={{
                        required: 'Selecciona un proveedor',
                        valueAsNumber: true,
                      }}
                      name="proveedorId"
                      label="Proveedor"
                      options={proveedoresOptions}
                    />
                  </div>
                )}
                <BeritInput
                  control={control}
                  errors={errors}
                  name="href"
                  icon="exchange-alt"
                  label="Sitio web del producto"
                />

                <ImageUploader form={form} name="imagenes" isEdit={isEdit} />

                <BeritWysiwygEditor
                  stripPastedStyles
                  editorState={editor}
                  onEditorStateChange={setEditor}
                  placeholder="Descripción"
                />
                <div className="text-center">
                  <BeritProgressButton label={label} entityName="producto" type="submit" />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default (ProductosCrear: () => React$Node)
