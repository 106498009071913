// @flow
import { MDBContainer } from 'mdbreact/dist/mdbreact.esm'
import { useState } from 'react'

import DireccionForm from 'components/Direcciones/DireccionForm'
import AccordionFrame from 'components/PedidosAccordions/AccordionFrame'
import { ucfirst } from 'utils/stringUtils'

type DireccionFormContainerProps = {
  //   onRemove: any,
  type: 'rfc' | 'sucursal',
  index: number,
  control: Control,
  direccion: {
    nombre: string,
  },
}

const DireccionFormContainer = ({
  //   onRemove,
  type,
  index,
  control,
  direccion,
}: DireccionFormContainerProps) => {
  const isEdit = !!direccion[type + 'Id']
  const [toggleAccordion, setToggleAccordion] = useState(!isEdit)

  const headerLabel = direccion.nombre
    ? direccion.nombre
    : `${ucfirst(type === 'rfc' ? 'Dirección fiscal' : type)} #${index + 1}`

  return (
    <>
      {isEdit ? (
        <MDBContainer className="DirectionFormHandler md-accordion mb-3 mt-5">
          <AccordionFrame
            accordion={toggleAccordion}
            header={headerLabel}
            toggleCollapse={() => setToggleAccordion(!toggleAccordion)}
            className="m-3"
            card={false}
          >
            <DireccionForm control={control} headerLabel={headerLabel} index={index} type={type} />
            {/* {directions.length > 1 && (
            <i className="fas fa-trash-alt clickable danger float-right" onClick={onRemove} />
          )} */}
          </AccordionFrame>
        </MDBContainer>
      ) : (
        <DireccionForm control={control} headerLabel={headerLabel} index={index} type={type} />
      )}
    </>
  )
}

export default (DireccionFormContainer: DireccionFormContainerProps => React$Node)
