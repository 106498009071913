// @flow
import { MDBContainer } from 'mdbreact/dist/mdbreact.esm'

import AlmacenAccordion from './PedidosAccordions/AlmacenAccordion'
import ComprasAccordion from './PedidosAccordions/ComprasAccordion'
import CostosAccordion from './PedidosAccordions/CostosAccordion'
import DisenoAccordion from './PedidosAccordions/DisenoAccordion'
import EnviosEntregasAccordion from './PedidosAccordions/EnviosEntregasAccordion'
import FacturacionPagoAccordion from './PedidosAccordions/FacturacionPagoAccordion'
import ProduccionMaquilaAccordion from './PedidosAccordions/ProduccionMaquilaAccordion'
import SatisfaccionClienteAccordion from './PedidosAccordions/SatisfaccionClienteAccordion'

const PedidoSeguimiento = () => {
  return (
    <MDBContainer>
      <MDBContainer className="md-accordion">
        <CostosAccordion />
        <ComprasAccordion />
        <DisenoAccordion />
        <ProduccionMaquilaAccordion />
        <AlmacenAccordion />
        <EnviosEntregasAccordion />
        <SatisfaccionClienteAccordion />
        <FacturacionPagoAccordion />
      </MDBContainer>
    </MDBContainer>
  )
}

export default (PedidoSeguimiento: () => React$Node)
