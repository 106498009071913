// @flow
import { LOG_IN } from 'redux/constants'

const initialState = {
  correo: '',
  departamento: '',
  id: 0,
  nombres: '',
  prefijo: '',
  rfc: '',
  rol: 0,
  usuarioId: 0,
  puesto: '',
  sexo: null,
  telefono: '',
}

export type UsuarioAction = {|
  +type: string,
  usuario: Usuario,
|}

type UsuarioReducer = UsuarioState

const usuarioReducer = (
  state: UsuarioState = initialState,
  action: UsuarioAction
): UsuarioReducer => {
  switch (action.type) {
    case LOG_IN:
      return {
        id: action.usuario.usuarioId,
        ...action.usuario,
        nombre: action.usuario.nombres,
      }

    default:
      return state
  }
}

export default (usuarioReducer: (state: UsuarioState, action: UsuarioAction) => UsuarioReducer)
