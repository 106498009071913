// @flow
import TextField from '@material-ui/core/TextField'
import { Controller } from 'react-hook-form'
import './BeritInput.scss'

type BeritInputProps = {
  autoComplete?: 'on' | 'off',
  className?: string,
  control: Control,
  defaultValue?: string | number,
  fullWidth?: boolean,
  label?: string,
  name: string,
  rules?: string | Rules,
}

const BeritInput = ({
  autoComplete = 'off',
  className = '',
  control,
  defaultValue,
  fullWidth = true,
  label = '',
  name,
  rules = {},
  ...beritInputProps
}: BeritInputProps) => (
  <Controller
    control={control}
    defaultValue={defaultValue}
    name={name}
    rules={typeof rules === 'string' ? { required: rules } : rules}
    render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => (
      <TextField
        autoComplete={autoComplete}
        className={`BeritInput ${className}`}
        error={invalid}
        inputRef={ref}
        fullWidth={fullWidth}
        helperText={error && error.message}
        label={label}
        variant="standard"
        {...field}
        {...beritInputProps}
      />
    )}
  />
)

export default (BeritInput: BeritInputProps => React$Node)
