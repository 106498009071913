// @flow
import Skeleton from '@material-ui/core/Skeleton'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact/dist/mdbreact.esm'

const PedidoCotizacionEditarSkeletonBody = () => {
  return (
    <MDBContainer className="mt-3">
      <MDBRow>
        <MDBCol size="12" className="mb-3">
          <Skeleton variant="rect" animation="wave" height={100} />
        </MDBCol>
        <MDBCol size="12" className="mb-3">
          <Skeleton variant="rect" animation="wave" height={100} />
        </MDBCol>
        <MDBCol size="12" className="mb-3">
          <Skeleton variant="rect" animation="wave" height={100} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default (PedidoCotizacionEditarSkeletonBody: () => React$Node)
