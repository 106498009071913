// @flow
import Skeleton from '@material-ui/core/Skeleton'
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { MDBCardBody, MDBContainer, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  COTIZACION_ADD_PRODUCT,
  COTIZACION_CLEAR_PRODUCTS,
  COTIZACION_GETBYID_ERROR,
  COTIZACION_GETBYID_REQUEST,
} from '../redux/constants'
import BeritCard from './BeritCard'
import BeritProgressButton from './BeritProgressButton/BeritProgressButtonComponent'
import CotizacionHeader from './CotizacionHeader'
import Productos from './CotizacionProductos'
import LiveSearch from './LiveSearch'
import LiveSearchInputSkeleton from './LiveSearchInputSkeleton'
import PedidoCotizacionEditarSkeleton from './PedidoCotizacionEditarSkeleton'
import VolverA from './VolverA'
import { getCotizacion, getCotizacionProductos, getLoading, getUsuario } from 'redux/selectors'
import { useAdd, useGetById, useToast, useUpdate } from 'utils'

import './CotizacionCrear.scss'

const CotizacionCrear = () => {
  const { cotizacionId: paramId } = useParams()
  const cotizacionAdd = useAdd('cotizaciones')
  const cotizacionGetById = useGetById('cotizaciones')
  const cotizacionUpdate = useUpdate('cotizaciones')
  const history = useHistory()
  const dispatch = useDispatch()
  const toast = useToast()
  const usuario = useSelector(getUsuario)
  const form = useForm({
    defaultValues: {
      cliente: { id: 0, nombre: '', asignacion: '' },
      contactos: [],
      cotizacionNumero: 0,
      fecha: moment(),
      tiempoEntrega: 'De 8 a 10 días hábiles',
      sucursal: { id: 0, nombre: '' },
      usuario: usuario,
      vendedor: usuario,
    },
  })
  const { handleSubmit, reset, setValue } = form

  const productos = useSelector(getCotizacionProductos)
  const cotizacion = useSelector(getCotizacion)
  const isLoading = useSelector(getLoading(['COTIZACION_GETBYID']))
  const cotizacionId = cotizacion.cotizacionId

  useEffect(() => {
    if (paramId) {
      dispatch({ type: COTIZACION_GETBYID_REQUEST })
      cotizacionGetById(paramId)
        .then(cotizacion => {
          if (cotizacion.status === 2) {
            const cotizacionParse = JSON.parse(cotizacion.cotizacionJson)
            const productosEdit = JSON.parse(cotizacionParse.productosJson)
            reset({
              ...cotizacionParse,
              fecha: moment(cotizacionParse.fecha),
              cotizacionNumero: cotizacionParse.cotizacionNumero || cotizacion.id,
            })
            dispatch({
              type: COTIZACION_ADD_PRODUCT,
              producto: productosEdit.map(producto => {
                const blocksFromHTML = convertFromHTML(producto.productoDescripcion)
                const description = ContentState.createFromBlockArray(
                  blocksFromHTML.contentBlocks,
                  blocksFromHTML.entityMap
                )
                return {
                  ...producto,
                  productoDescripcion: convertToRaw(description),
                }
              }),
            })
          } else {
            history.push(`/admin/cotizaciones/ver/${+paramId}`)
          }
        })
        .catch(err => {
          toast(`ERROR: ID no encontrado en la base de datos`, err)
          dispatch({ type: COTIZACION_GETBYID_ERROR })
          history.push('/admin/cotizaciones')
        })
    } else {
      setValue('cotizacionNumero', '')
      dispatch({ type: COTIZACION_CLEAR_PRODUCTS })
    }
  }, [paramId, toast, history, reset, dispatch, cotizacionGetById, setValue])

  const onSubmit = cotizacionForm => {
    if (productos.length > 0) {
      const cotizacionPayload = {
        ...cotizacionForm,
        cotizacion: {
          id: cotizacionId,
        },
        productos: cotizacion.productos.map(producto => ({
          ...producto,
          imagenes: [
            producto.imagen,
            ...producto.imagenes
              .split(', ')
              .filter(imageFiltered => imageFiltered !== producto.imagen),
          ].join(', '),
          productoDescripcion: draftToHtml(producto.productoDescripcion),
        })),
      }

      cotizacionPayload.productosJson = JSON.stringify(cotizacionPayload.productos)
      cotizacionPayload.cotizacionJson = JSON.stringify(cotizacionPayload)

      if (paramId) {
        cotizacionUpdate(+paramId, cotizacionPayload)
      } else {
        cotizacionAdd(cotizacionPayload)
      }
    } else {
      toast('ERROR: Agrega al menos un producto a la cotización', 'error')
    }
  }

  if (isLoading && paramId) {
    return (
      <>
        <Skeleton variant="rect" animation="wave" height={25} width={190} />
        <MDBContainer>
          <BeritCard>
            <PedidoCotizacionEditarSkeleton />
            <LiveSearchInputSkeleton />
          </BeritCard>
        </MDBContainer>
      </>
    )
  }

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  return (
    <section className="CotizacionCrear">
      <MDBRow className="mb-3">
        <VolverA label="Cotizaciones" to="/admin/cotizaciones" />
      </MDBRow>
      <MDBContainer className="mb-5" id="cotizacion">
        <BeritCard>
          <div id="invoice">
            <MDBContainer fluid>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <CotizacionHeader form={form} />

                <LiveSearch type="cotizacion" />

                {productos && <Productos type="cotizacion" />}

                <section className="mb-4">
                  <MDBCardBody className="d-flex justify-content-end align-items-center">
                    <div>
                      <BeritProgressButton
                        label={paramId ? 'Actualizar y vista previa' : 'Guardar y vista previa'}
                        entityName="cotizacion"
                        type="submit"
                      />
                    </div>
                  </MDBCardBody>
                </section>
              </form>
            </MDBContainer>
          </div>
        </BeritCard>
      </MDBContainer>
    </section>
  )
}

export default (CotizacionCrear: () => React$Node)
