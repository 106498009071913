// @flow
import Skeleton from '@material-ui/core/Skeleton'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact/dist/mdbreact.esm'

const DashboardSkeletonComponent = () => {
  return (
    <MDBContainer>
      <div className="d-flex justify-content-center">
        <Skeleton animation="wave" variant="rect" width={200} height={50} />
      </div>

      <MDBRow className="mt-5">
        <Skeleton animation="wave" variant="rect" height="120px" width="100%" />
      </MDBRow>
      <MDBRow>
        <MDBCol sm="12" md="6">
          <Skeleton animation="wave" variant="text" height="700px" width="100%" />
        </MDBCol>
        <MDBCol sm="12" md="6">
          <Skeleton animation="wave" variant="text" height="700px" width="100%" />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol sm="12" md="6">
          <Skeleton animation="wave" variant="text" height="700px" width="100%" />
        </MDBCol>
        <MDBCol sm="12" md="6">
          <Skeleton animation="wave" variant="text" height="700px" width="100%" />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default DashboardSkeletonComponent
