// @flow
type BeritInlineComponentProps = {
  name: string,
  form: Form,
  label: string,
  hide?: boolean,
  aligned?: boolean,
}

const BeritInlineComponent = ({
  name,
  form,
  label,
  hide = false,
  aligned = false,
}: BeritInlineComponentProps) => {
  const { watch } = form
  const value = watch([name])

  return (
    <div className={`${hide ? 'd-none' : ''}`}>
      <div className="container row">
        <small style={{ color: '#4285f4' }}>{label}</small>
      </div>
      <span className={`${aligned ? 'container row' : ''}`}>{value}</span>
    </div>
  )
}

export default (BeritInlineComponent: BeritInlineComponentProps => React$Node)
