// @flow
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBRow,
} from 'mdbreact/dist/mdbreact.esm'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import BeritSelectObject from './BeritSelectObject'
import CreateEntitySkeleton from './CreateEntitySkeleton'
import VolverA from './VolverA'
import BeritInput from 'components/BeritInput'
import BeritProgressButton from 'components/BeritProgressButton'
import BeritSelect from 'components/BeritSelect'
import {
  CONTACTO_GETBYID_ERROR,
  CONTACTO_GETBYID_REQUEST,
  CONTACTO_GETBYID_SUCCESS,
} from 'redux/constants'
import { getLoading } from 'redux/selectors'
import { errorUtils, stringUtils, useAdd, useGet, useGetById, useToast, useUpdate } from 'utils'

const prefijoOptions = [
  { value: 'Sr.', text: 'Sr.' },
  { value: 'Sra.', text: 'Sra.' },
  { value: 'Srta.', text: 'Srta.' },
  { value: 'Lic.', text: 'Lic.' },
  { value: 'Ing.', text: 'Ing.' },
  { value: 'Doc.', text: 'Doc.' },
  { value: 'Contador Public.', text: 'Contador Public.' },
]

const sexoOptions = [
  { value: 'Masculino', text: 'Masculino' },
  { value: 'Femenino', text: 'Femenino' },
]

const tipoContactoOptions = [
  { id: 1, nombre: 'cliente' },
  { id: 2, nombre: 'proveedor' },
  { id: 3, nombre: 'maquilero' },
]

const ContactosCrear = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const { id } = useParams()
  const contactoId = id !== undefined ? parseInt(id) : -1
  const isEdit = contactoId > 0 && true
  const form = useForm({
    defaultValues: {
      contactoTipo: { id: 1, nombre: 'cliente' },
      contactoEntity: { id: 0, nombre: '', nombreComercial: '' },
      prefijo: '',
      departamento: '',
      puesto: '',
      nombres: '',
      apellidos: '',
      sexo: '',
      correo: '',
      celular: '',
      telefono: '',
      ext: '',
    },
  })
  const { handleSubmit, control, reset, watch, setValue } = form

  const clienteAdd = useAdd('contactos')
  const clientes: Cliente[] = useGet('clientes')
  const proveedores: Proveedor[] = useGet('proveedores')
  const maquileros: Maquilero[] = useGet('maquileros')
  const contactosGetById = useGetById('contactos')
  const contactoUpdate = useUpdate('contactos')
  const isFetching = useSelector(getLoading(['CLIENTE_GETBYID', 'CONTACTO_GETBYID']))

  const {
    contactoTipo: { nombre: nombreContactoTipo },
  } = watch()

  const contactsOptions = useMemo(() => {
    const tipoOptions = {
      cliente: clientes,
      proveedor: proveedores,
      maquilero: maquileros,
    }

    return tipoOptions[nombreContactoTipo]
  }, [clientes, proveedores, maquileros, nombreContactoTipo])

  useEffect(() => {
    if (isEdit) {
      dispatch({ type: CONTACTO_GETBYID_REQUEST })
      contactosGetById(contactoId)
        .then(contacto => {
          const contactEdited = {
            ...contacto,
            contactoTipo: { id: 1, nombre: 'cliente' },
            contactoEntity: {
              id: contacto.cliente.id,
              nombre: contacto.cliente.nombre,
              nombreComercial: contacto.cliente.nombre,
            },
          }
          reset(contactEdited)
          dispatch({
            type: CONTACTO_GETBYID_SUCCESS,
            contacto: contactEdited,
          })
        })
        .catch((err: ErrorResponse) => {
          dispatch({
            type: CONTACTO_GETBYID_ERROR,
            errorDescription: errorUtils.getDescription(err),
          })
          console.error('useGetById.contacto error: ', err)
          toast(errorUtils.getDescription(err), 'error')
        })
    }
  }, [contactosGetById, dispatch, contactoId, isEdit, toast, reset])

  const onSubmit = contactoForm => {
    if (isEdit) {
      contactoUpdate(contactoId, contactoForm)
    } else {
      clienteAdd(contactoForm)
    }
  }

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  if (isFetching) {
    return <CreateEntitySkeleton doubleBlock={true} />
  }

  return (
    <>
      <MDBRow>
        <VolverA label="Contactos" to="/admin/contactos" />
      </MDBRow>
      <MDBContainer>
        <MDBCard narrow className="mb-5">
          <MDBCardImage
            className="view view-cascade gradient-card-header blue-gradient"
            cascade
            tag="div"
          >
            <h2 className="h2-responsive mb-2">Contacto</h2>
          </MDBCardImage>
          <MDBCardBody>
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              className="needs-validation"
              noValidate
            >
              <MDBRow>
                <MDBCol xs="12" md="6">
                  <BeritSelectObject
                    control={control}
                    getOptionLabel={option => stringUtils.ucfirst(option.nombre)}
                    getOptionSelected={(option, value) => option.nombre === value.nombre}
                    label="Tipo de contacto"
                    name="contactoTipo"
                    options={tipoContactoOptions}
                    rules="Elige un tipo."
                    onChange={(_, option) => {
                      setValue('contactoTipo', option)
                      setValue('contactoEntity', { id: 0, nombre: '', nombreComercial: '' })
                    }}
                  />
                </MDBCol>
                <MDBCol xs="12" md="6">
                  {nombreContactoTipo.length > 0 && contactsOptions !== undefined && (
                    <BeritSelectObject
                      control={control}
                      emptyOption={{ id: 0, nombre: '', nombreComercial: '' }}
                      getOptionLabel={option => option.nombre}
                      getOptionSelected={(option, value) => option.id === value.id}
                      label={stringUtils.ucfirst(nombreContactoTipo)}
                      name="contactoEntity"
                      options={contactsOptions}
                      rules="Elige un tipo."
                    />
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol xs="12" md="6">
                  <BeritInput control={control} name="departamento" label="Departamento" />
                </MDBCol>

                <MDBCol xs="12" md="6">
                  <BeritSelect
                    control={control}
                    rules="Selecciona un prefijo"
                    name="prefijo"
                    label="Prefijo"
                    options={prefijoOptions}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol xs="12" md="6">
                  <BeritInput
                    control={control}
                    rules="El nombre es obligatorio"
                    name="nombres"
                    label="Nombres"
                  />
                </MDBCol>

                <MDBCol xs="12" md="6">
                  <BeritInput
                    control={control}
                    rules="El apellido es obligatorio"
                    name="apellidos"
                    label="Apellidos"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol xs="12" md="4">
                  <BeritSelect
                    control={control}
                    rules="Selecciona un género"
                    name="sexo"
                    label="Sexo"
                    options={sexoOptions}
                  />
                </MDBCol>

                <MDBCol xs="12" md="4">
                  <BeritInput
                    control={control}
                    rules={{
                      required: 'El correo electrónico es obligatorio',
                      pattern: {
                        value: /^\S+@\S+\.\S+$/,
                        message: 'Proporciona un correo electrónico válido',
                      },
                    }}
                    name="correo"
                    label="Correo electrónico"
                    type="email"
                  />
                </MDBCol>
                <MDBCol xs="12" md="4">
                  <BeritInput
                    control={control}
                    rules="Este campo es obligatorio"
                    name="puesto"
                    label="Puesto"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol xs="12" md="4">
                  <BeritInput control={control} name="celular" label="Celular" type="cel" />
                </MDBCol>
                <MDBCol xs="12" md="4">
                  <BeritInput control={control} name="telefono" label="Teléfono" type="tel" />
                </MDBCol>
                <MDBCol xs="12" md="4">
                  <BeritInput control={control} name="ext" label="Extensión" type="ext" />
                </MDBCol>
              </MDBRow>
              <div className="text-center">
                <BeritProgressButton
                  label={isEdit ? 'Guardar cambios' : 'Crear contacto'}
                  entityName="contacto"
                  type="submit"
                />
              </div>
            </form>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </>
  )
}

export default (ContactosCrear: () => React$Node)
