// @flow
import { toast } from 'mdbreact/dist/mdbreact.esm'
import { useCallback } from 'react'

const defaultProps = {
  autoClose: 10000,
  closeOnClick: true,
  draggable: true,
  hideProgressBar: false,
  newestOnTop: true,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  position: 'top-center',
}
const useToast = () =>
  useCallback((message, type = 'success', props = {}) => {
    props = {
      ...defaultProps,
      ...props,
    }
    switch (type) {
      case 'success':
        toast.success(message, props)
        break
      case 'info':
        toast.info(message, props)
        break
      case 'warning':
        toast.warn(message, props)
        break
      case 'error':
        toast.error(message, props)
        break
      default:
    }
  }, [])

export default (useToast: () => (
  message: string,
  type?: 'info' | 'success' | 'warning' | 'error',
  props?: {
    position?:
      | 'top-right'
      | 'top-center'
      | 'top-left'
      | 'bottom-right'
      | 'bottom-center'
      | 'bottom-left',
    autoClose?: number,
    closeOnClick?: boolean,
    hideProgressBar?: boolean,
    pauseOnFocusLoss?: boolean,
    pauseOnHover?: boolean,
    newestOnTop?: boolean,
  }
) => void)
