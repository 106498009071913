// @flow
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import AccordionFrame from './AccordionFrame'
import BeritCard from 'components/BeritCard'
import BeritDatePicker from 'components/BeritDatePicker'
import BeritInput from 'components/BeritInput'
import { useToast } from 'utils'

const AlmacenAccordion = () => {
  const [AlmacenAcc, setAlmacenAcc] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fecha1EntregaAlmacenBerit: moment(),
      cantidad1raEntrega: 0,
      fecha2EntregaAlmacenBerit: '',
      cantidad2daEntrega: 0,
      materialDefectuoso: '',
      reposicionMatDefectuoso: '',
      terminadoDeImpresion: '',
      fechaEmpaquetado: '',
    },
  })
  const toast = useToast()

  const onSubmit = almacen => console.warn(almacen)

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  return (
    <AccordionFrame
      accordion={AlmacenAcc}
      toggleCollapse={() => setAlmacenAcc(!AlmacenAcc)}
      header="Almacén"
    >
      <BeritCard>
        <h4 className="text-center">Almacén</h4>
        <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="Esta fecha es obligatoria"
                name="fecha1EntregaAlmacenBerit"
                label="1ra Entrega en almacen Berit"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="cantidad1raEntrega"
                label="Cant. 1ra entrega"
                type="number"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="Esta fecha es obligatoria"
                name="fecha2EntregaAlmacenBerit"
                label="2da Entrega en almacen Berit"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="cantidad2daEntrega"
                label="Cant. 2da entrega"
                type="number"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="materialDefectuoso"
                label="Material con defecto"
                type="number"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="reposicionMatDefectuoso"
                label="Reposición"
                type="number"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="Esta fecha es obligatoria"
                name="terminadoDeImpresion"
                label="Terminado de impresión"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="Esta fecha es obligatoria"
                name="fechaEmpaquetado"
                label="Fecha de empaquetado"
              />
            </MDBCol>
          </MDBRow>

          <div className="d-flex justify-content-center">
            <MDBBtn type="submit" color="blue">
              Guardar
            </MDBBtn>
          </div>
        </form>
      </BeritCard>
    </AccordionFrame>
  )
}

export default (AlmacenAccordion: () => React$Node)
