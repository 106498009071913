// @flow
import { useState } from 'react'

import BeritLightboxComponent from './BeritLightboxComponent'
import { productoImg } from 'utils/cotizacionesUtils'

type CotizacionVerProductoImageProps = {
  producto: {
    imagen: string,
    bsku: string,
    productoNombre: string,
    imagenes: string,
  },
}

const CotizacionVerProductoImage = ({ producto }: CotizacionVerProductoImageProps) => {
  const [lightBoxModaState, setLightBoxModaState] = useState(false)

  return (
    <>
      <img
        src={productoImg(producto.imagen)}
        className="clickable img-fluid rounde d-circle z-depth-2"
        title={`${producto.bsku}: ${producto.productoNombre}`}
        alt={`${producto.bsku}: ${producto.productoNombre}`}
        onClick={() => setLightBoxModaState(true)}
      />
      {lightBoxModaState && (
        <BeritLightboxComponent
          images={producto.imagenes.split(',').map(img => img.trim())}
          handleLightboxState={setLightBoxModaState}
          lightBoxState={lightBoxModaState}
          autoplay
        />
      )}
    </>
  )
}

export default (CotizacionVerProductoImage: CotizacionVerProductoImageProps => React$Node)
