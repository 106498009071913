// @flow
import TextField from '@material-ui/core/TextField'
import DatePicker from '@material-ui/lab/DatePicker'
import { Controller } from 'react-hook-form'

import './BeritDatePicker.scss'

type BeritDatePickerProps = {
  className?: string,
  clearable?: boolean,
  control: Control,
  defaultValue?: string | number,
  fullWidth?: boolean,
  label?: string,
  name: string,
  rules?: string | Rules,
}

const BeritDatePicker = ({
  className = '',
  clearable = false,
  control,
  defaultValue,
  fullWidth = true,
  label,
  name,
  rules = {},
  ...beritDatePickerProps
}: BeritDatePickerProps) => {
  const newRules = {
    ...(typeof rules === 'string' ? { required: rules } : rules),
    validate: {
      isValidDate: v => (v && v.isValid()) || 'La fecha es inválida',
    },
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={newRules}
      render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
        <DatePicker
          cancelText="Cancelar"
          clearable={clearable}
          disableMaskedInput
          inputRef={ref}
          inputFormat={'D [de] MMMM [de] YYYY'}
          todayText="Hoy"
          showTodayButton
          renderInput={params => (
            <TextField
              {...params}
              className={`BeritDatePicker ${className}`}
              error={invalid}
              fullWidth={fullWidth}
              label={label}
              inputProps={{
                ...params.inputProps,
                placeholder: 'dia [de] mes [de] año',
              }}
              helperText={error && error.message}
              variant="standard"
            />
          )}
          {...field}
          {...beritDatePickerProps}
        />
      )}
    />
  )
}

export default (BeritDatePicker: BeritDatePickerProps => React$Node)
