// @flow
import Autocomplete from '@material-ui/core/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Controller } from 'react-hook-form'
import './BeritAutocomplete.scss'

type BeritAutocompleteProps = {
  className?: string,
  control: Control,
  defaultValue?: string | number,
  disableClearable?: boolean,
  fullWidth?: boolean,
  groupBy?: any => string,
  emptyOption?: {
    [key: string]: any,
  },
  label?: string,
  name: string,
  noOptionsText?: string,
  options: any[],
  rules?: string | Rules,
  variant?: 'standard' | 'outlined' | 'filled',
}

const BeritAutocomplete = ({
  className = '',
  control,
  defaultValue,
  disableClearable = false,
  fullWidth = true,
  groupBy,
  label,
  name,
  noOptionsText,
  options,
  rules,
  emptyOption,
  variant = 'standard',
  ...beritAutocompleteProps
}: BeritAutocompleteProps) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={
        typeof rules === 'string'
          ? {
              validate: {
                validId: v => (typeof v === 'object' && v.id > 0) || rules,
              },
            }
          : rules
      }
      render={({ field: { onChange, ref, ...field }, fieldState: { invalid, error } }) => (
        <Autocomplete
          disableClearable={disableClearable}
          getOptionDisabled={option => option?.nombre === ''}
          getOptionLabel={option => option.nombre}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          groupBy={groupBy}
          noOptionsText={noOptionsText}
          onChange={(_, data, reason) => onChange(reason === 'clear' ? emptyOption : data)}
          options={emptyOption ? [emptyOption, ...options] : options}
          renderInput={params => (
            <TextField
              {...params}
              className={`BeritAutocomplete ${className}`}
              error={invalid}
              helperText={error && error.message}
              inputRef={ref}
              label={label}
              variant="standard"
            />
          )}
          {...field}
          {...beritAutocompleteProps}
        />
      )}
    />
  )
}

export default (BeritAutocomplete: BeritAutocompleteProps => React$Node)
