// @flow
import { MDBCol, MDBInput, MDBInputGroup, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import {
  CHANGE_COLOR_TALLA_VARIANTE,
  CHANGE_COLOR_TALLA_VARIANTE_PEDIDO,
  UPDATE_CALCULADORA,
  UPDATE_CALCULADORA_PEDIDOS,
} from 'redux/constants'
import { conditionalUtils, numberUtils } from 'utils'

import './CalculadoraVariants.scss'

type CalculadoraVariantsProps = {
  variante: Variante,
}

const CalculadoraVariants = ({ variante }: CalculadoraVariantsProps) => {
  const { cantidad, precioUnitario, variantId, bsku, color = '', talla = '' } = variante
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const calcAction = conditionalUtils.pathIncludes(
    pathname,
    '/admin/pedidos/crear',
    UPDATE_CALCULADORA_PEDIDOS,
    UPDATE_CALCULADORA
  )
  const changeColorVariant = conditionalUtils.pathIncludes(
    pathname,
    '/admin/pedidos/crear',
    CHANGE_COLOR_TALLA_VARIANTE_PEDIDO,
    CHANGE_COLOR_TALLA_VARIANTE
  )

  const updateVariant = (action, key, value) =>
    dispatch({
      type: action,
      key,
      value,
      bsku,
      variantId,
    })

  const cantidadHandler = value => updateVariant(calcAction, 'cantidad', value)
  const precioUnitarioHandler = value =>
    updateVariant(calcAction, 'precioUnitario', Number(value).toFixed(2))
  const totalPartidaHandler = value => updateVariant(calcAction, 'totalPartida', value)
  const colorTallaProducto = (key, value) => updateVariant(changeColorVariant, key, value)

  return (
    <>
      <MDBRow className="my-2 text-center align-items-center CalculadoraVariants">
        <MDBCol>
          <MDBInput
            label="Color"
            className="text-center customInputCalc"
            type="text"
            onChange={e => colorTallaProducto('color', e.target.value)}
            value={color}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            label="Talla"
            className="text-center customInputCalc"
            type="text"
            onChange={e => colorTallaProducto('talla', e.target.value)}
            value={talla}
          />
        </MDBCol>

        <div className="w-100 d-block d-lg-none" />

        <MDBCol>
          <MDBInputGroup
            label={'Cantidad'}
            labelClassName="with-label"
            containerClassName="my-0"
            className="text-center"
            prepend="&nbsp;(&nbsp;&nbsp;"
            append="&nbsp;&nbsp;×"
            type="number"
            material
            onChange={e => cantidadHandler(e.target.value)}
            value={cantidad + ''}
          />
        </MDBCol>
        <MDBCol>
          <MDBInputGroup
            label={'Precio unitario'}
            labelClassName="with-label"
            containerClassName="my-0"
            className="text-center"
            prepend="&nbsp;$&nbsp;"
            append=")&nbsp;="
            type="number"
            material
            onChange={e => precioUnitarioHandler(e.target.value)}
            value={precioUnitario + ''}
          />
        </MDBCol>
        <div className="w-100 d-block d-lg-none" />
        <MDBCol>
          <MDBInputGroup
            label={'Importe'}
            labelClassName="with-label"
            containerClassName="my-0"
            className="text-center font-weight-bold importe"
            prepend="&nbsp;$"
            append="&nbsp;&nbsp;&nbsp;"
            type="number"
            material
            disabled
            onChange={e => totalPartidaHandler(e.target.value)}
            value={numberUtils.Precio(cantidad * precioUnitario)}
          />
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default (CalculadoraVariants: CalculadoraVariantsProps => React$Node)
