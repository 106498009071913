// @flow
import { MDBBtn, MDBCard, MDBCardBody, MDBContainer, MDBIcon } from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import ProductosVer from './ProductosVer'
import BeritCard from 'components/BeritCard'
import HeaderVer from 'components/HeaderVer'
import PedidoCotizacionEditarSkeleton from 'components/PedidoCotizacionEditarSkeleton'
import PedidoEditarHeader from 'components/PedidoForm/PedidoEditarHeader'
import AccordionFrame from 'components/PedidosAccordions/AccordionFrame'
import PedidoSeguimiento from 'components/PedidoSeguimiento'
import VerTotal from 'components/VerTotal'
import { getLoading } from 'redux/selectors'
import { useGet, useGetById, useToast } from 'utils'

import beritBanner from 'assets/img/berit-banner.png'

import './../CotizacionHeader.scss'
import './../CotizacionVer.scss'
import './../CotizacionCondicionesComerciales.scss'

const PedidoVerComponent = () => {
  const { pedidoId } = useParams()
  const [dynamicChips, setDynamicChips] = useState({ emails: [], tels: [] })
  const [accordionProductosState, setaccordionProductosState] = useState(false)
  const [finishDate, setFinishDate] = useState(false)
  const toast = useToast()
  const { pathname } = useLocation()
  const productosApi: Producto[] = useGet('productos')
  const maquileroGetById = useGetById('maquileros')
  const pedido: Pedido[] = useGet('pedidos')
  const contactos: Contacto[] = useGet('contactos')
  const empleados: Empleado[] = useGet('empleados')
  const isLoading = useSelector(getLoading(['PEDIDO_GETALL', 'EMPLEADO_GETALL', 'CONTACTO_GETALL']))
  const pedidoFiltered = pedido.find(ped => ped.pedidoId === +pedidoId)
  const productos = pedidoFiltered?.productos
  const form = useForm({
    defaultValues: {
      pedidoBerit: 0,
      cliente: {
        id: 0,
        nombre: '',
        asignacion: '',
      },
      emails: '',
      fechaEntregaIni: '',
      fechaPedido: moment(),
      ordenCompra: '',
      sucursal: {
        id: 0,
        nombre: '',
      },
      rfc: {
        id: 0,
        nombre: '',
      },
      entregaPersona: '',
      entregaComentarios: '',
      vendedor: {
        id: 0,
        nombre: '',
      },
    },
  })
  const { handleSubmit, reset } = form

  useEffect(() => {
    maquileroGetById(pedidoId)
      .then(pedidoFetch => {
        reset({
          ...pedidoFetch,
          entregaPersona: pedidoFetch.entregaRecibe,
          sucursalId: 1,
          pedidoBerit: pedidoFetch.pedidoId,
          fechaEntregaIni: pedidoFetch.fechaEntregaClienteIni,
        })
      })
      .catch(err => toast(`ERROR: ${err}, intentelo mas tarde`, 'error'))
  }, [pedidoId, maquileroGetById, reset, toast])

  if (isLoading) {
    return <PedidoCotizacionEditarSkeleton />
  }

  const onSubmit = pedidoForm => console.warn(pedidoForm)
  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  return (
    <div className="CotizacionVer">
      {!pedidoId && (
        <section className="mb-4 print-topbar">
          <MDBCard>
            <MDBCardBody className="d-flex justify-content-end">
              <div>
                <MDBBtn color="primary" onClick={() => window.print()}>
                  <MDBIcon icon="print" className="left" /> Imprimir / Guardar PDF
                </MDBBtn>
              </div>
            </MDBCardBody>
          </MDBCard>
        </section>
      )}

      <BeritCard classes="PedidoForm mb-5 pb-3 p-2">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
          <section className="PedidoForm mb-4 container">
            {pathname.includes('revisar') ? (
              <HeaderVer
                banner={beritBanner}
                pedido={pedidoFiltered}
                cliente={pedidoFiltered?.clienteId}
                contactos={contactos}
                empleados={empleados}
                isPedido
              />
            ) : (
              <PedidoEditarHeader
                banner={beritBanner}
                dynamicChips={dynamicChips}
                finishDate={finishDate}
                form={form}
                pedido={pedidoFiltered}
                setDynamicChips={setDynamicChips}
                setFinishDate={setFinishDate}
              />
            )}

            <hr className="my-0" />
          </section>
        </form>
      </BeritCard>
      <MDBContainer className="md-accordion mt-5">
        <MDBContainer>
          <AccordionFrame
            accordion={accordionProductosState}
            toggleCollapse={() => setaccordionProductosState(accState => !accState)}
            header={productos ? `Productos (${productos?.length})` : ''}
          >
            <ProductosVer productos={productos ?? []} productosApi={productosApi} />

            <VerTotal productos={productos} />
          </AccordionFrame>
        </MDBContainer>
      </MDBContainer>
      {pathname.includes('seguimiento') && <PedidoSeguimiento />}
    </div>
  )
}

export default (PedidoVerComponent: () => React$Node)
