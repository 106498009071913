// @flow
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import AccordionFrame from './AccordionFrame'
import BeritCard from 'components/BeritCard'
import BeritCheckbox from 'components/BeritCheckbox'
import BeritDatePicker from 'components/BeritDatePicker'
import BeritInput from 'components/BeritInput'
import { useToast } from 'utils'

const FacturacionPagoAccordion = () => {
  const [facturaccionAcc, setFacturacionAcc] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      razonSocial: '',
      numFactura: 0,
      fechaFactura: moment(),
      fechaIngresoFacturaSistema: '',
      pagado: '',
    },
  })
  const toast = useToast()

  const onSubmit = facturacion => console.warn(facturacion)

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  return (
    <AccordionFrame
      accordion={facturaccionAcc}
      toggleCollapse={() => setFacturacionAcc(!facturaccionAcc)}
      header="Facturación y pago"
    >
      <BeritCard classes="mb-5">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="razonSocial"
                label="Razón social"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules="Este campo es obligatorio"
                name="numFactura"
                label="No. de factura"
                type="number"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="Esta fecha es obligatoria"
                name="fechaFactura"
                label="Fecha de factura"
              />
            </MDBCol>

            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="Esta fecha es obligatoria"
                name="fechaIngresoFacturaSistema"
                label="Ingreso de factura al sistema"
              />
            </MDBCol>
          </MDBRow>
          <BeritCheckbox control={control} name="pagado" label="Pagado" />

          <div className="d-flex justify-content-center">
            <MDBBtn type="submit" color="blue">
              Guardar
            </MDBBtn>
          </div>
        </form>
      </BeritCard>
    </AccordionFrame>
  )
}

export default (FacturacionPagoAccordion: () => React$Node)
