// @flow
import { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ImagesController } from 'controllers'
import { API_UPDATE_SINGLE_ELEMENT, entity } from 'redux/constants'
import { getAuthState } from 'redux/selectors'
import { useToast } from 'utils'

const useUpdateImagenes = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const auth = useSelector(getAuthState, shallowEqual)

  const EntityService = useMemo(() => new ImagesController(auth), [auth])

  const updateImage = useCallback(
    (data: {}) => {
      EntityService.UpdateProductoImagenes(data)
        .then(({ imagenes, productoId }) => {
          dispatch({
            type: API_UPDATE_SINGLE_ELEMENT,
            entityName: entity.producto,
            entityId: productoId,
            key: 'imagenes',
            value: imagenes,
          })
        })
        .catch(err => toast(`ERROR: ${err.description}`, 'error'))
    },
    [EntityService, toast, dispatch]
  )

  return updateImage
}

export default useUpdateImagenes
