// @flow
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
} from 'mdbreact/dist/mdbreact.esm'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import {
  API_UPDATE_ELEMENT,
  COTIZACION_CLEAR_COTIZACIONES,
  COTIZACION_DELETE_PRODUCT,
  COTIZACION_REFRESH_PRODUCT,
  entity,
  PEDIDO_DELETE_PRODUCT,
  PEDIDOS_CLEAR_PEDIDOS,
} from '../redux/constants'
import { conditionalUtils, htmlUtils, useGetById, useToast } from 'utils'

type EllipsisDropdownProps = {
  producto: ProductoPedido,
}

const EllipsisDropdown = ({ producto }: EllipsisDropdownProps) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const productoGetById = useGetById(entity.producto)
  const { pathname } = useLocation()

  const deleteProducto = conditionalUtils.pathCondition(
    pathname,
    '/admin/pedidos/crear',
    PEDIDO_DELETE_PRODUCT,
    COTIZACION_DELETE_PRODUCT
  )
  const vaciar = conditionalUtils.pathCondition(
    pathname,
    '/admin/pedidos/crear',
    PEDIDOS_CLEAR_PEDIDOS,
    COTIZACION_CLEAR_COTIZACIONES
  )

  const deleteProductHandler = bsku => {
    dispatch({
      type: deleteProducto,
      bsku,
    })
  }

  const handleUpdateProducto = () => {
    productoGetById(producto.productoId)
      .then(
        ({
          bsku,
          sku,
          nombre,
          precio,
          productoId,
          imagenes,
          proveedor,
          categoriaId,
          href,
          fechaCreacion,
          ultimaModificacion,
          descripcion,
        }) => {
          const productoDescripcion = htmlUtils.htmlToBlocksFormat(descripcion)

          const getImagenes = imagenes.map(({ imagen }) => `${proveedor.id}/${imagen}`)
          dispatch({
            type: COTIZACION_REFRESH_PRODUCT,
            bsku,
            productoNombre: nombre,
            imagen: getImagenes[0],
            imagenes: getImagenes.join(', '),
            precio,
            productoId,
            productoDescripcion,
          })
          dispatch({
            type: API_UPDATE_ELEMENT,
            entity: {
              id: productoId,
              nombre,
              bsku,
              sku,
              productoId,
              proveedor,
              categoriaId,
              descripcion: null,
              descripcionClean: null,
              precio,
              href,
              fechaCreacion,
              ultimaModificacion,
              imagenes,
            },
            entityName: 'productos',
            entityId: productoId,
          })
        }
      )
      .catch(err => toast(`Error: ${err.description}`, 'error'))
  }

  return (
    <MDBDropdown className="onHover-child">
      <MDBDropdownToggle color="primary">
        <i className="fas fa-ellipsis-v" />
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        <MDBDropdownItem>
          <a
            href={producto.productoHref}
            target="_blank"
            rel="noopener noreferrer"
            title={`Ver producto en sitio web de ${producto.proveedorNombre}`}
          >
            {producto.proveedorNombre} <MDBIcon icon="external-link-alt" />
          </a>
        </MDBDropdownItem>
        <MDBDropdownItem divider />
        <MDBDropdownItem onClick={handleUpdateProducto}>
          <span>Actualizar</span>
        </MDBDropdownItem>
        <MDBDropdownItem onClick={() => deleteProductHandler(producto.bsku)}>
          <span>Eliminar producto</span>
        </MDBDropdownItem>
        <MDBDropdownItem onClick={() => dispatch({ type: vaciar })}>
          <span className="text-danger">Vacíar</span>
        </MDBDropdownItem>
      </MDBDropdownMenu>
    </MDBDropdown>
  )
}

export default (EllipsisDropdown: EllipsisDropdownProps => React$Node)
