// @flow
import { MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { Controller } from 'react-hook-form'

import BeritInput from 'components/BeritInput'

type DireccionFormProps = {
  control: Control,
  headerLabel: string,
  index: number,
  type?: 'rfc' | 'sucursal',
}

const DireccionForm = ({ control, headerLabel, index, type = 'rfc' }: DireccionFormProps) => {
  return (
    <>
      <p className="h4 text-center mt-5 mb-3">{headerLabel}</p>

      <MDBRow>
        {type === 'rfc' && (
          <>
            <MDBCol md="6">
              <BeritInput
                control={control}
                name={`${type}.${index}.rfc`}
                label="* RFC"
                defaultValue=""
                rules={{
                  required: 'El RFC es obligatorio',
                  pattern: {
                    value:
                      /^[A-ZÑ\x26]{3,4}-?([0-9]{2})-?(0[1-9]|1[0-2])-?(0[1-9]|1[0-9]|2[0-9]|3[0-1])-?([A-Z0-9]{3})?$/i,
                    message: 'El formato del RFC es incorrecto',
                  },
                }}
              />
            </MDBCol>
            <MDBCol md="6">
              <BeritInput
                control={control}
                label="* Razón Social"
                defaultValue=""
                name={`${type}.${index}.razonSocial`}
                rules="La razón social es obligatoria"
              />
            </MDBCol>
          </>
        )}
        <Controller
          name={`${type}.${index}.${type}Id`} // rfcId | sucursalId
          control={control}
          defaultValue={0}
          render={({ field }) => <input type="hidden" {...field} />}
        />
        <MDBCol size="12">
          <BeritInput
            control={control}
            name={`${type}.${index}.nombre`}
            defaultValue=""
            label="* Nombre"
            rules="El nombre es obligatorio"
          />
        </MDBCol>
        <MDBCol md="6">
          <BeritInput
            defaultValue=""
            control={control}
            name={`${type}.${index}.calleAvenida`}
            label="* Calle / Avenida"
            rules="La calle o avenida es obligatoria"
          />
        </MDBCol>
        <MDBCol size="6" md="3">
          <BeritInput
            defaultValue=""
            control={control}
            name={`${type}.${index}.numExt`}
            label="* Núm. Ext."
            rules="El número exterior es obligatorio"
          />
        </MDBCol>
        <MDBCol size="6" md="3">
          <BeritInput
            control={control}
            name={`${type}.${index}.numInt`}
            defaultValue=""
            label="Núm. Int."
          />
        </MDBCol>
        <MDBCol md="4">
          <BeritInput
            control={control}
            defaultValue=""
            name={`${type}.${index}.cp`}
            label="* Código Postal"
            rules={{
              required: 'El código postal es obligatorio',
              pattern: {
                value: /^[0-9]{5}$/,
                message: 'El código postal deben ser 5 números.',
              },
            }}
          />
        </MDBCol>
        <MDBCol md="4">
          <BeritInput
            control={control}
            defaultValue=""
            name={`${type}.${index}.edificioPlaza`}
            label="Edificio / Plaza"
          />
        </MDBCol>
        <MDBCol md="4">
          <BeritInput
            control={control}
            name={`${type}.${index}.piso`}
            label="Piso"
            defaultValue=""
          />
        </MDBCol>
        <MDBCol md="6">
          <BeritInput
            control={control}
            name={`${type}.${index}.colonia`}
            defaultValue=""
            label="* Colonia"
            rules="La colonia es obligatoria"
          />
        </MDBCol>
        <MDBCol md="6">
          <BeritInput
            control={control}
            defaultValue=""
            name={`${type}.${index}.delMun`}
            label="* Delegación / Municipio"
            rules="La delegación ó municipio es obligatoria"
          />
        </MDBCol>
        <MDBCol md="6">
          <BeritInput
            control={control}
            defaultValue=""
            name={`${type}.${index}.estado`}
            label="* Estado"
            rules="El estado es obligatorio"
          />
        </MDBCol>
        <MDBCol md="6">
          <BeritInput
            control={control}
            name={`${type}.${index}.pais`}
            defaultValue="México"
            label="* País"
            rules="El país es obligatorio"
          />
        </MDBCol>
        <MDBCol>
          <BeritInput
            control={control}
            defaultValue=""
            name={`${type}.${index}.indicaciones`}
            label="Indicaciones"
          />
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default (DireccionForm: DireccionFormProps => React$Node)
