// @flow
import { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { SuperController } from 'controllers'
import { updateOk } from 'redux/actions/apiActions'
import { dispatchError } from 'redux/actions/errorsActions'
import { entity } from 'redux/constants'
import { getAuthState } from 'redux/selectors'
import { stringUtils, useToast } from 'utils'

const useUpdate = (entityName: string) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector(getAuthState, shallowEqual)
  const EntityService = useMemo(
    () => new SuperController(auth, stringUtils.singularize(entityName)),
    [entityName, auth]
  )
  const entityActionName = useMemo(
    () => stringUtils.singularize(entityName).toUpperCase(),
    [entityName]
  )

  const updateSuccess = useCallback(
    (message, redirect = true) => {
      toast(message)
      redirect &&
        setTimeout(() => {
          history.push(`/admin/${entityName.toLowerCase()}`)
        }, 2000)
    },
    [history, toast, entityName]
  )

  const entityUpdate = useCallback(
    (entityId, formData) => {
      if (entityId && EntityService) {
        dispatch({ type: `${entityActionName}_UPDATE_REQUEST` })
        const response = EntityService.Update(
          typeof entityId === 'number' ? +entityId : entityId,
          formData
        )
          .then(entityResponse => {
            dispatch({
              type: `${entityActionName}_UPDATE_SUCCESS`,
            })
            const isCotizacion = entityName === entity.cotizacion

            const entityUpdated = entityResponse[stringUtils.singularize(entityName)]
            const entityEdited = {
              ...entityUpdated,
              nombre: entityUpdated.nombreComercial || entityUpdated.nombre,
              id: entityUpdated[`${stringUtils.singularize(entityName)}Id`],
            }
            dispatch(updateOk(entityName, isCotizacion ? entityUpdated : entityEdited))

            isCotizacion
              ? history.push(`/admin/cotizaciones/ver/${entityUpdated.id}`)
              : updateSuccess(
                  `El ${stringUtils.singularize(entityName)} "${
                    entityUpdated.nombreComercial || entityUpdated.nombre
                  }" fue actualizado con éxito.`
                )

            if (entityName === entity.producto) {
              return entityUpdated
            }
          })
          .catch(err => {
            dispatch(dispatchError(`${entityActionName}_UPDATE_ERROR`, err))
            toast(`ERROR: ${err.description}`, 'error')
          })

        return response
      }
    },
    [EntityService, toast, dispatch, entityActionName, entityName, updateSuccess, history]
  )

  return entityUpdate
}

export default useUpdate
