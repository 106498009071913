import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

const Confirm = ({ state, onClose, children, okHandler }) => {
  return (
    <Dialog
      open={state}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={okHandler} color="primary" autoFocus>
          Publicar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Confirm
