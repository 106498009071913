// @flow
import { MDBAvatar, MDBCard, MDBCardBody } from 'mdbreact/dist/mdbreact.esm'

import ProfileCardSkeleton from './ProfileCardSkeleton'

import './ProfileCard.scss'

type ProfileCardProps = {
  nombre: string,
  departamento: string,
}

const ProfileCard = ({ nombre, departamento }: ProfileCardProps) => {
  if (!nombre) {
    return <ProfileCardSkeleton />
  }

  return (
    <MDBCard className="profile-card ProfileCard">
      <MDBAvatar
        tag="img"
        alt={nombre}
        src={`/img/usuarios/${nombre.split(' ')[0]}.jpg`}
        className="z-depth-1-half mb-2 mt-3 ProfilfeCardAvatar"
        circle
      />
      <MDBCardBody className="pt-0 mt-0">
        <h3 className="mb-1 font-bold">
          <strong>{nombre}</strong>
        </h3>
        <h6 className="font-bold cyan-text mb-4">{departamento}</h6>
      </MDBCardBody>
    </MDBCard>
  )
}

/**
 *  === ProfileCardProps ==
 * - nombre: Name of employe/user. [STRING]
 * - departamento: Department where the employee works. [STRING]
 **/
export default (ProfileCard: ProfileCardProps => React$Node)
