// @flow
import beritBanner from 'assets/img/berit-banner.png'

type BeritBannerProps = {
  className?: string,
}

const BeritBanner = ({ className = '' }: BeritBannerProps) => {
  return (
    <div className={className}>
      <a href="https://beritpublicidad.com/" target="_blank" rel="noopener noreferrer">
        <img src={beritBanner} alt="Banner Berit" className="banner-berit" />
      </a>
    </div>
  )
}

export default (BeritBanner: BeritBannerProps => React$Node)
