// @flow
import Skeleton from '@material-ui/core/Skeleton'
import { MDBCard, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'

type CreateEntitySkeletonProps = {
  doubleBlock?: boolean,
}

const CreateEntitySkeleton = ({ doubleBlock = false }: CreateEntitySkeletonProps) => {
  return (
    <>
      <MDBRow className="mb-5">
        <MDBCol lg="8" className="mb-4 mx-auto">
          <MDBCard narrow className="mb-5 align-items-center">
            <Skeleton animation="wave" width="80%" height="140px" />
            <Skeleton animation="wave" width="80%" height="100px" />
            <Skeleton animation="wave" width="80%" height="250px" />
            {doubleBlock && <Skeleton animation="wave" width="80%" height="230px" />}
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default CreateEntitySkeleton
