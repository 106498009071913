// @flow
import { MDBBtn, MDBSpinner } from 'mdbreact/dist/mdbreact.esm'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CLEAN_ERRORS_MESSAGES, CLEAN_SUCCESS } from 'redux/constants'
import { getErrorMessage, getLoading, getSuccess } from 'redux/selectors'

import './BeritProgressButton.scss'

const defaults = {
  nothing: '',
  loading: 'loading',
  success: 'success',
  error: 'error',
}

type BeritProgressButtonProps = {
  label: string,
  entityName: string,
  resultTime?: number,
  gradient?: string,
  size?: string,
  type?: string,
  className?: string,
}

const BeritProgressButton = ({
  label,
  entityName,
  resultTime = 1000,
  ...rest
}: BeritProgressButtonProps) => {
  const [buttonState, setButtonState] = useState('')
  const dispatch = useDispatch()
  const entitySubmit = `${entityName.toUpperCase()}_SUBMIT`
  const entityUpdate = `${entityName.toUpperCase()}_UPDATE`
  const entityDelete = `${entityName.toUpperCase()}_DELETE`
  const imagenUpload = 'IMAGENES_SUBMIT'
  const isLoading = useSelector(
    getLoading([entitySubmit, entityUpdate, entityDelete, imagenUpload])
  )
  const errorMessage = useSelector(getErrorMessage([entitySubmit, entityUpdate, entityDelete]))
  const isSuccess = useSelector(getSuccess([entitySubmit, entityUpdate, entityDelete]))

  const isError = !!errorMessage

  useEffect(() => {
    let showTime
    const nothing = () => setButtonState(defaults.nothing)

    const loading = () => {
      setButtonState(defaults.loading)
    }

    const success = () => {
      setButtonState(defaults.success)
    }

    const error = () => {
      setButtonState(defaults.error)

      showTime = setTimeout(() => {
        dispatch({ type: CLEAN_ERRORS_MESSAGES, entitySubmit })
        nothing()
      }, resultTime * 3)
    }

    if (isError) {
      error()
    } else if (isLoading) {
      loading()
    } else if (isSuccess) {
      success()
    } else {
      nothing()
    }
    return () => {
      if (isSuccess) {
        dispatch({ type: CLEAN_SUCCESS })
      } else if (isError) {
        dispatch({ type: CLEAN_ERRORS_MESSAGES, entitySubmit })
      }
      clearTimeout(showTime)
    }
  }, [entitySubmit, dispatch, isError, isLoading, isSuccess, resultTime])

  const classNames = `${
    buttonState === defaults.loading || buttonState === defaults.error ? 'isLoading' : ''
  } ${rest.className ?? ''}`

  return buttonState === defaults.success ? (
    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  ) : (
    <MDBBtn gradient="berit" size="lg" type="submit" {...rest} className={classNames}>
      {buttonState === defaults.nothing ? (
        label
      ) : buttonState === defaults.loading ? (
        <MDBSpinner yellow small />
      ) : (
        <span>
          <i className="fas fa-exclamation-triangle" />
          <b> Error</b>
        </span>
      )}
    </MDBBtn>
  )
}

export default BeritProgressButton
