// @flow
import { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ImagesController, SuperController } from 'controllers'
import { API_UPDATE_SINGLE_ELEMENT, entity, MODAL_SET, MODAL_TOGGLE } from 'redux/constants'
import { getAuthState } from 'redux/selectors'
import { stringUtils, useToast } from 'utils'

const usePublish = (entityName: string) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const auth = useSelector(getAuthState, shallowEqual)

  const EntityService = useMemo(
    () =>
      entityName !== 'img'
        ? new SuperController(auth, stringUtils.singularize(entityName))
        : new ImagesController(auth),
    [entityName, auth]
  )

  const publishEntity = useCallback(
    (entityIdOrData: number | {}, setStatus: (status: number) => void, hash) => {
      if (entityName !== 'img') {
        EntityService.Publish(entityIdOrData)
          .then(response => {
            dispatch({
              type: API_UPDATE_SINGLE_ELEMENT,
              entityName: entityName,
              entityId: entityIdOrData,
              key: 'status',
              value: response,
            })
            setStatus(response)
            dispatch({ type: MODAL_SET, payload: { status: 2, hash } })
            dispatch({ type: MODAL_TOGGLE })
          })
          .catch(err => toast(`ERROR: ${err.description}`, 'error'))
      } else {
        EntityService.Publish(entityIdOrData)
          .then(({ imagenes }) => {
            const { producto_id: productoId } = imagenes[0]
            dispatch({
              type: API_UPDATE_SINGLE_ELEMENT,
              entityName: entity.producto,
              entityId: productoId,
              key: 'imagenes',
              value: imagenes,
            })
          })
          .catch(err => console.error(err))
      }
    },
    [dispatch, entityName, EntityService, toast]
  )

  return publishEntity
}

export default usePublish
