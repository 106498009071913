// @flow
import { MDBContainer } from 'mdbreact/dist/mdbreact.esm'
import { Link } from 'react-router-dom'

type VolverAProps = {
  to: string,
  label: string,
  float?: string,
}

const VolverA = ({ to, label }: VolverAProps) => {
  return (
    <MDBContainer>
      <Link to={to} className="VolverA">
        <i className="fas fa-arrow-left mr-1" /> Volver a {label}
      </Link>
    </MDBContainer>
  )
}

export default (VolverA: VolverAProps => React$Node)
