// @flow
import { MDBView } from 'mdbreact/dist/mdbreact.esm'

import AddButton from './AddButton'

type EntityTableHeaderProps = {
  label: string,
  to: string,
}

const EntityTableHeader = ({ label, to }: EntityTableHeaderProps) => {
  return (
    <MDBView
      cascade
      className="gradient-card-header blue-gradient narrower py-3 mx-4 mb-0 d-flex justify-content-between"
    >
      <AddButton to={to} label={`Crear ${label}`} />
      <h4 className="white-text mx-3 text-center">{label}</h4>
      <i style={{ opacity: 0 }} className="fas fa-plus-circle fa-2x d- none" />
    </MDBView>
  )
}

export default (EntityTableHeader: EntityTableHeaderProps => React$Node)
