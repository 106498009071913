// @flow
import BeritCard from 'components/BeritCard'
import CostoFinal from 'components/CostoFinal'

const CostoTotal = () => {
  const totalMock = 339.5

  return (
    <BeritCard classes="mb-5">
      <h5>Costo total:</h5>
      <CostoFinal total={totalMock} />
    </BeritCard>
  )
}

export default (CostoTotal: () => React$Node)
