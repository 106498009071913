// @flow
import AdapterMoment from '@material-ui/lab/AdapterMoment'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import moment from 'moment'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { type Store as ReduxStore, type StoreEnhancer } from 'redux'
import { PersistGate } from 'redux-persist/integration/react'

import Admin from 'components/Admin'
import CotizacionVer from 'components/CotizacionVer'
import Landing from 'components/Landing'
import Login from 'components/Login'
import { type Action } from 'redux/reducers'

import 'moment/locale/es-mx'

type Store = ReduxStore<State, StoreEnhancer<State, Action>>
type AppProps = {
  store: Store,
  persistor: any,
}

const App = ({ store, persistor }: AppProps) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizationProvider dateAdapter={AdapterMoment} locale={moment.locale('es-mx')}>
        <Router>
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/login" exact component={Login} />
            <Route path="/cotizacion/:cotizacionHash" exact component={CotizacionVer} />

            <Admin />
          </Switch>
        </Router>
      </LocalizationProvider>
    </PersistGate>
  </Provider>
)

export default (App: AppProps => React$Node)
