// @flow
import axios from 'axios'
import {
  MDBAnimation,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBMask,
  MDBRow,
  MDBSpinner,
  MDBView,
} from 'mdbreact/dist/mdbreact.esm'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'

import Header from './Header'
import { LOG_IN } from 'redux/constants'
import { configUtils, errorUtils } from 'utils'
import './Login.scss'

const { beritApi } = configUtils.createConfig()

const Login = () => {
  const dispatch = useDispatch()
  const { state: locationState } = useLocation()
  const loggedInTo =
    locationState && locationState.from.pathname !== '/' ? locationState.from.pathname : '/admin'
  const isLoggedIn = useSelector<State, boolean>(state => state.auth.isAuthenticated)

  const [correo, setCorreo] = useState('')
  const [password, setPassword] = useState('')
  const [responseText, setResponseText] = useState('')
  const [responseStatus, setResponseStatus] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCorreoChange = correo => {
    setCorreo(correo)
    setResponseText('')
    setResponseStatus('')
  }
  const handlePasswordChange = password => {
    setPassword(password)
    setResponseText('')
    setResponseStatus('')
  }

  const handleSubmit = e => {
    e.target.className += ' was-validated'

    if (correo && password) {
      if (!loading) {
        setResponseText('')
        setResponseStatus('')
        setLoading(true)

        const data = new FormData()
        data.append('correo', correo)
        data.append('password', password)

        axios
          .post(`${beritApi}login/`, data, { withCredentials: true })
          .then(res => {
            setResponseText(res.data.description)
            setResponseStatus('success')

            console.warn(res)

            setTimeout(() => {
              dispatch({
                type: LOG_IN,
                usuario: res.data.usuario,
                auth: res.data.auth,
              })
            }, 1500)
          })
          .catch(err => {
            console.warn(err)
            if (err.response) {
              setResponseText(err.response.data.description)
              setResponseStatus('error')
              setLoading(false)
            } else {
              setResponseText(errorUtils.ERROR_UNEXPECTED)
              setResponseStatus('error')
              setLoading(false)
            }
          })
      } else {
        console.warn('loading... please wait')
      }
    }

    e.preventDefault()
  }

  return isLoggedIn ? (
    <Redirect to={loggedInTo} />
  ) : (
    <>
      <Helmet>
        <title>Berit Publicidad - Iniciar sesión</title>
      </Helmet>
      <div className="classic-form-page Login">
        <Header />

        <MDBView>
          <MDBMask
            className="d-flex justify-content-center align-items-center"
            overlay="blue-grey-strong"
          >
            <MDBContainer>
              <MDBRow>
                <MDBCol md="10" lg="6" xl="5" sm="12" className="mt-5 mx-auto">
                  <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                    <MDBCard>
                      <MDBCardBody>
                        <div className="form-header berit-gradient mb-4">
                          <h3>
                            <MDBIcon icon="user" className="mt-2 mb-2 text-white" /> Inicio de
                            sesión
                          </h3>
                        </div>
                        <MDBAnimation type={responseText ? 'zoomIn' : 'fadeOut'} duration="500ms">
                          {responseText && (
                            <MDBCard
                              color={`${responseStatus === 'success' ? 'green' : 'red'} lighten-1`}
                              text="white"
                              className="text-center mb-4"
                            >
                              <MDBCardBody>{responseText}</MDBCardBody>
                            </MDBCard>
                          )}
                        </MDBAnimation>

                        <MDBInput
                          className="mt-5"
                          type="email"
                          label="Tu correo"
                          icon="envelope"
                          iconClass="white-text"
                          name="correo"
                          required
                          onChange={e => handleCorreoChange(e.target.value)}
                          value={correo}
                        >
                          <div className="invalid-feedback ml-4 pl-3">
                            Proporciona un correo válido.
                          </div>
                        </MDBInput>
                        <MDBInput
                          type="password"
                          label="Tu contraseña"
                          icon="lock"
                          iconClass="white-text"
                          name="password"
                          required
                          onChange={e => handlePasswordChange(e.target.value)}
                          value={password}
                        >
                          <div className="invalid-feedback ml-4 pl-3">
                            Introduce una contraseña.
                          </div>
                        </MDBInput>
                        <div className="text-center mt-3 black-text">
                          <MDBBtn className="berit-gradient" size="lg" type="submit">
                            {loading ? <MDBSpinner yellow small /> : 'Iniciar sesión'}
                          </MDBBtn>
                        </div>

                        {/*
                        <hr />
                        <div className='inline-ul text-center d-flex justify-content-center'>
                          <a href='!#'>
                            <MDBIcon
                              fab
                              icon='twitter'
                              size='lg'
                              className='p-2 m-2  white-text'
                            />
                          </a>
                          <a href='!#'>
                            <MDBIcon
                              fab
                              icon='linkedin'
                              size='lg'
                              className='p-2 m-2 white-text'
                            />{' '}
                          </a>
                          <a href='!#'>
                            <MDBIcon
                              fab
                              icon='instagram'
                              size='lg'
                              className='p-2 m-2 white-text'
                            />
                          </a>
                      </div> */}
                      </MDBCardBody>
                    </MDBCard>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </div>
    </>
  )
}

export default (Login: () => React$Node)
