// @flow
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact/dist/mdbreact.esm'

type BeritCardTypes = {
  children: React$Node,
  classes?: string,
  title?: string,
}

const BeritCard = ({ children, classes = '', title }: BeritCardTypes) => {
  return (
    <MDBCard narrow className={`${classes} z-depth-2`}>
      {title && <MDBCardTitle>{title}</MDBCardTitle>}
      <MDBCardBody>{children}</MDBCardBody>
    </MDBCard>
  )
}

export default (BeritCard: BeritCardTypes => React$Node)
