// @flow
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import AccordionFrame from './AccordionFrame'
import BeritCard from 'components/BeritCard'
import BeritDatePicker from 'components/BeritDatePicker'
import BeritInput from 'components/BeritInput'
import { numberUtils, useToast } from 'utils'

const ComprasAccordion = () => {
  const [comprasAcc, setComprasAcc] = useState(false)
  const [totalOp, setTotal] = useState('')
  const {
    watch,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    cantidadExtra: 0,
    cantidadPedido: 0,
    cantidadTotal: 0,
    fechaEnvio: moment(),
    pagoAnticipadoProveedor: '',
    liquidacionProveedor: '',
  })
  const toast = useToast()
  const { cantidadExtra, cantidadPedido } = watch()

  useEffect(() => {
    const totalFinal = cantidadExtra + cantidadPedido
    if (totalFinal > 0) {
      setTotal(numberUtils.round(totalFinal.toFixed(0)))
    } else {
      setTotal('0')
    }
  }, [setTotal, cantidadExtra, cantidadPedido])

  const onSubmit = compra => {
    const totalFinal = {
      ...compra,
      cantidadTotal: totalOp,
    }
    console.warn(totalFinal)
  }

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  return (
    <AccordionFrame
      accordion={comprasAcc}
      toggleCollapse={() => setComprasAcc(!comprasAcc)}
      header="Compras"
    >
      <BeritCard classes="mb-5">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules={{
                  required: 'Este campo es obligatorio',
                  valueAsNumber: true,
                }}
                name="cantidadExtra"
                iconClass="grey-text"
                label="Cantidad extra"
              />
            </MDBCol>
            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                rules={{
                  required: 'Este campo es obligatorio',
                  valueAsNumber: true,
                }}
                name="cantidadPedido"
                iconClass="grey-text"
                label="Cantidad de pedido"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritInput
                control={control}
                errors={errors}
                name="cantidadTotal"
                className="text-center font-weight-bold"
                label="Cantidad Total"
                value={totalOp}
                disabled
              />
            </MDBCol>
            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="La fecha es obligatoria"
                name="fechaEnvio"
                iconClass="grey-text"
                label="Fecha de envio"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="La fecha es obligatoria"
                name="pagoAnticipadoProveedor"
                iconClass="grey-text"
                label="Fecha de pago anticipado"
              />
            </MDBCol>
            <MDBCol md="6" sm="12">
              <BeritDatePicker
                control={control}
                errors={errors}
                rules="La fecha es obligatoria"
                name="liquidacionProveedor"
                iconClass="grey-text"
                label="Fecha de liquidación"
              />
            </MDBCol>
          </MDBRow>
          <div className="d-flex justify-content-center">
            <MDBBtn type="submit" color="blue">
              Guardar
            </MDBBtn>
          </div>
        </form>
      </BeritCard>
    </AccordionFrame>
  )
}

export default (ComprasAccordion: () => React$Node)
