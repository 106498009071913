// @flow
import { MDBCard, MDBCardBody, MDBContainer } from 'mdbreact/dist/mdbreact.esm'
import { useParams } from 'react-router'

import BeritDataTable from './BeritDataTable'
import EntityTableHeader from './EntityTableHeader'
import TableSkeleton from './TableSkeleton'
import useDataTables from 'utils/hooks/useDataTables'
import { ucfirst } from 'utils/stringUtils'

import './VerEntidad.scss'

const VerEntidad = () => {
  const { entity } = useParams()
  const { dataEntity, isFetching } = useDataTables(entity)

  return (
    <MDBContainer className="DataTablesMaster" fluid>
      {entity && (
        <MDBCard narrow>
          <EntityTableHeader label={ucfirst(entity)} to={`/admin/${entity}/crear`} />
          <MDBCardBody className="data-table-container pt-0">
            {!isFetching && dataEntity ? (
              <BeritDataTable data={dataEntity} entityName={entity} />
            ) : (
              <TableSkeleton classNames="Clientes" />
            )}
          </MDBCardBody>
        </MDBCard>
      )}
    </MDBContainer>
  )
}

export default (VerEntidad: () => React$Node)
