// @flow
import Skeleton from '@material-ui/core/Skeleton'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact/dist/mdbreact.esm'

type TableSkeletonProps = {
  classNames?: string,
  header?: boolean,
}

const TableSkeleton = ({ classNames = '', header = true }: TableSkeletonProps) => {
  return (
    <MDBContainer className={classNames} fluid>
      {header && <Skeleton animation="wave" width="100%" height="70px" />}
      <MDBRow>
        <MDBCol size="6">
          <Skeleton className="float-left" animation="wave" width="50%" height="70px" />
        </MDBCol>
        <MDBCol size="6">
          <Skeleton className="float-right" animation="wave" width="30%" height="70px" />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="2">
          <Skeleton animation="wave" width="100%" height="70px" />
        </MDBCol>
        <MDBCol size="2">
          <Skeleton animation="wave" width="100%" height="70px" />
        </MDBCol>
        <MDBCol size="2">
          <Skeleton animation="wave" width="100%" height="70px" />
        </MDBCol>
        <MDBCol size="2">
          <Skeleton animation="wave" width="100%" height="70px" />
        </MDBCol>
        <MDBCol size="2">
          <Skeleton animation="wave" width="100%" height="70px" />
        </MDBCol>
        <MDBCol size="2">
          <Skeleton animation="wave" width="100%" height="70px" />
        </MDBCol>
      </MDBRow>
      <Skeleton animation="wave" width="100%" height="95px" />
      <Skeleton animation="wave" width="100%" height="95px" />
      <Skeleton animation="wave" width="100%" height="95px" />
      <Skeleton animation="wave" width="100%" height="95px" />
    </MDBContainer>
  )
}

export default (TableSkeleton: TableSkeletonProps => React$Node)
