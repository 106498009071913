// @flow
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import BeritCard from 'components/BeritCard'
import BeritInput from 'components/BeritInput'
import BeritSelect from 'components/BeritSelect'
import { numberUtils, useGet, useToast } from 'utils'

const CostoProveedor = () => {
  const [totalOp, setTotal] = useState('0')
  const proveedoresStore: Proveedor[] = useGet('proveedores')
  const {
    watch,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ defaultValues: { proveedorId: '', costoUnitario: 0, cantidad: 0, total: '' } })
  const toast = useToast()
  const { cantidad, costoUnitario } = watch()

  useEffect(() => {
    const ccu = cantidad * costoUnitario
    if (ccu > 0) {
      setTotal(numberUtils.round(ccu))
    } else {
      setTotal('0')
    }
  }, [setTotal, cantidad, costoUnitario])

  const proveedorOptions = useMemo(
    () =>
      proveedoresStore.map(proveedor => ({
        value: proveedor.proveedorId,
        text: proveedor.nombreComercial,
      })),
    [proveedoresStore]
  )

  const onSubmit = costoProveedor => {
    const costoTotalProveedor = {
      ...costoProveedor,
      total: totalOp,
    }
    console.warn(costoTotalProveedor)
  }

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  return (
    <BeritCard className="z-depth-2">
      <h5 className="text-center">Proveedores</h5>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
        <MDBRow>
          <MDBCol md="6" sm="12" className="text-center">
            <BeritSelect
              control={control}
              errors={errors}
              rules={{
                required: 'El proveedor es obligatorio',
                valueAsNumber: true,
              }}
              name="proveedorId"
              label="Proveedor"
              options={proveedorOptions}
            />
          </MDBCol>

          <MDBCol md="6" sm="12" className="text-center">
            <BeritInput
              control={control}
              errors={errors}
              rules={{
                required: 'Este campo es obligatorio',
                valueAsNumber: true,
              }}
              name="costoUnitario"
              label="Costo unitario"
              type="number"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6" sm="12" className="text-center">
            <BeritInput
              control={control}
              errors={errors}
              rules={{
                required: 'Este campo es obligatorio',
                valueAsNumber: true,
              }}
              name="cantidad"
              iconClass="grey-text"
              label="Cantidad"
              type="number"
            />
          </MDBCol>
          <MDBCol md="6" sm="12">
            <BeritInput
              control={control}
              errors={errors}
              name="total"
              className="text-center font-weight-bold"
              label="Total"
              type="number"
              disabled
              value={totalOp}
            />
          </MDBCol>
        </MDBRow>
        <div className="d-flex justify-content-center">
          <MDBBtn type="submit" color="blue">
            Guardar
          </MDBBtn>
        </div>
      </form>
    </BeritCard>
  )
}

export default (CostoProveedor: () => React$Node)
