// @flow
import { MDBInput } from 'mdbreact/dist/mdbreact.esm'
import { Controller } from 'react-hook-form'
import './BeritCheckbox.scss'

type BeritCheckboxProps = {
  className?: string,
  control: Control,
  filled?: boolean,
  label?: string,
  name: string,
  defaultValue?: boolean,
  rules?: string | Rules,
  variant?: 'filled' | 'outlined' | 'standard',
}

const BeritCheckbox = ({
  className = '',
  control,
  defaultValue,
  filled = true,
  label = '',
  name,
  rules = {},
  ...beritCheckboxProps
}: BeritCheckboxProps) => (
  <div className={`BeritCheckbox ${className}`}>
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={typeof rules === 'string' ? { required: rules } : rules}
      render={({ field: { ref, value, ...field }, fieldState: { invalid, error } }) => (
        <MDBInput
          checked={value}
          filled={filled}
          id={`checkbox_${name}`}
          inputRef={ref}
          label={label}
          ref={ref}
          type="checkbox"
          value={name}
          {...field}
          {...beritCheckboxProps}
        >
          {invalid && <div className="invalid-feedback">{error && error.message}</div>}
        </MDBInput>
      )}
    />
  </div>
)

export default (BeritCheckbox: BeritCheckboxProps => React$Node)
