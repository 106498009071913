// @flow
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  COTIZACION_CHANGE_PRODUCT_DESCRIPTION,
  COTIZACIONES_ADD_VARIANTE,
  PEDIDO_ADD_VARIANTE,
  PEDIDO_CHANGE_PRODUCT_DESCRIPTION,
} from '../redux/constants'
import BeritSelectMainImage from './BeritSelectMainImage/BeritSelectMainImage'
import BeritWysiwygEditor from './BeritWysiwygEditor'
import Calculadora from 'components/Calculadora'
import EllpsisDropdown from 'components/EllipsisDropdown'
import { getCotizacion, getPedidoProductos } from 'redux/selectors'
import { arrayUtils, conditionalUtils } from 'utils'

import './CotizacionProducto.scss'
import './EllipsisDropdown.scss'

type CotizacionProductoProps = {
  productoIndex: number,
  producto: ProductoPedido,
  isPedido?: boolean,
}

const CotizacionProducto = ({ productoIndex, producto, isPedido }: CotizacionProductoProps) => {
  const cotizacion = useSelector(getCotizacion)
  const productoPedido = useSelector(getPedidoProductos)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [editor, setEditor] = useState(
    EditorState.createWithContent(convertFromRaw(producto.productoDescripcion))
  )

  useEffect(() => {
    return () => {}
  }, [editor])

  const { productos: productoCotizacion } = cotizacion

  const coditional = conditionalUtils.pathIncludes(
    pathname,
    '/admin/pedidos/crear',
    productoPedido,
    productoCotizacion
  )
  const addVariant = conditionalUtils.pathIncludes(
    pathname,
    '/admin/pedidos/crear',
    PEDIDO_ADD_VARIANTE,
    COTIZACIONES_ADD_VARIANTE
  )
  const changeProductoDescripcion = conditionalUtils.pathIncludes(
    pathname,
    '/admin/pedidos/crear',
    PEDIDO_CHANGE_PRODUCT_DESCRIPTION,
    COTIZACION_CHANGE_PRODUCT_DESCRIPTION
  )

  const bsku = coditional[productoIndex]?.bsku
  const variantes = coditional[productoIndex]?.variantes ? coditional[productoIndex]?.variantes : []
  const { cantidad, precioUnitario, proveedorCosto, maquileroCosto } = arrayUtils.arrLastItem(
    coditional[productoIndex]?.variantes
  )

  const handleAddVariant = () => {
    dispatch({
      type: addVariant,
      variante: {
        cantidad: cantidad,
        precioUnitario: precioUnitario,
        totalPartida: precioUnitario * cantidad,
        proveedorCosto: proveedorCosto,
        maquileroCosto: maquileroCosto,
        color: '',
        bsku: producto.bsku,
        talla: '',
        variantId: new Date().getTime(),
      },
    })
  }

  const descriptionChanger = value => {
    setEditor(value)

    const descriptionData = convertToRaw(value.getCurrentContent())
    dispatch({
      type: changeProductoDescripcion,
      bsku,
      descripcion: descriptionData,
    })
  }
  return (
    <div className="mb-4 py-4 CotizacionProducto EllipsisDropdown">
      <MDBRow className="onHover">
        <MDBCol className="d-flex align-items-center ImagenProducto">
          <BeritSelectMainImage
            images={producto.imagenes.split(', ')}
            mainImage={producto.imagen}
            id={producto.productoId}
          />
        </MDBCol>
        <div className="w-100 d-block d-md-none" />
        <MDBCol>
          <MDBRow>
            <MDBCol size="6">
              <h4 className="mb-0" title={producto.proveedorNombre}>
                {producto.bsku}
              </h4>
            </MDBCol>
            <MDBCol size="6" className="text-right">
              <EllpsisDropdown producto={producto} />
            </MDBCol>

            <MDBCol size="12">
              <BeritWysiwygEditor
                toolbarOnFocus
                stripPastedStyles
                editorState={editor}
                onEditorStateChange={descriptionChanger}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>

      <Calculadora bsku={bsku} variantes={variantes}>
        <MDBRow style={{ justifyContent: 'center' }}>
          <MDBBtn size="sm" type="button" gradient="blue" onClick={handleAddVariant}>
            <span>Agregar variante </span>
            <i className="fas fa-plus" />
          </MDBBtn>
        </MDBRow>
      </Calculadora>
    </div>
  )
}

export default (CotizacionProducto: CotizacionProductoProps => React$Node)
