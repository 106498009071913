// @flow
import { API_ADD_ELEMENT, API_UPDATE_ELEMENT } from '../constants'

export const createOk = (entityName: string, entity: {}) => ({
  type: API_ADD_ELEMENT,
  entity,
  entityName,
})

export const updateOk = (entityName: string, entity: any) => {
  return {
    type: API_UPDATE_ELEMENT,
    entity,
    entityName,
    entityId: entity.id,
  }
}
