// @flow
import { createSelector } from 'reselect'

import { getPedidosState } from './stateSelectors'

export const getPedido: State => PedidoForm = createSelector(
  state => getPedidosState(state),
  pedidos => pedidos.pedido
)

export const getPedidoProductos: State => ProductoPedido[] = createSelector(
  state => getPedido(state),
  pedido => pedido.productos
)

export const getPedidoProductoByBsku: (bsku: string) => State => ?ProductoPedido = bsku =>
  createSelector(
    state => getPedidoProductos(state),
    pedidoProductos => {
      const pedidoProducto = pedidoProductos.find(pedidoProducto => pedidoProducto.bsku === bsku)
      return pedidoProducto
    }
  )

export const getPedidoProductoCalculadoraByBsku: (bsku: string) => State => ?Calculadora = bsku =>
  createSelector(
    state => getPedidoProductoByBsku(bsku)(state),
    pedidoProducto => pedidoProducto?.calculadora
  )
