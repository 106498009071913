// @flow
import Skeleton from '@material-ui/core/Skeleton'
import { MDBCol } from 'mdbreact/dist/mdbreact.esm'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import BeritAutocomplete from './BeritAutocomplete'
import BeritSelectObject from './BeritSelectObject'
import BeritTagAutocomplete from './BeritTagAutocomplete'
import { getLoading } from 'redux/selectors'
import { useGet } from 'utils'

type CotizacionHeaderSelectsProps = {
  form: Form,
}

const CotizacionHeaderSelects = ({ form }: CotizacionHeaderSelectsProps) => {
  const { control, watch, setValue } = form
  const {
    cliente: { id: clienteId },
  } = watch()

  const contactos: Contacto[] = useGet('contactos')
  const clientes: Cliente[] = useGet('clientes')
  const sucursales: Sucursal[] = useGet('sucursales')
  const isLoading = useSelector(
    getLoading(['CLIENTE_GETALL', 'CONTACTO_GETALL', 'SUCURSAL_GETALL'])
  )

  const clienteOptions = useMemo(
    () =>
      clientes
        .sort((a, b) => -b.nombre.localeCompare(a.nombre))
        .sort((a, b) => -b.asignacion.localeCompare(a.asignacion)),
    [clientes]
  )

  const sucursalesOptions = useMemo(
    () => sucursales.filter(sucursal => sucursal.clienteId === clienteId),
    [clienteId, sucursales]
  )

  const contactosOptions = useMemo(
    () =>
      contactos
        .filter(contacto => contacto.cliente.id === clienteId)
        .sort((a, b) => -b.correo.localeCompare(a.correo))
        .sort((a, b) => -b.nombre.localeCompare(a.nombre)),
    [clienteId, contactos]
  )

  if (isLoading) {
    return (
      <MDBCol size="12" md="6">
        <Skeleton animation="wave" width="100%" height="70px" />
        <Skeleton animation="wave" width="100%" height="70px" />
        <Skeleton animation="wave" width="100%" height="70px" />
        <Skeleton animation="wave" width="100%" height="70px" />
      </MDBCol>
    )
  }

  return (
    <>
      <MDBCol size="12" md="6">
        <BeritAutocomplete
          control={control}
          emptyOption={{ id: 0, nombre: '', asignacion: '' }}
          groupBy={option => option.asignacion}
          label="Cliente"
          name="cliente"
          noOptionsText="No se encontraron clientes"
          onChange={(_, cliente, reason) => {
            if (reason === 'clear') {
              setValue('cliente', { id: 0, nombre: '', asignacion: '' })
              setValue('sucursal', { id: 0, nombre: '' })
            } else {
              setValue('cliente', cliente, { shouldValidate: true })
              setValue(
                'sucursal',
                sucursales.find(sucursal => sucursal.clienteId === cliente.id) ?? {
                  id: 0,
                  nombre: '',
                }
              )
            }
            setValue('contactos', [])
          }}
          options={clienteOptions}
          rules={'El cliente es obligatorio'}
        />

        {sucursalesOptions.length > 0 && (
          <BeritSelectObject
            control={control}
            label="Sucursal"
            name="sucursal"
            noOptionsText="No se encontraron sucursales"
            options={sucursalesOptions}
          />
        )}

        {contactosOptions.length > 0 && (
          <BeritTagAutocomplete
            control={control}
            label="Buscar Contactos"
            name="contactos"
            noOptionsText="No se encontraron contactos"
            options={contactosOptions}
          />
        )}
      </MDBCol>
    </>
  )
}

export default (CotizacionHeaderSelects: CotizacionHeaderSelectsProps => React$Node)
