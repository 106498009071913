import { configUtils } from 'utils'

function BaseControllerImg() {
  const { beritImg } = configUtils.createConfig()
  this.IMG_ENDPOINT = beritImg
  this.controllerEndpoint = ''
  this.auth = {}
}

BaseControllerImg.prototype._handleRequest = async request => {
  const response = await fetch(request)
  if (!response.ok) {
    console.error(response)
    if (response.status === 401) {
      window.location.replace('/login')
    }
    if (response.status === 500) {
      const thro = await response.json()
      const status500 = {
        ...thro,
        description: 'Error en base de datos',
      }
      throw status500
    } else {
      throw await response.json()
    }
  }
  const textResponse = await response.text()
  try {
    const jsonResponse = JSON.parse(textResponse)
    return jsonResponse
  } catch (err) {
    return textResponse
  }
}

BaseControllerImg.prototype.GetById = async function (id) {
  const request = new Request(`${this.IMG_ENDPOINT}${this.controllerEndpoint}/?id=${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Token ${this.auth.accessToken}`,
    },
  })
  return await this._handleRequest(request)
}
BaseControllerImg.prototype.UpdateProductoImagenes = async function (entity) {
  const request = new Request(`${this.IMG_ENDPOINT}${this.controllerEndpoint}imagen/publish/`, {
    method: 'PUT',
    headers: {
      Authorization: `Token ${this.auth.accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(entity),
  })

  return await this._handleRequest(request)
}

export default BaseControllerImg
