export const arrLastItem = array => array[array.length - 1]

export const arrPositionExchanger = (array, currentPosition, nextPosition) => {
  let aux = ''

  aux = array[currentPosition]

  array[currentPosition] = array[nextPosition]

  array[nextPosition] = aux
  return array
}
