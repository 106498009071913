// @flow
import { Fragment } from 'react'
import { useLocation } from 'react-router'

import CalculadoraHeader from './CalculadoraHeader'
import CalculadoraVariante from './CalculadoraVariants'
import CostosCalculadora from './CostosCalculadora'

import './Calculadora.scss'

type CalculadoraProps = {
  bsku: string,
  children?: React$Node,
  variantes: Variante[],
}

const Calculadora = ({ bsku, variantes, children }: CalculadoraProps) => {
  const { pathname } = useLocation()

  return (
    <div className="Calculadora">
      <CalculadoraHeader bsku={bsku} />

      {variantes.map(variante => (
        <Fragment key={variante.variantId}>
          <CalculadoraVariante variante={variante} />
          {pathname.includes('/admin/pedidos/crear') && (
            <CostosCalculadora bsku={variante.bsku} variantId={variante.variantId} />
          )}
        </Fragment>
      ))}

      {children}
    </div>
  )
}

export default (Calculadora: CalculadoraProps => React$Node)
