// @flow
import { MDBContainer } from 'mdbreact/dist/mdbreact.esm'

import PedidoForm from './PedidoForm'

const PedidosCrear = () => {
  return (
    <MDBContainer>
      <PedidoForm title="" />
    </MDBContainer>
  )
}
export default (PedidosCrear: () => React$Node)
