// @flow
const initialState = {}

type LoadingReducer = LoadingState

export type LoadingAction = {|
  +type: string,
|}

const loadingReducer = (
  state: LoadingState = initialState,
  action: LoadingAction
): LoadingReducer => {
  const { type } = action
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type)

  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  }
}

export default (loadingReducer: (state: LoadingState, action: LoadingAction) => LoadingReducer)
