// @flow
export const createConfig = () => {
  switch (process.env.REACT_APP_API) {
    case 'prod': {
      return {
        isProduction: process.env.NODE_ENV === 'production',
        env: process.env.NODE_ENV,
        beritApi: 'https://api.beritpublicidad.com/',
        beritImg: 'https://img.beritpublicidad.com/',
        beritWeb: 'https://beritpublicidad.com/',
      }
    }
    case 'qa':
      return {
        isProduction: process.env.NODE_ENV === 'production',
        env: process.env.NODE_ENV,
        beritApi: 'https://api.qa.beritpublicidad.com/',
        beritImg: 'https://img.qa.beritpublicidad.com/',
        beritWeb: 'https://qa.beritpublicidad.com/',
      }
    default: {
      return {
        isProduction: process.env.NODE_ENV === 'production',
        env: process.env.NODE_ENV,
        beritApi: 'http://api.beritpublicidad.localhost/',
        beritImg: 'http://img.beritpublicidad.localhost/',
        beritWeb: 'http://beritpublicidad.localhost:3000/',
      }
    }
  }
}

export default createConfig
