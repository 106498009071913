// @flow
import { LOG_IN, LOG_OUT } from 'redux/constants'

const initialState = {
  accessToken: '',
  expiresInSeconds: 0,
  refreshToken: '',
  isAuthenticated: false,
}

type AuthReducer = AuthState

export type AuthAction = {|
  +type: string,
  auth: {
    accessToken: string,
    expiresInSeconds: number,
    isAuthenticated: boolean,
    refreshToken: string,
  },
|}

const authReducer = (state: AuthState = initialState, action: AuthAction): AuthReducer => {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        ...action.auth,
      }

    case LOG_OUT:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default (authReducer: (state: AuthState, action: AuthAction) => AuthReducer)
