// @flow
import configUtils from './configUtils'

import noImage from 'assets/img/no-image.jpg'

const config = configUtils()

export const thumbFilename = (filename: string): string =>
  filename.substr(0, filename.lastIndexOf('.')) +
  '.thumb.' +
  filename.substr(filename.lastIndexOf('.') + 1)

export const productoImg = (imagenUrl: string): string =>
  imagenUrl ? `${config.beritImg}${imagenUrl}` : noImage

export const productoImgThumb = (imagenUrl: string): string =>
  imagenUrl ? `${config.beritImg}${thumbFilename(imagenUrl)}` : noImage

export const formatPrecio = (precio: number): string =>
  '  ' +
  (precio
    ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(precio)
    : '$ 0')
