// @flow
import Popper from '@material-ui/core/Popper'

type BeritPopperProps = {
  anchorEl: string | null,
  children: React$Node,
  placement?: string,
  height?: string,
  overflow?: string,
  backgroundColor?: string,
  textColor?: string,
  isClickable?: boolean,
}

const BeritPopper = ({
  anchorEl,
  children,
  placement = 'top',
  height = 'auto',
  overflow = 'auto',
  backgroundColor = 'gray',
  textColor = 'white',
  isClickable = false,
  ...props
}: BeritPopperProps) => {
  const open = Boolean(anchorEl)
  const id = open ? 'costoTecnica-popper' : undefined
  return (
    <Popper
      {...props}
      placement={placement}
      id={id}
      open={open}
      anchorEl={anchorEl}
      className={isClickable ? 'clickable' : ''}
      style={{ zIndex: 2 }}
    >
      <div
        style={{
          border: '0px',
          borderRadius: '5px',
          padding: '5px',
          color: textColor,
          backgroundColor,
          height,
          overflow,
        }}
      >
        {children}
      </div>
    </Popper>
  )
}

/**
 *  ==== BeritPopperProps ====
 * - anchorEl: This is basically the state of the Popper.
 * - placement: Position where we want to show the Popper ('top', 'botton', 'left', 'right')(Default: "top")[String].
 * - height: This is essentialy the height of the tooltip/popper(Default: "auto")[String].
 * - overflow: If you want to be able to scrolling inside the tooltip/popper(Default: "auto")[String].
 * - backgroundColor: Background color of the tooltip/popper (Default: "gray")[String].
 * - textColor: It's the text color inside the tooltip/popper (Default: "white")[String].
 * - isClickable: If you want to make clickable the whole tooltip/popper (Default: true)[Boolean].
 *
 * If the property has a default value then is optional.
 */

export default (BeritPopper: BeritPopperProps => React$Node)
