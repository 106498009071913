import { Editor } from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const BeritWysiwygEditor = ({ ...rest }) => {
  const toolbarOptions = {
    options: ['inline', 'blockType', 'colorPicker'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    colorPicker: {
      colors: ['rgb(255,0,0)', 'rgb(249,229,56)'],
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
  }

  return (
    <Editor
      toolbar={toolbarOptions}
      toolbarStyle={{ width: '50%' }}
      localization={{ locale: 'es' }}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      {...rest}
    />
  )
}

export default BeritWysiwygEditor
