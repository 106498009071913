// @flow
import Skeleton from '@material-ui/core/Skeleton'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact/dist/mdbreact.esm'

const PedidoProductosSeguimientoSkeleton = () => {
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <Skeleton variant="rect" animation="wave" height={190} />
        </MDBCol>
        <MDBCol>
          <Skeleton variant="rect" animation="wave" height={25} width="49%" />
          <Skeleton className="mt-1" variant="rect" animation="wave" height={25} width="60%" />
          <Skeleton className="mt-1" variant="rect" animation="wave" height={25} width="65%" />
        </MDBCol>
        <MDBCol>
          <Skeleton variant="rect" animation="wave" height={25} />
          <Skeleton className="mt-1" variant="rect" animation="wave" height={25} />
          <Skeleton className="mt-1" variant="rect" animation="wave" height={25} />
          <Skeleton className="mt-1" variant="rect" animation="wave" height={25} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default (PedidoProductosSeguimientoSkeleton: () => React$Node)
