// @flow
import { MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'

import BeritInput from 'components/BeritInput'
import BeritSelectObject from 'components/BeritSelectObject'
import SubheaderBar from 'components/SubheaderBar'

type EntregaProps = {
  form: Form,
  sucursalOptions: {
    id: number,
    nombre: string,
  }[],
}

const Entrega = ({ form, sucursalOptions }: EntregaProps) => {
  const { control } = form
  return (
    <>
      <SubheaderBar label="Entrega" />

      {sucursalOptions.length > 0 ? (
        <MDBRow>
          <MDBCol>
            <BeritSelectObject
              control={control}
              name="sucursal"
              label="Dirección de entrega"
              getOptionSelected={(option, value) => option.sucursalId === value.sucursalId}
              options={sucursalOptions}
            />
          </MDBCol>
          <MDBCol>
            <BeritInput control={control} name="entregaPersona" label="Persona para entregar" />
          </MDBCol>
          <MDBCol>
            <BeritInput
              control={control}
              name="entregaComentarios"
              label="Comentarios de entrega"
            />
          </MDBCol>
        </MDBRow>
      ) : (
        <p className="text-center">El cliente no tiene direcciones asignadas.</p>
      )}
    </>
  )
}

export default (Entrega: EntregaProps => React$Node)
