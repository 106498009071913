// @flow
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { Fragment } from 'react'

type PedidoVerHeaderProps = {
  banner: string,
  pedido: Pedido,
  contactos: Contacto[],
  empleados: Empleado[],
}

const PedidoVerHeader = ({ banner, pedido, contactos, empleados }: PedidoVerHeaderProps) => {
  const empleado = empleados?.find(empleado => empleado.contactoId === pedido?.vendedor.id)
  const currentContacts = pedido?.contactos.map(id =>
    contactos.find(contacto => (contacto.contactoId === Number(id) ? contacto : ''))
  )

  return (
    <MDBCard className="CotizacionHeader">
      <MDBCardBody>
        {pedido && empleado && (
          <MDBRow>
            <MDBCol size="12">
              <MDBRow>
                <MDBCol size="12" md="6">
                  <h2 className="numero-cotizacion">Pedido # {pedido.pedidoId}</h2>
                  <p>{pedido.vendedor.nombre}</p>
                  <p>{empleado.correo}</p>
                  <p>Tel. {empleado.telefono}</p>
                  <p className="cliente">{pedido.cliente.nombre}</p>
                </MDBCol>
                <MDBCol size="12" md="6" className="usuario">
                  <div>
                    <img src={banner} alt="Banner Berit" className="banner-berit" />
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <MDBCol size="12" md="6" className="cliente-contacto">
              {currentContacts?.map(contacto => (
                <Fragment key={contacto?.contactoId}>
                  {contacto ? (
                    <Fragment>
                      <p className="contacto">
                        {contacto.nombres} {contacto.apellidos}
                      </p>
                      <p>
                        <a href={`mailto:${contacto.correo}`}>{contacto.correo}</a>/
                        <a href={`tel:${contacto.telefono}`}>{contacto.telefono}</a>
                      </p>
                    </Fragment>
                  ) : (
                    ''
                  )}
                </Fragment>
              ))}
            </MDBCol>

            <MDBCol size="12" md="6" className="fecha">
              <p>
                <span>Fecha:</span>
                {moment(pedido.fechaPedido).format('D [de] MMMM [de] YYYY')}
              </p>
            </MDBCol>
          </MDBRow>
        )}
      </MDBCardBody>
    </MDBCard>
  )
}

export default (PedidoVerHeader: PedidoVerHeaderProps => React$Node)
