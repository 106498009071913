// @flow
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact/dist/mdbreact.esm'

import BeritInline from 'components/BeritInline'
import BeritSelectObject from 'components/BeritSelectObject'
import SubheaderBar from 'components/SubheaderBar'

type FacturacionProps = {
  form: Form,
  rfcOptions: {
    id: number,
    nombre: string,
  }[],
}

const Facturacion = ({ form, rfcOptions }: FacturacionProps) => {
  const { control, watch } = form
  const {
    rfc: { rfcId },
  } = watch()

  return (
    <>
      <SubheaderBar label="Facturación" />

      {rfcOptions?.length > 0 ? (
        <>
          <MDBRow>
            <MDBCol size="6">
              {rfcOptions.length > 1 ? (
                <BeritSelectObject
                  control={control}
                  getOptionLabel={option => {
                    return option.rfcId === 0
                      ? option.nombre && option.nombre
                      : `${option.rfc} - ${option.nombre}`
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  label="RFC"
                  name="rfc"
                  options={rfcOptions}
                  rules="Elige un RFC"
                />
              ) : (
                <>
                  <BeritInline form={form} name="rfc.nombre" label="RFC" />
                </>
              )}
            </MDBCol>
            {rfcId !== 0 && (
              <MDBCol size="6">
                <BeritInline form={form} name="rfc.razonSocial" label="Razón social" />
              </MDBCol>
            )}
          </MDBRow>
          {rfcId !== 0 && (
            <MDBRow>
              <MDBCol>
                <BeritInline form={form} name="rfc.direccion" label="Dirección Fiscal" />
              </MDBCol>
            </MDBRow>
          )}
        </>
      ) : (
        <MDBContainer>
          <p className="text-center">El cliente no tiene datos de facturación asignados.</p>
        </MDBContainer>
      )}
    </>
  )
}

export default (Facturacion: FacturacionProps => React$Node)
