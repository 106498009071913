// @flow
import { MDBCol, MDBInputGroup, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useDispatch, useSelector } from 'react-redux'

import { UPDATE_CALCULADORA_PEDIDOS } from 'redux/constants'
import { getPedidoProductoByBsku } from 'redux/selectors'
import { numberUtils } from 'utils'

type CostosCalculadoraProps = {
  bsku: string,
  variantId: number,
}

const CostosCalculadora = ({ bsku, variantId }: CostosCalculadoraProps) => {
  const dispatch = useDispatch()
  const productoPedido = useSelector(getPedidoProductoByBsku(bsku))
  const variante = productoPedido?.variantes.find(variante => variante.variantId === variantId)
  const { cantidad, proveedorCosto, maquileroCosto } = variante ?? {
    cantidad: 0,
    proveedorCosto: 0,
    maquileroCosto: 0,
  }
  const numeroTintas = productoPedido?.numeroTintas ?? 0
  const costoTotalProveedor = numberUtils.Precio(proveedorCosto * cantidad)
  const costoTotalMaquilero = numberUtils.Precio(maquileroCosto * cantidad * numeroTintas)

  const updateCalculadoraVariante = key => e =>
    dispatch({
      type: UPDATE_CALCULADORA_PEDIDOS,
      key,
      value: e.target.value,
      bsku,
      variantId,
    })

  return (
    <>
      <MDBRow center>
        <MDBCol size="12" lg="3">
          <MDBInputGroup
            label={'Costo unitario proveedor'}
            labelClassName="with-label"
            containerClassName="my-0"
            className="text-center"
            prepend="($&nbsp;"
            append="&nbsp;=>"
            type="number"
            material
            onChange={updateCalculadoraVariante('proveedorCosto')}
            value={proveedorCosto.toString()}
          />
        </MDBCol>
        <MDBCol size="12" lg="3">
          <MDBInputGroup
            label={'Costo total proveedor'}
            labelClassName="with-label"
            containerClassName="my-0"
            className="text-center font-weight-bold importe"
            prepend="$&nbsp;"
            append=")&nbsp;"
            type="number"
            material
            value={costoTotalProveedor.toString()}
          />
        </MDBCol>
      </MDBRow>
      {/* <div className="w-100 d-block d-lg-n one" /> */}
      <MDBRow center>
        <MDBCol size="12" lg="3">
          <MDBInputGroup
            label={'Costo unitario maquilero'}
            labelClassName="with-label"
            containerClassName="my-0"
            className="text-center"
            prepend="($&nbsp;"
            append="&nbsp;=>"
            type="number"
            material
            onChange={updateCalculadoraVariante('maquileroCosto')}
            value={maquileroCosto.toString()}
          />
        </MDBCol>
        <MDBCol size="12" lg="3">
          <MDBInputGroup
            label={'Costo total maquilero'}
            labelClassName="with-label"
            containerClassName="my-0"
            className="text-center font-weight-bold importe"
            prepend="$&nbsp;"
            append=")&nbsp;"
            type="number"
            material
            value={costoTotalMaquilero.toString()}
          />
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default (CostosCalculadora: CostosCalculadoraProps => React$Node)
