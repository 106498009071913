// @flow
import {
  API_ADD_ELEMENT,
  API_UPDATE_ELEMENT,
  API_UPDATE_SINGLE_ELEMENT,
  CLIENTE_GETALL_SUCCESS,
  CONTACTO_GETALL_SUCCESS,
  COTIZACION_GETALL_SUCCESS,
  EMPLEADO_GETALL_SUCCESS,
  MAQUILERO_GETALL_SUCCESS,
  PEDIDO_GETALL_SUCCESS,
  PRODUCTO_GETALL_SUCCESS,
  PROVEEDOR_GETALL_SUCCESS,
  REPORTE_GETALL_SUCCESS,
  SUCURSAL_GETALL_SUCCESS,
  TECNICA_GETALL_SUCCESS,
} from 'redux/constants'

const initialState = {
  clientes: [],
  contactos: [],
  cotizaciones: [],
  empleados: [],
  maquileros: [],
  pedidos: [],
  productos: [],
  proveedores: [],
  sucursales: [],
  tecnicas: [],
  reportes: {},
}

type ApiReducer = ApiState

export type ApiAction = {|
  +type: string,
  clientes: Cliente[],
  contactos: Contacto[],
  empleados: Empleado[],
  cotizaciones: Cotizacion[],
  entityName: string,
  key: string,
  value: any,
  entityId: number,
  entity:
    | Cliente
    | Contacto
    | Cotizacion
    | Maquilero
    | Pedido
    | Producto
    | Proveedor
    | Sucursal
    | Tecnica,
  maquileros: Maquilero[],
  pedidos: Pedido[],
  productos: Producto[],
  proveedores: Proveedor[],
  sucursales: Sucursal[],
  tecnicas: Tecnica[],
  reportes: Totales,
|}

const apiReducer = (state: ApiState = initialState, action: ApiAction): ApiReducer => {
  switch (action.type) {
    case API_ADD_ELEMENT:
      return {
        ...state,
        [action.entityName]: [...state[action.entityName], action.entity],
      }

    case API_UPDATE_ELEMENT: {
      return {
        ...state,
        [action.entityName]: state[action.entityName].map(entity =>
          entity.id === action.entityId ? action.entity : entity
        ),
      }
    }

    case API_UPDATE_SINGLE_ELEMENT: {
      return {
        ...state,
        [action.entityName]: state[action.entityName].map(entity =>
          entity.id === +action.entityId ? { ...entity, [action.key]: action.value } : entity
        ),
      }
    }

    case REPORTE_GETALL_SUCCESS:
      return {
        ...state,
        reportes: action.reportes,
      }

    case CLIENTE_GETALL_SUCCESS:
      return {
        ...state,
        clientes: action.clientes.map(cliente => ({
          id: cliente.clienteId,
          nombre: cliente.nombreComercial,
          ...cliente,
        })),
      }

    case CONTACTO_GETALL_SUCCESS:
      return {
        ...state,
        contactos: action.contactos.map(contacto => ({
          id: contacto.contactoId,
          nombre: contacto.nombres,
          ...contacto,
        })),
      }

    case COTIZACION_GETALL_SUCCESS:
      return {
        ...state,
        cotizaciones: action.cotizaciones.map(cotizacion => ({
          id: cotizacion.id,
          ...cotizacion,
        })),
      }

    case EMPLEADO_GETALL_SUCCESS:
      return {
        ...state,
        empleados: action.empleados.map(empleado => ({
          id: empleado.contactoId,
          nombre: empleado.contactoNombre,
          ...empleado,
        })),
      }

    case PEDIDO_GETALL_SUCCESS:
      return {
        ...state,
        pedidos: action.pedidos.map(pedido => ({
          id: pedido.pedidoId,
          ...pedido,
        })),
      }

    case PRODUCTO_GETALL_SUCCESS:
      return {
        ...state,
        productos: action.productos.map(producto => ({
          id: producto.productoId,
          nombre: producto.nombre,
          ...producto,
        })),
      }

    case PROVEEDOR_GETALL_SUCCESS:
      return {
        ...state,
        proveedores: action.proveedores.map(proveedor => ({
          id: proveedor.proveedorId,
          nombre: proveedor.nombreComercial,
          ...proveedor,
        })),
      }

    case MAQUILERO_GETALL_SUCCESS:
      return {
        ...state,
        maquileros: action.maquileros.map(maquilero => ({
          id: maquilero.maquileroId,
          nombre: maquilero.nombreComercial,
          ...maquilero,
        })),
      }

    case SUCURSAL_GETALL_SUCCESS:
      return {
        ...state,
        sucursales: action.sucursales.map(sucursal => ({
          id: sucursal.sucursalId,
          nombre: sucursal.sucursalNombre,
          ...sucursal,
        })),
      }

    case TECNICA_GETALL_SUCCESS:
      return {
        ...state,
        tecnicas: action.tecnicas.map(tecnicas => ({
          id: tecnicas.tecnicaId,
          nombre: tecnicas.nombre,
          ...tecnicas,
        })),
      }

    default:
      return state
  }
}

export default (apiReducer: (state: ApiState, action: ApiAction) => ApiReducer)
