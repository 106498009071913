// @flow

import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { SuperController } from 'controllers'
import { stringUtils } from 'utils'

const useGetByHash = (entityName: string) => {
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState('')

  const entityActionName = useMemo(
    () => stringUtils.singularize(entityName).toUpperCase(),
    [entityName]
  )

  const EntityService = useMemo(
    () => new SuperController(null, stringUtils.singularize(entityName)),
    [entityName]
  )

  const getByHash = useCallback(
    (hash: number) => {
      if (hash && EntityService) {
        dispatch({ type: `${entityActionName}_GETBYHASH_REQUEST` })
        EntityService.GetByHash(hash)
          .then(entityByHash => {
            dispatch({ type: `${entityActionName}_GETBYHASH_SUCCESS` })
            dispatch({
              type: `${entityName.toUpperCase()}_SET_${entityActionName}`,
              [stringUtils.singularize(entityName)]:
                entityByHash[stringUtils.singularize(entityName)],
            })
          })
          .catch(err => {
            dispatch({ type: `${entityActionName}_GETBYHASH_ERROR` })
            setErrorMessage(err.description)
          })
      }
    },
    [EntityService, dispatch, entityActionName, entityName]
  )

  return { getByHash, errorMessage }
}

export default useGetByHash
