// @flow
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBProgress,
  MDBRow,
} from 'mdbreact/dist/mdbreact.esm'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import DashboardSkeleton from './DashboardSkeleton'
import DashboardTableCharts from './DashboardTableCharts'
import { dateUtils, useGet } from 'utils'
import './Dashboard.scss'

const Dashboard = () => {
  const totales = useGet('reportes', true)
  const cotizaciones = useMemo(
    () => dateUtils.parseBarChart(totales?.cotizaciones, 'Cotizaciones'),
    [totales]
  )
  const pedidos = useMemo(() => dateUtils.parseBarChart(totales?.pedidos, 'Pedidos'), [totales])

  const generalData = [cotizaciones, pedidos]

  const entityTiles = [
    {
      entityName: 'Clientes',
      className: 'success-color',
      icon: 'handshake',
    },
    {
      entityName: 'Contactos',
      className: 'warning-color',
      icon: 'male',
    },
    {
      entityName: 'Proveedores',
      className: 'info-color',
      icon: 'people-carry',
    },
    {
      entityName: 'Productos',
      className: 'danger-color',
      icon: 'cubes',
    },
  ]
  if (!totales.clientes) {
    return <DashboardSkeleton />
  }

  return (
    <>
      <MDBContainer fluid className="Dashboard mb-5">
        <section className="mb-4">
          <h2 className="section-heading h1 text-center my-5">Dashboard</h2>
          <MDBRow>
            {totales &&
              entityTiles.map(tile => {
                const key = tile.entityName.toLowerCase()
                return (
                  <MDBCol xl="3" md="6" className="mb-4" key={key}>
                    <Link to={`/admin/${key}`}>
                      <MDBCard cascade className="cascading-admin-card">
                        <div className="admin-up">
                          <MDBIcon icon={tile.icon} className={tile.className} />
                          <div className="data">
                            <p className="dark-grey-text">{tile.entityName}</p>
                            <h3 className="font-weight-bold dark-grey-text">{totales[key]}</h3>
                          </div>
                        </div>
                        <MDBCardBody cascade className="d-none">
                          <MDBProgress
                            value={25}
                            barClassName="red accent-2"
                            height="6px"
                            wrapperStyle={{ opacity: '.7' }}
                            className="mb-3"
                          />
                          <p className="card-text">Worse than last week (25%)</p>
                        </MDBCardBody>
                      </MDBCard>
                    </Link>
                  </MDBCol>
                )
              })}
          </MDBRow>
        </section>
      </MDBContainer>
      {generalData.map(
        entityData =>
          entityData && <DashboardTableCharts key={entityData.name} entityData={entityData} />
      )}
    </>
  )
}

export default (Dashboard: () => React$Node)
