// @flow
import { useCallback } from 'react'

const useLazyImage = (classSelector: string, useMemoizedLoad: boolean = false) => {
  const handleLazyLoadingImg = () => {
    const images = document.querySelectorAll(classSelector)

    const callbackObserver = entries => {
      entries.forEach(({ isIntersecting, target }) => {
        if (isIntersecting) {
          if (target instanceof HTMLImageElement) {
            target.src = target.title
            observer.unobserve(target)
          }
        }
      })
    }

    const observer = new IntersectionObserver(callbackObserver)
    images.forEach(image => observer.observe(image))
  }

  const memoizedHandleLazyLoadingImg = useCallback(() => {
    const images = document.querySelectorAll(classSelector)

    const callbackObserver = entries => {
      entries.forEach(({ isIntersecting, target }) => {
        if (isIntersecting) {
          if (target instanceof HTMLImageElement) {
            target.src = target.title
            observer.unobserve(target)
          }
        }
      })
    }

    const observer = new IntersectionObserver(callbackObserver)
    images.forEach(image => observer.observe(image))
  }, [classSelector])

  if (useMemoizedLoad) {
    return memoizedHandleLazyLoadingImg
  } else {
    return handleLazyLoadingImg
  }
}

/**
 * == useLazyImage Param ===
 * - className: this is the className that you want to assign to the "lazyLoading".
 *   This must start the className with ".", for example: ".classImg"[String].
 * - useMemoizedLoad: this property specifies to the hook if you want a memoized
 *   result or not, default on "false", where "false" returns a regular function, and
 *   "true" returms a memoized function[Boolean].
 */
export default (useLazyImage: (classSelector: string, useMemoizedLoad?: boolean) => () => void)
