// @flow
import MenuItem from '@material-ui/core/MenuItem'
import Skeleton from '@material-ui/core/Skeleton'
import TextField from '@material-ui/core/TextField'
import { MDBCol, MDBInput, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { UPDATE_CALCULADORA_HEADER, UPDATE_CALCULADORA_HEADER_PEDIDOS } from 'redux/constants'
import { getCotizacionProductoByBsku, getLoading, getPedidoProductoByBsku } from 'redux/selectors'
import { useGet } from 'utils'

import './Calculadora.scss'

type MaquilerosFieldsProductRowProps = {
  bsku: string,
  isCotizacion?: boolean,
}

const MaquilerosFieldsProductRow = ({
  bsku,
  isCotizacion = false,
}: MaquilerosFieldsProductRowProps) => {
  const dispatch = useDispatch()
  const producto = useSelector(
    isCotizacion ? getCotizacionProductoByBsku(bsku) : getPedidoProductoByBsku(bsku)
  )
  const isLoading = useSelector(getLoading(['MAQUILERO_GETALL']))

  const maquileros: Maquilero[] = useGet('maquileros')
  const maquilerosOptions = useMemo(
    () =>
      maquileros.map(maquilero => ({
        ...maquilero,
        id: maquilero.maquileroId,
      })),
    [maquileros]
  )

  const updateCalculadora = useCallback(
    (key, value) => {
      const maquileroFiltered = maquilerosOptions.find(maquilero => maquilero.id === value)
      dispatch({
        type: isCotizacion ? UPDATE_CALCULADORA_HEADER : UPDATE_CALCULADORA_HEADER_PEDIDOS,
        key,
        value: key === 'maquilero' ? maquileroFiltered : value,
        bsku,
      })
    },
    [bsku, dispatch, isCotizacion, maquilerosOptions]
  )

  if (isLoading) {
    return <Skeleton animation="wave" height={150} />
  }

  const maquileroHandler = e => updateCalculadora('maquilero', e.target.value)
  const tecnicaHandler = e => updateCalculadora('tecnica', e.target.value)
  const posicionDecoradoHandler = e => updateCalculadora('posicionDecorado', e.target.value)
  const numeroTintasHandler = e => updateCalculadora('numeroTintas', Number(e.target.value))

  return producto ? (
    <MDBRow className="my-2 text-center align-items-center Calculadora">
      <MDBCol xs="12" sm="3" md="3">
        {maquilerosOptions.length > 0 && (
          <TextField
            select
            className="BeritSelect"
            fullWidth={true}
            label="* Maquilero"
            variant="standard"
            value={producto.maquilero?.id}
            onChange={maquileroHandler}
          >
            {maquilerosOptions.map((val, idx) => (
              <MenuItem key={idx} value={val.maquileroId}>
                {val.nombreComercial}
              </MenuItem>
            ))}
          </TextField>
        )}
      </MDBCol>
      <MDBCol xs="12" sm="3" md="3">
        <TextField
          autoComplete="off"
          className={`BeritInput`}
          fullWidth
          label={'* Técnica de decorado'}
          multiline
          variant="standard"
          onChange={tecnicaHandler}
          value={producto.tecnica}
        />
      </MDBCol>
      <MDBCol xs="12" sm="3" md="3">
        <TextField
          autoComplete="off"
          className={`BeritInput`}
          fullWidth
          label={'Posición de decorado'}
          multiline
          variant="standard"
          onChange={posicionDecoradoHandler}
          value={producto.posicionDecorado}
        />
      </MDBCol>
      <MDBCol xs="12" sm="3" md="3">
        <MDBInput
          label="* Número de tintas"
          type="number"
          className="text-center headerInputField"
          onChange={numeroTintasHandler}
          value={producto.numeroTintas}
        />
      </MDBCol>
      <MDBCol className="d-none d-lg-block" />
    </MDBRow>
  ) : (
    <></>
  )
}

export default (MaquilerosFieldsProductRow: MaquilerosFieldsProductRowProps => React$Node)
