// @flow
import { MDBBtn } from 'mdbreact/dist/mdbreact.esm'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import AccordionFrame from './AccordionFrame'
import BeritCard from 'components/BeritCard'
import BeritCheckbox from 'components/BeritCheckbox'
import BeritInput from 'components/BeritInput'
import { useToast } from 'utils'

const SatisfaccionClienteAccordion = () => {
  const [satisfaccionClienteAcc, setSatisfaccionClienteAcc] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      satisfaccionCliente: '',
      satisfaccionComentarios: '',
    },
  })
  const toast = useToast()

  const onSubmit = satisfaccion => console.warn(satisfaccion)

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  return (
    <AccordionFrame
      accordion={satisfaccionClienteAcc}
      toggleCollapse={() => setSatisfaccionClienteAcc(!satisfaccionClienteAcc)}
      header="Satisfacción del cliente"
    >
      <BeritCard classes="mb-5">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
          <BeritCheckbox
            control={control}
            name="satisfaccionCliente"
            label="Satisfaccion del cliente"
          />

          <BeritInput
            control={control}
            errors={errors}
            name="satisfaccionComentarios"
            label="Comentarios"
            type="textarea"
          />
          <div className="d-flex justify-content-center">
            <MDBBtn type="submit" color="blue">
              Guardar
            </MDBBtn>
          </div>
        </form>
      </BeritCard>
    </AccordionFrame>
  )
}

export default SatisfaccionClienteAccordion
