// @flow
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact/dist/mdbreact.esm'

import { formatPrecio } from 'utils/cotizacionesUtils'

type VariantsVerProps = {
  variantes: Variante[],
  isCotizacion?: boolean,
  cantidadVariantes?: number,
  total?: number,
}

const VariantsVer = ({
  variantes,
  isCotizacion = false,
  cantidadVariantes,
  total,
}: VariantsVerProps) => {
  const columnExists = key =>
    variantes.map(variante => variante[key].length).reduce((acc, curr) => acc + curr)

  const color = columnExists('color')
  const talla = columnExists('talla')

  return (
    <MDBTable className="VariantsVer" striped>
      <MDBTableHead color="gradient-card-header berit-gradient" textWhite>
        <tr>
          {color > 0 ? <th>Color</th> : <th />}
          {talla > 0 ? <th>Talla</th> : <th />}
          <th>Cantidad</th>
          <th>Precio unitario</th>
          <th>Total por partida</th>
        </tr>
      </MDBTableHead>

      <MDBTableBody>
        {variantes.map((variant, idx) => {
          const cantidad = variant.cantidad
          const unitario = isCotizacion
            ? formatPrecio(variant.precioUnitario)
            : formatPrecio(variant.precioUnitarioCliente)
          const total = isCotizacion
            ? variant.cantidad * variant.precioUnitario
            : variant.cantidad * variant.precioUnitarioCliente

          return (
            <tr key={idx}>
              {color > 0 ? <td>{variant.color || 'N/A'}</td> : <td />}
              {talla > 0 ? <td>{variant.talla || 'N/A'}</td> : <td />}
              <td>{cantidad}</td>
              <td>{unitario}</td>
              <td>{formatPrecio(total)}</td>
            </tr>
          )
        })}
        {isCotizacion && total && (
          <tr>
            <td />
            <td />
            <td>{variantes.length > 1 && <span>{cantidadVariantes}</span>}</td>
            <td />
            <td>
              {variantes.length > 1 && (
                <div>
                  <strong>Subtotal</strong>
                  <span className="ml-2">{formatPrecio(total)}</span>
                </div>
              )}
              <div>
                <strong>IVA</strong>
                <span className="ml-2">{formatPrecio(total * 0.16)}</span>
              </div>
              <div>
                <strong>Total</strong>
                <span className="ml-2">{formatPrecio(total * 1.16)}</span>
              </div>
            </td>
          </tr>
        )}
      </MDBTableBody>
    </MDBTable>
  )
}

export default (VariantsVer: VariantsVerProps => React$Node)
