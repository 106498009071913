// @flow
import Link from '@material-ui/core/Link'
import { MDBFooter } from 'mdbreact/dist/mdbreact.esm'

type CopyrightsProps = {
  className: string,
  style: any,
  isPublic?: boolean,
}
const Copyrights = ({ className, style, isPublic = false }: CopyrightsProps) => {
  const date = new Date().getFullYear()
  return (
    <MDBFooter className={className} style={{ ...style, zIndex: 10 }}>
      <p className="footer-copyright mb-0 py-3 text-center">
        &copy; {date} Copyright: Berit Soluciones{' '}
        {isPublic && (
          <>
            <br />
            <Link href="https://www.beritpublicidad.com/">www.beritpublicidad.com</Link>
          </>
        )}
      </p>
    </MDBFooter>
  )
}
export default (Copyrights: CopyrightsProps => React$Node)
