// @flow
import BaseControllerImg from './_imgBaseController'

class ImagesController extends BaseControllerImg {
  constructor(auth: AuthState) {
    super()
    this.auth = auth
  }

  // async GetById(imageId: number) { Doesn't work yet 😞
  //   const response = await super.GetById.call(this, imageId)
  //   return await response
  // }

  async UpdateProductoImagenes(currentData) {
    const response = await super.UpdateProductoImagenes.call(this, currentData)
    return await response
  }

  async Publish(data) {
    const request = new Request(`${this.IMG_ENDPOINT}imagen/publish/`, {
      method: 'POST',
      headers: {
        Authorization: `Token ${this.auth.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    return await this._handleRequest(request)
  }

  //   async Delete(entityId: number): Promise<1> { same here...
  //     const response = await super.Delete.call(this, entityId)
  //     return await response
  //   }
}

export default ImagesController
