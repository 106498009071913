// @flow
import {
  CHANGE_COLOR_TALLA_VARIANTE,
  COTIZACION_ADD_PRODUCT,
  COTIZACION_CHANGE_PRODUCT_DESCRIPTION,
  COTIZACION_CLEAR_COTIZACIONES,
  COTIZACION_CLEAR_PRODUCTS,
  COTIZACION_DELETE_PRODUCT,
  COTIZACION_HEADER_INPUT_CHANGE,
  COTIZACION_HEADER_SELECT_CHANGE,
  COTIZACION_REFRESH_PRODUCT,
  COTIZACION_UPDATE_PRODUCTO_PROP,
  COTIZACIONES_ADD_VARIANTE,
  COTIZACIONES_SET_COTIZACION,
  COTIZACIONES_SET_USER_INFO,
  FECHA_CHANGE,
  SAVE_BUSQUEDA_RESPONSE,
  UPDATE_CALCULADORA,
  UPDATE_CALCULADORA_HEADER,
  UPDATE_CALCULADORA_PU_VARIANTES,
} from 'redux/constants'

const busquedaInicial = {
  hits: 0,
  isLoading: false,
  productos: [],
}

const initialState = {
  busqueda: busquedaInicial,
  cotizacion: {
    hash: '',
    id: 0,
    status: 0,
    contactoId: 0,
    correo: '',
    correoContacto: '',
    cotizacionId: 0,
    cotizacionJson: '',
    maquilero: {},
    clienteId: 0,
    fecha: new Date().toISOString(),
    fechaCreacion: '',
    ultimaModificacion: '',
    cotizacionNumero: 1,
    productos: [],
    productosJson: '',
    sucursalId: 0,
    telefono: '',
    telefonoContacto: '',
    usuarioId: 0,
    tiempoEntrega: 'De 8 a 10 días hábiles',
  },
}

type CotizacionesReducer = CotizacionesState

export type CotizacionesAction = {|
  +type: string,
  payload: any,
  bsku: string,
  imagen: string,
  imagenes: string,
  productoDescripcion: any,
  cotizacion: Cotizacion,
  descripcion: string,
  fecha: string,
  input: any,
  key: string,
  productoId: number,
  productoNombre: string,
  precio: number,
  producto: ProductoCotizacion,
  productoIndex: number,
  response: BusquedaResponse,
  selects: {|
    clienteId: number,
    sucursalId: number,
    contactoId: number,
  |},
  usuario: {
    correo: string,
    telefono: string,
  },
  value: any,
  variantId: number,
  variante: Variante,
|}

const cotizacionesReducer = (
  state: CotizacionesState = initialState,
  action: CotizacionesAction
): CotizacionesReducer => {
  switch (action.type) {
    case COTIZACION_DELETE_PRODUCT:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          productos: state.cotizacion.productos.filter(p => p.bsku !== action.bsku),
        },
      }
    case COTIZACION_CLEAR_COTIZACIONES:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          productos: [],
        },
      }
    case COTIZACIONES_SET_USER_INFO:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          telefono: action.usuario.telefono,
          correo: action.usuario.correo,
        },
      }

    case COTIZACIONES_SET_COTIZACION:
      return {
        ...state,
        cotizacion: {
          ...action.cotizacion,
        },
      }

    case SAVE_BUSQUEDA_RESPONSE:
      return {
        ...state,
        busqueda: {
          ...state.busqueda,
          productos: action.response.productos,
          hits: action.response.hits,
        },
      }

    case COTIZACION_ADD_PRODUCT:
      return {
        ...state,
        busqueda: busquedaInicial,
        cotizacion: {
          ...state.cotizacion,
          productos: Array.isArray(action.producto)
            ? [...action.producto]
            : [...state.cotizacion.productos, action.producto],
        },
      }

    case COTIZACION_UPDATE_PRODUCTO_PROP:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          productos: state.cotizacion.productos.map(producto =>
            producto.productoId === action.productoId
              ? { ...producto, [action.key]: action.payload }
              : producto
          ),
        },
      }

    case COTIZACION_REFRESH_PRODUCT:
      return {
        ...state,
        busqueda: busquedaInicial,
        cotizacion: {
          ...state.cotizacion,
          productos: state.cotizacion.productos.map(producto =>
            producto.productoId === action.productoId
              ? {
                  ...producto,
                  bsku: action.bsku,
                  productoNombre: action.productoNombre,
                  productoPrecio: action.precio,
                  imagen: action.imagen,
                  imagenes: action.imagenes,
                  productoDescripcion: action.productoDescripcion,
                }
              : producto
          ),
        },
      }

    case COTIZACION_CLEAR_PRODUCTS:
      return {
        ...state,
        busqueda: busquedaInicial,
        cotizacion: {
          ...state.cotizacion,
          productos: [],
        },
      }

    case FECHA_CHANGE:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          fecha: action.fecha,
        },
      }

    case COTIZACION_HEADER_INPUT_CHANGE: {
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          ...action.input,
        },
      }
    }

    case COTIZACION_HEADER_SELECT_CHANGE:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          ...action.selects,
        },
      }

    case COTIZACIONES_ADD_VARIANTE:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          productos: state.cotizacion.productos.map(producto =>
            producto.bsku === action.variante.bsku
              ? producto.variantes
                ? { ...producto, variantes: [...producto.variantes, action.variante] }
                : { ...producto, variantes: [action.variante] }
              : producto
          ),
        },
      }

    case UPDATE_CALCULADORA_HEADER:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          productos: state.cotizacion.productos.map(producto =>
            producto.bsku === action.bsku
              ? {
                  ...producto,
                  [action.key]: action.value,
                }
              : producto
          ),
        },
      }

    case UPDATE_CALCULADORA:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          productos: state.cotizacion.productos.map(producto =>
            producto.bsku === action.bsku
              ? {
                  ...producto,
                  variantes: producto.variantes.map(variante =>
                    variante.variantId === action.variantId
                      ? {
                          ...variante,
                          [action.key]:
                            action.key === 'tecnicaDecorado'
                              ? action.value
                              : parseFloat(action.value),
                        }
                      : variante
                  ),
                }
              : producto
          ),
        },
      }

    case CHANGE_COLOR_TALLA_VARIANTE:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          productos: state.cotizacion.productos.map(product =>
            product.bsku === action.bsku
              ? {
                  ...product,
                  variantes: product.variantes.map(variante =>
                    variante.variantId === action.variantId
                      ? { ...variante, [action.key]: action.value }
                      : variante
                  ),
                }
              : product
          ),
        },
      }

    case UPDATE_CALCULADORA_PU_VARIANTES:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          productos: state.cotizacion.productos.map(producto =>
            producto.bsku === action.bsku
              ? {
                  ...producto,
                  variantes: producto.variantes.map(variante =>
                    variante.bsku === action.bsku
                      ? {
                          ...variante,
                          precioUnitario: action.value,
                        }
                      : variante
                  ),
                }
              : producto
          ),
        },
      }

    case COTIZACION_CHANGE_PRODUCT_DESCRIPTION:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          productos: state.cotizacion.productos.map(p =>
            p.bsku === action.bsku ? { ...p, productoDescripcion: action.descripcion } : p
          ),
        },
      }

    default:
      return state
  }
}

export default (cotizacionesReducer: (
  state: CotizacionesState,
  action: CotizacionesAction
) => CotizacionesReducer)
