// @flow
import { createSelector } from 'reselect'

import { getApiState } from './stateSelectors'

export const getClientes: State => Cliente[] = createSelector(getApiState, api => api.clientes)

export const getContactos: State => Contacto[] = createSelector(getApiState, api => api.contactos)

export const getCotizaciones: State => Cotizacion[] = createSelector(
  getApiState,
  api => api.cotizaciones
)

export const getMaquileros: State => Maquilero[] = createSelector(
  getApiState,
  api => api.maquileros
)

export const getPedidos: State => Pedido[] = createSelector(getApiState, api => api.pedidos)

export const getProductos: State => Producto[] = createSelector(getApiState, api => api.productos)

export const getProveedores: State => Proveedor[] = createSelector(
  getApiState,
  api => api.proveedores
)

export const getSucursales: State => Sucursal[] = createSelector(getApiState, api => api.sucursales)

export const getTecnicas: State => Tecnica[] = createSelector(getApiState, api => api.tecnicas)
