// @flow
import axios from 'axios'
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import {
  MDBAnimation,
  MDBBtn,
  MDBContainer,
  MDBIcon,
  MDBInputGroup,
} from 'mdbreact/dist/mdbreact.esm'
import { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import LiveSearchSkeleton from './LiveSearchSkeleton'
import {
  COTIZACION_ADD_PRODUCT,
  PEDIDOS_ADD_PRODUCTO,
  SAVE_BUSQUEDA_RESPONSE,
  SAVE_BUSQUEDA_RESPONSE_PEDIDOS,
} from 'redux/constants'
import './LiveSearch.scss'
import { getCotizacionesState, getPedidosState } from 'redux/selectors'
import { conditionalUtils, configUtils, errorUtils, useGet, useToast } from 'utils'
import { formatPrecio, productoImg } from 'utils/cotizacionesUtils'

const { beritApi } = configUtils.createConfig()

const stripHtmlTags = document.createElement('div')

type LiveSearchProps = {
  type: 'cotizacion' | 'pedido',
}

const LiveSearch = ({ type = 'pedido' }: LiveSearchProps) => {
  const dispatch = useDispatch()
  const tecnicasAll: Tecnica[] = useGet('tecnicas')
  const maquilerosAll: Maquilero[] = useGet('maquileros')
  const cotizaciones = useSelector(getCotizacionesState, shallowEqual)
  const pedidos = useSelector(getPedidosState, shallowEqual)
  const toast = useToast()
  const busqueda = conditionalUtils.pathCondition(
    type,
    'pedido',
    pedidos.busqueda,
    cotizaciones.busqueda
  )
  const productos = conditionalUtils.pathCondition(
    type,
    'pedido',
    pedidos.pedido.productos,
    cotizaciones.cotizacion.productos
  )
  const saveBusqueda = conditionalUtils.pathCondition(
    type,
    'pedido',
    SAVE_BUSQUEDA_RESPONSE_PEDIDOS,
    SAVE_BUSQUEDA_RESPONSE
  )

  const sourceRef = useRef(null)
  const [query, setQuery] = useState('')
  const [status, setStatus] = useState<Flag>({
    isLoading: false,
    response: {
      text: '',
      status: '',
    },
  })

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      sourceRef.current && sourceRef.current.cancel('Búsqueda cancelada.')
      sourceRef.current = axios.CancelToken.source()
      const cancelToken = sourceRef.current.token

      if (query) {
        setStatus({
          ...status,
          isLoading: true,
        })
        axios
          .get(`${beritApi}producto/buscar/?q=${query}`, {
            cancelToken,
          })
          .then(res => {
            setStatus({
              isLoading: false,
              response: {
                text: 'Éxito',
                status: 'success',
              },
            })
            dispatch({
              type: saveBusqueda,
              response: {
                productos: res.data.productos,
                hits: res.data.hits,
              },
            })
          })
          .catch(err => {
            console.warn(err)
            if (axios.isCancel(err)) {
              console.error('Request canceled', err)
            } else if (err.response) {
              setStatus({
                isLoading: false,
                response: {
                  text: err.response.data.description,
                  status: 'error',
                },
              })
            } else {
              setStatus({
                isLoading: false,
                response: {
                  text: errorUtils.ERROR_UNEXPECTED,
                  status: 'error',
                },
              })
            }
          })
      }
    }, 400)

    return () => clearTimeout(typingTimeout)
    // eslint-disable-next-line
  }, [query])

  const onBusquedaChange = e => {
    setQuery(e.target.value)
  }

  const busquedaProductoClick = bsku => {
    const costoTecnica = tecnica => tecnicasAll.find(t => t.nombre === tecnica)?.precio ?? 0.1

    const producto = busqueda.productos.find(producto => producto.bsku === bsku)
    const tecnicas = producto?.tecnicas ? producto.tecnicas.split(', ') : []
    const tecnicaDecorado =
      tecnicas &&
      tecnicas.reduce(
        (acc, tecnica, idx) =>
          (idx !== 0 ? `${acc},\r\n` : '') + `${tecnica}: ${formatPrecio(costoTecnica(tecnica))}`,
        ''
      )
    const tecnica = tecnicas && tecnicas[0]
    const costoProveedor = producto ? producto.productoPrecio : 0

    const precioSugerido = (costoProveedor + costoTecnica(tecnica)) * 1.4
    const exists = productos.find(product => product.bsku === bsku)
    if (exists) {
      toast('ERROR: El producto ya esta listado', 'error')
    } else {
      const textDescription = `<b>${producto.productoNombre}</b><br>${producto.productoDescripcion}`
      const htmlDescription = textDescription
      const blocksFromHTML = convertFromHTML(htmlDescription)
      const description = ContentState.createFromBlockArray(blocksFromHTML)
      const defaultCant = 5
      const initialVariant = {
        cantidad: defaultCant,
        precioUnitario: producto.productoPrecio,
        totalPartida: producto.productoPrecio * defaultCant,
        proveedorCosto: costoProveedor,
        maquileroCosto: 0.01,
        color: '',
        bsku,
        talla: '',
        variantId: new Date().getTime(),
      }
      if (type === 'pedido') {
        dispatch({
          type: PEDIDOS_ADD_PRODUCTO,
          vendedor: 'default',
          producto: {
            ...producto,
            productoDescripcion: convertToRaw(description),
            maquilero: maquilerosAll[0],
            tecnica: '',
            posicionDecorado: '',
            numeroTintas: 1,
            variantes: [initialVariant],
          },
        })
      } else {
        dispatch({
          type: COTIZACION_ADD_PRODUCT,
          producto: {
            ...producto,
            productoDescripcion: convertToRaw(description),
            costoProveedor: costoProveedor,
            costoTecnica: costoTecnica(tecnica),
            porcentaje: 40,
            precioSugerido: precioSugerido,
            tecnicaDecorado: tecnicaDecorado,
            maquilero: maquilerosAll[0],
            tecnica: '',
            posicionDecorado: '',
            numeroTintas: 1,
            variantes: [initialVariant],
          },
        })
      }
      setQuery('')
    }
  }

  return (
    <MDBContainer fluid className="LiveSearch section mb-5 border p-3">
      <MDBInputGroup
        material
        hint="Buscar producto por BSKU, nombre, descripción, colores, técnica..."
        onChange={e => onBusquedaChange(e)}
        value={query}
        prepend={
          <MDBBtn gradient="blue" className="m-0">
            <MDBIcon icon="search" />
          </MDBBtn>
        }
      />
      {query &&
        (status.isLoading ? (
          <LiveSearchSkeleton />
        ) : (
          <MDBAnimation type="fadeIn">
            <small className="form-text text-muted mb-4 text-align-right text-right">
              {busqueda.hits} resultado{busqueda.hits === 1 ? '' : 's'}
            </small>
            <section
              style={{ maxHeight: '70vh', overflow: 'auto' }}
              className="z-depth-4 scrollbar mb-1"
            >
              {busqueda.productos.map((el, idx) => {
                stripHtmlTags.innerHTML = el.productoDescripcion

                return (
                  <li
                    className="list-group-item-action list-group-item media d-flex"
                    key={idx}
                    style={{ cursor: 'pointer' }}
                    onClick={() => busquedaProductoClick(el.bsku)}
                  >
                    <div className="media-left align-self-center">
                      <img src={productoImg(el.imagen)} title={el.proveedorNombre} alt={el.bsku} />
                    </div>
                    <div className="media-body">
                      <h5 className="font-weight-bold mb-3 mt-2">
                        <span title={el.proveedorNombre}>{el.bsku}</span> - {el.productoNombre} -{' '}
                        <small className="text-muted">{formatPrecio(el.productoPrecio)}</small>
                      </h5>
                      <p className="mb-1">{stripHtmlTags.textContent}</p>
                    </div>
                  </li>
                )
              })}
            </section>
          </MDBAnimation>
        ))}
    </MDBContainer>
  )
}

export default (LiveSearch: LiveSearchProps => React$Node)
