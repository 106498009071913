// @flow
import sanitizeHtml from 'sanitize-html'

import VariantsVer from './VariantsVer'
import PedidoProductosSeguimientoSkeleton from 'components/PedidoProductosSeguimientoSkeleton'
import { formatPrecio, productoImg } from 'utils/cotizacionesUtils'

type ProductosVerProps = {
  productos: ProductoPedido[],
  productosApi: Producto[],
}

const ProductosVer = ({ productos, productosApi }: ProductosVerProps) => {
  if (!productos) {
    return <h3>No hay pedido</h3>
  }

  const productoDataFilteredFromApi = productosApi.filter(apiProducto =>
    productos?.find(producto => apiProducto.productoId === producto.productoId)
  )

  return productos.map((producto, idx) => {
    const stringTohtml = producto.pedidoDescripcion
    const cleanedHtml = sanitizeHtml(stringTohtml, {
      allowedAttributes: {
        span: ['style'],
        div: ['style'],
        p: ['style'],
        strong: ['style'],
        h1: ['style'],
        h2: ['style'],
        h3: ['style'],
        h4: ['style'],
        h5: ['style'],
        h6: ['style'],
      },
    })

    const createMarkup = () => {
      return { __html: cleanedHtml }
    }

    const productoDataLackFiltered = productoDataFilteredFromApi.find(
      productoData => productoData.productoId === producto.productoId
    )

    const getTotals = (key, quantities = false) => {
      const totalByItem: any = producto.variantes
        .map(variante => (quantities ? variante.cantidad : variante.cantidad * variante[key]))
        .reduce((acc, curr) => acc + curr)

      const total =
        totalByItem.length > 0
          ? Number(totalByItem.reduce((acc, curr) => acc + curr)).toFixed(2)
          : totalByItem

      return Number(total)
    }
    const importeFinal = getTotals('precioUnitarioCliente')
    const cantidadFinal = getTotals('cantidad', true)

    return (
      <div className="CotizacionVer" key={idx}>
        {productoDataLackFiltered ? (
          <>
            <section className="item">
              <div className="item-image" size="12" md="2">
                <img
                  src={productoImg(`4/${productoDataLackFiltered.imagen}`)}
                  className="img-fluid rounde d-circle z-depth-2"
                  title={`${productoDataLackFiltered.bsku}: ${productoDataLackFiltered.nombre}`}
                  alt={`${productoDataLackFiltered.bsku}: ${productoDataLackFiltered.nombre}`}
                />
              </div>
              <div className="item-text" size="12" md="5">
                <h5 className="dark-grey-text">
                  <strong>{productoDataLackFiltered.bsku}</strong>
                </h5>
                <div dangerouslySetInnerHTML={createMarkup()} />
              </div>
              <div className="item-quantities" size="12" md="5">
                <div size="12" md="auto">
                  <span className="text-muted">Técnica:</span>
                  <span>{producto.tecnicaDecorado || 'N/A'}</span>
                </div>
                <div size="12" md="auto">
                  <span className="text-muted">Posición de decorado:</span>
                  <span>{producto.posicionDecorado || 'N/A'}</span>
                </div>
                <div size="12" md="auto">
                  <span>Cantidad:</span>
                  <span>{cantidadFinal}</span>
                </div>
                <div size="12" md="4">
                  <span>Total:</span>
                  <span>
                    <strong>{formatPrecio(importeFinal * 1.16)}</strong>
                  </span>
                </div>
              </div>
            </section>
            <hr />
            <VariantsVer variantes={producto.variantes} />
          </>
        ) : (
          <PedidoProductosSeguimientoSkeleton />
        )}
      </div>
    )
  })
}

export default ProductosVer
