// @flow
import { MODAL_IMG_SET, MODAL_IMG_TOGGLE } from 'redux/constants'

const initialState = {
  isOpen: false,
  data: {
    src: '',
  },
}

type ModalImagesTypes = ModalImagesState

export type ModalImageAction = {
  +type: string,
  payload: {
    src: string,
  },
}

const modalImagesReducer = (state: ModalImagesTypes = initialState, action: ModalImageAction) => {
  switch (action.type) {
    case MODAL_IMG_SET:
      return {
        ...state,
        data: action.payload,
      }
    case MODAL_IMG_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    default:
      return state
  }
}

export default (modalImagesReducer: (
  state: ModalImagesState,
  action: ModalImageAction
) => ModalImagesTypes)
