// @flow
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTable,
} from 'mdbreact/dist/mdbreact.esm'
import { Bar } from 'react-chartjs-2'
import { Link } from 'react-router-dom'

import { stringUtils } from 'utils'

type DataSets = {
  label: string,
  data: number[],
  borderColor: string[],
  backgroundColor: string[],
  borderWidth: number,
}

type DashboardTableChartsProps = {
  entityData: {
    chart: {
      labels: string[],
      datasets: DataSets[],
    },
    table: any[],
    name: string,
  },
}

const DashboardTableCharts = ({ entityData }: DashboardTableChartsProps) => {
  const isProductionAndPedidos =
    entityData.name === 'pedidos' && process.env.NODE_ENV === 'production'
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xdataset: [
        {
          barPercentage: 1,
          gridLines: {
            display: true,
            color: 'rgba(0, 0, 0, 0.1)',
          },
        },
      ],
      ydataset: [
        {
          gridLines: {
            display: true,
            color: 'rgba(0, 0, 0, 0.1)',
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  return (
    <>
      {!isProductionAndPedidos && (
        <MDBRow center>
          <MDBCol md="6">
            <MDBContainer>
              <MDBCard narrow>
                <Link to={`/admin/${entityData.name}`}>
                  <MDBCardImage
                    className={`view view-cascade gradient-card-header ${
                      entityData.name === 'pedidos' ? 'peach-gradient' : 'blue-gradient'
                    }`}
                    cascade
                    tag="div"
                  >
                    <h2 className="h2-responsive mb-2 clickable">
                      {stringUtils.ucfirst(entityData.name)}
                    </h2>
                  </MDBCardImage>
                </Link>
                <MDBCardBody>
                  {entityData?.table && (
                    <MDBTable>
                      <thead>
                        <tr>
                          <th className="font-weight-bold dark-grey-text">
                            <strong>Mes</strong>
                          </th>
                          <th className="font-weight-bold dark-grey-text">
                            <strong>No. Cotizaciones</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {entityData.table.length > 0
                          ? entityData.table.map((entity, index) => {
                              return (
                                <tr key={index}>
                                  <td>{entity.month}</td>
                                  <td>{entity.entity}</td>
                                </tr>
                              )
                            })
                          : entityData.table}
                      </tbody>
                    </MDBTable>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBContainer>
          </MDBCol>
          <MDBCol md="6">
            <MDBContainer>
              <MDBCard narrow style={{ height: '517px', cursor: 'pointer' }} className="mb-4">
                <MDBCardBody>
                  {entityData?.chart && <Bar data={entityData.chart} options={chartOptions} />}
                </MDBCardBody>
              </MDBCard>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      )}
    </>
  )
}

export default (DashboardTableCharts: DashboardTableChartsProps => React$Node)
