// @flow
import {
  MDBCard,
  MDBCardBody,
  MDBCollapse,
  MDBCollapseHeader,
  MDBIcon,
} from 'mdbreact/dist/mdbreact.esm'

type AccordionFrameProps = {
  children: React$Node,
  accordion: boolean,
  toggleCollapse: () => void,
  header: string,
  card?: boolean,
  className?: string,
}

const AccordionFrame = ({
  children,
  accordion = false,
  toggleCollapse,
  header,
  card = true,
  className = '',
}: AccordionFrameProps) => {
  return (
    <MDBCard narrow className="mt-3">
      <MDBCollapseHeader tagClassName="d-flex justify-content-between" onClick={toggleCollapse}>
        {header}
        <MDBIcon icon={accordion ? 'angle-up' : 'angle-down'} />
      </MDBCollapseHeader>
      <MDBCollapse isOpen={accordion} className={className}>
        {card ? <MDBCardBody>{children}</MDBCardBody> : children}
      </MDBCollapse>
    </MDBCard>
  )
}

export default (AccordionFrame: AccordionFrameProps => React$Node)
