// @flow
import { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { SuperController } from 'controllers'
import { createOk } from 'redux/actions/apiActions'
import { dispatchError } from 'redux/actions/errorsActions'
import { entity } from 'redux/constants'
import { getAuthState } from 'redux/selectors'
import { stringUtils, useGet, useGetById, useToast } from 'utils'

const useAdd = entityName => {
  const dispatch = useDispatch()
  const history = useHistory()
  const toast = useToast()
  const auth = useSelector(getAuthState, shallowEqual)
  const entityById = useGetById(entityName)
  const entities = useGet(entityName)

  const EntityService = useMemo(
    () => new SuperController(auth, stringUtils.singularize(entityName)),
    [entityName, auth]
  )

  const entityActionName = useMemo(
    () => stringUtils.singularize(entityName).toUpperCase(),
    [entityName]
  )

  const updateSuccess = useCallback(
    (message, redirect = true) => {
      toast(message)
      redirect &&
        setTimeout(() => {
          history.push(`/admin/${entityName.toLowerCase()}`)
        }, 2000)
    },
    [history, toast, entityName]
  )

  const entityAdd = useCallback(
    entityForm => {
      if (entityForm) {
        dispatch({ type: `${entityActionName}_SUBMIT_REQUEST` })
        const response = EntityService.Add(entityForm)
          .then(entityId => {
            entityById(entityId)
              .then(entityById => {
                dispatch({
                  type: `${entityActionName}_SUBMIT_SUCCESS`,
                })
                const isCotizacion = entityName === entity.cotizacion
                const entityEdited = {
                  ...entityById,
                  nombre: entityById.nombreComercial || entityById.nombre,
                  id: entityById[`${stringUtils.singularize(entityName)}Id`],
                }

                entities && dispatch(createOk(entityName, isCotizacion ? entityById : entityEdited))

                isCotizacion
                  ? history.push(`/admin/cotizaciones/ver/${entityId}`)
                  : updateSuccess(
                      `El ${stringUtils.singularize(entityName)} "${
                        entityById.nombreComercial || entityById.nombre
                      }" fue creado con éxito.`
                    )
              })
              .catch(err => {
                dispatch(dispatchError(`${entityActionName}_SUBMIT_ERROR`, err))
                toast(`ERROR: ${err.description}`, 'error')
              })
            if (entityName === entity.producto) {
              return entityId
            }
          })
          .catch(err => {
            dispatch(dispatchError(`${entityActionName}_SUBMIT_ERROR`, err))
            toast(`ERROR: ${err.description}`, 'error')
          })

        return response
      }
    },
    [
      EntityService,
      dispatch,
      entityActionName,
      toast,
      entityById,
      entityName,
      updateSuccess,
      history,
      entities,
    ]
  )
  return entityAdd
}

export default useAdd
