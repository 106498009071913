// @flow
import Tooltip from '@material-ui/core/Tooltip'
import { MDBBadge } from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import StandardEntityTableCellComponent from '../../components/StandardEntityTableCellComponent'
import CotizacionTableCellComponent from 'components/CotizacionTableCellComponent'
import ImageThumbnailTableCell from 'components/ImageThumbnailTableCell'
import { entity } from 'redux/constants'
import { getErrorMessage, getLoading } from 'redux/selectors'
import { useGet } from 'utils'
import { formatPrecio } from 'utils/cotizacionesUtils'
import { singularize, ucfirst } from 'utils/stringUtils'

const useDataTables = entityName => {
  const singularEntity = singularize(entityName)
  const customEntity = `${singularEntity.toUpperCase()}_GETALL`
  const entityData = useGet(entityName)
  const isFetching = useSelector(getLoading([customEntity]))
  const errorMessage = useSelector(getErrorMessage([customEntity]))

  const dataEntity = useMemo(() => {
    const labels = {
      acciones: {
        name: '',
        width: '40px',
      },
      precio: {
        name: 'Precio',
        width: '100px',
      },
      asignacion: {
        name: 'Asignación',
        width: '150px',
      },
      imagen: {
        name: 'Imagen',
        width: '120px',
      },
      clienteId: {
        name: 'ID',
        width: '80px',
      },
      cliente: {
        name: 'Cliente',
        width: '120px',
      },
      contactoId: {
        name: 'ID',
        width: '50px',
      },
      contactos: {
        name: 'Contactos',
        width: '150px',
      },
      descripcion: {
        name: 'Descripción',
        width: '150px',
      },
      direccionEntrega: {
        name: 'Dirección de entrega',
        width: '150px',
      },
      entregaRecibe: {
        name: 'Recibe',
        width: '150px',
      },
      entregaComentarios: {
        name: 'Comentarios de entrega',
        width: '150px',
      },
      entregaDireccion: {
        name: 'Dirección de entrega',
        width: '150px',
      },
      fecha: {
        name: 'Fecha',
        width: '120px',
      },
      fechaCreacion: {
        name: 'Fecha de creación',
        width: '150px',
      },
      fechaPedido: {
        name: 'Fecha de pedido',
        width: '80px',
      },
      fechaEntregaMaquila: {
        name: 'Fecha de entrega a maquila',
        width: '430px',
      },
      fechaRecoleccionProveedor: {
        name: 'Fecha de recolleción del proveedor',
        width: '150px',
      },
      fechaEntregaClienteIni: {
        name: 'Fecha de entrega inicial',
        width: '150px',
      },
      fechaEntregaClienteFin: {
        name: 'Fecha de entrega final',
        width: '150px',
      },
      telefono: {
        name: 'Teléfono',
        width: '150px',
      },
      nombreComercial: {
        name: 'Nombre comercial',
        width: '150px',
      },
      maquileroId: {
        name: 'ID',
        width: '40px',
      },
      ordenCompra: {
        name: 'Orden de compra',
        width: '90px',
      },
      pedidoId: {
        name: 'ID',
        width: '40px',
      },
      productoId: {
        name: 'ID',
        width: '80px',
      },
      productos: {
        name: 'Productos',
        width: '300px',
      },
      productosJson: {
        name: 'Productos',
        width: '150px',
      },
      proveedorId: {
        name: 'ID',
        width: '40px',
      },
      proveedor: {
        name: 'Proveedor',
        width: '150px',
      },
      status: {
        name: 'Estado',
        width: '90px',
      },
      ultimaModificacion: {
        name: 'Ultima modificación',
        width: '160px',
      },
      usuario: {
        name: 'Creado por',
        width: '150px',
      },
      sucursal: {
        name: 'Sucursal',
        width: '120px',
      },
      bsku: {
        name: 'BSKU',
        width: '100px',
      },
      prefijo: {
        name: 'Prefijo',
        width: '30px',
      },
      sku: {
        name: 'SKU',
        width: '100px',
      },
      vendedor: {
        name: entityName === 'cotizaciones' ? 'Vendedor asignado' : 'Vendedor',
        width: '160px',
      },
      tiempoEntrega: {
        name: 'Tiempo de entrega',
        width: '150px',
      },
      cotizacionNumero: {
        name: '#',
        width: '80px',
      },
      giro: {
        name: 'Giro',
        width: '160px',
      },
    }
    const getDataDates = (value, key = '', format = false) => {
      if (!key) {
        return moment(value).format()
      }
      if (key === 'fechaCreacion' || key === 'ultimaModificacion') {
        const formatDate = moment(value).format()
        const entityDate = moment(value)
        const now = moment()

        const differenceDates =
          now.diff(entityDate, 'days') > 29 ? (
            <Tooltip title={moment(value).format('DD/MMM/YYYY hh:mma').replace('.', '')} arrow>
              <span>{moment(value).format('DD/MMM/YYYY').replace('.', '')}</span>
            </Tooltip>
          ) : (
            <Tooltip title={moment(value).format('DD/MMM/YYYY hh:mma').replace('.', '')} arrow>
              <span>{moment(value).fromNow()}</span>
            </Tooltip>
          )
        if (format) {
          return formatDate
        } else {
          return differenceDates
        }
      } else {
        return value
      }
    }

    const getColums = () => {
      const initialization = Object.keys(entityData[0] ?? {})
      let cotizacionInitialization =
        entityData[0]?.cotizacionJson && JSON.parse(entityData[0]?.cotizacionJson)

      cotizacionInitialization = Object.keys(cotizacionInitialization ?? {})

      const removeFechas = key => key !== 'fechaCreacion' && key !== 'ultimaModificacion'

      const getName = key => labels[key]?.name ?? ucfirst(key)

      const getWidth = key => labels[key]?.width ?? '101px'

      const fechas =
        entityName === entity.cotizacion
          ? [
              {
                name: getName('fechaCreacion'),
                selector: row => getDataDates(row.fechaCreacion, 'fechaCreacion', true),
                cell: row => getDataDates(row.fechaCreacion, 'fechaCreacion'),
                sortable: true,
                width: getWidth('fechaCreacion'),
              },
              {
                name: getName('ultimaModificacion'),
                selector: row => getDataDates(row.ultimaModificacion, 'ultimaModificacion', true),
                cell: row => getDataDates(row.ultimaModificacion, 'ultimaModificacion'),
                id: 'ultimaModificacion',
                sortable: true,
                width: getWidth('ultimaModificacion'),
              },
            ]
          : initialization
              .filter(key => key === 'fechaCreacion' || key === 'ultimaModificacion')
              .map(key => {
                return {
                  cell: row => getDataDates(row[key], key),
                  selector: row => getDataDates(row[key], key, true),
                  name: getName(key),
                  sortable: true,
                  width: getWidth(key),
                }
              })

      switch (entityName) {
        case entity.cotizacion: {
          const cotizaciones = cotizacionInitialization
            .filter(
              key =>
                removeFechas(key) &&
                key !== 'productosJson' &&
                key !== 'hash' &&
                key !== 'tiempoEntrega' &&
                key !== 'cotizacionNumero' &&
                key !== 'sucursal' &&
                key !== 'fecha' &&
                key !== 'contactos' &&
                key !== 'productos' &&
                key !== 'cliente' &&
                key !== 'vendedor' &&
                key !== 'cotizacion'
            )
            .map(key => {
              if (key === 'productos') {
                return {
                  cell: row => (
                    <div style={{ width: getWidth(key) }} className="elipsisBreakLine">
                      {row[key]}
                    </div>
                  ),
                  selector: row => row[key],
                  name: getName(key),
                  sortable: true,
                  width: getWidth(key),
                  wrap: true,
                }
              } else {
                return {
                  cell: row => {
                    return (
                      <div style={{ width: getWidth(key) }} className="elipsisBreakLine">
                        {key === 'fecha'
                          ? moment(row[key], 'DD/MMM/YYYY hh:mma')
                              .format('DD/MMM/YYYY')
                              .replace('.', '')
                          : row[key]}
                      </div>
                    )
                  },
                  selector: row => row[key],
                  name: getName(key),
                  sortable: true,
                  width: getWidth(key),
                  wrap: true,
                }
              }
            })

          const cotizacionesColumns = [
            {
              name: getName('acciones'),
              width: getWidth('acciones'),
              allowOverflow: true,
              cell: (row, index, column, id) => <CotizacionTableCellComponent row={row} />,
            },
            {
              name: getName('cotizacionNumero'),
              width: getWidth('cotizacionNumero'),
              selector: row => row.cotizacionNumero,
              cell: (row, index, column, id) => row.cotizacionNumero,
              sortable: true,
            },
            {
              name: getName('status'),
              width: getWidth('status'),
              selector: row => row.status,
              cell: (row, index, column, id) => (
                <MDBBadge color={row.status === 'Publicado' ? 'success' : 'grey'}>
                  {row.status}
                </MDBBadge>
              ),
              sortable: true,
            },
            {
              name: getName('fecha'),
              width: getWidth('fecha'),
              selector: row => row.fecha,
              cell: (row, index, column, id) =>
                moment(row.fecha, 'DD/MMM/YYYY hh:mma').format('DD/MMM/YYYY').replace('.', ''),
              sortable: true,
            },
            {
              name: getName('cliente'),
              width: getWidth('cliente'),
              selector: row => row.cliente,
              cell: (row, index, column, id) => row.cliente,
              sortable: true,
            },
            {
              name: getName('sucursal'),
              width: getWidth('sucursal'),
              selector: row => row.sucursal,
              cell: (row, index, column, id) => row.sucursal,
              sortable: true,
            },
            {
              name: getName('contactos'),
              width: getWidth('contactos'),
              selector: row => row.contactos,
              cell: (row, index, column, id) => row.contactos,
              sortable: true,
            },
            {
              name: getName('productos'),
              width: getWidth('productos'),
              selector: row => row.productos,
              cell: (row, index, column, id) => row.productos,
              sortable: true,
            },
            {
              name: getName('vendedor'),
              width: getWidth('vendedor'),
              selector: row => row.vendedor,
              cell: (row, index, column, id) => row.vendedor,
              sortable: true,
            },
            ...cotizaciones,
            ...fechas,
          ]
          return cotizacionesColumns
        }
        case entity.pedido: {
          const pedidos = initialization
            .filter(
              key =>
                key !== 'direccionEntregaEstado' &&
                key !== 'direccionEntregaNombre' &&
                removeFechas(key)
            )
            .map(key => ({
              name: getName(key),
              selector: row => row[key],
              cell: row => (
                <div style={{ width: getWidth(key) }} className="elipsisBreakLine">
                  {row[key]}
                </div>
              ),
              sortable: true,
            }))
            .filter(pedido => pedido.name !== 'Id')

          const pedidosRes = [
            {
              name: getName('acciones'),
              width: getWidth('acciones'),
              allowOverflow: true,
              cell: row => (
                <StandardEntityTableCellComponent
                  entity="pedidos"
                  id={row.id}
                  customPath="revisar"
                />
              ),
            },
            ...pedidos,
            ...fechas,
          ]
          return pedidosRes
        }
        case entity.cliente: {
          const clientes = initialization
            .filter(
              key =>
                key !== 'nombre' &&
                key !== 'nombreComercial' &&
                key !== 'clienteId' &&
                key !== 'giro' &&
                key !== 'sucursal' &&
                removeFechas(key)
            )
            .map(key => ({
              name: getName(key),
              selector: row => row[key],
              cell: row => (
                <div style={{ width: getWidth(key) }} className="elipsisBreakLine">
                  {row[key]}
                </div>
              ),
              sortable: true,
            }))
            .filter(cliente => cliente.name !== 'Id')

          const clientesRes = [
            {
              name: getName('acciones'),
              width: getWidth('acciones'),
              allowOverflow: true,
              cell: row => <StandardEntityTableCellComponent entity="clientes" id={row.id} />,
            },
            {
              name: getName('clienteId'),
              width: getWidth('clienteId'),
              allowOverflow: true,
              cell: row => row.clienteId,
            },
            {
              name: getName('nombreComercial'),
              width: getWidth('nombreComercial'),
              allowOverflow: true,
              cell: row => row.nombreComercial,
            },
            {
              name: getName('giro'),
              width: getWidth('giro'),
              allowOverflow: true,
              cell: row => row.giro,
            },
            {
              name: getName('sucursal'),
              width: getWidth('sucursal'),
              allowOverflow: true,
              cell: row => <div className="elipsisBreakLine">{row.sucursal}</div>,
            },
            ...clientes,
            ...fechas,
          ]
          return clientesRes
        }
        case entity.contacto: {
          const contactos = initialization
            .filter(
              key =>
                key !== 'nombre' &&
                key !== 'sexo' &&
                !key.includes('clienteId') &&
                !key.includes('hash') &&
                !key.includes('rolId') &&
                key
            )
            .map(key => ({
              name: getName(key),
              selector: row => getDataDates(row[key], key, true),
              cell: row => (
                <div style={{ width: getWidth(key) }} className="elipsisBreakLine">
                  {getDataDates(row[key], key)}
                </div>
              ),
              sortable: true,
            }))
            .filter(contacto => contacto.name !== 'Id')
          return [
            {
              name: getName('acciones'),
              width: getWidth('acciones'),
              allowOverflow: true,
              cell: row => <StandardEntityTableCellComponent entity="contactos" id={row.id} />,
            },
            ...contactos,
          ]
        }
        case entity.proveedor: {
          const proveedores = initialization
            .filter(key => key !== 'nombre' && removeFechas(key))
            .map(key => {
              return {
                name: getName(key),
                selector: row => row[key],
                cell: row => (
                  <div style={{ width: getWidth(key) }} className="elipsisBreakLine">
                    {row[key]}
                  </div>
                ),
                sortable: true,
                defaultSortAsc: true,
              }
            })
            .filter(proveedor => proveedor.name !== 'Id')

          const proveedoresRes = [
            {
              name: getName('acciones'),
              width: getWidth('acciones'),
              allowOverflow: true,
              cell: row => <StandardEntityTableCellComponent entity="proveedores" id={row.id} />,
            },
            ...proveedores,
            ...fechas,
          ]
          return proveedoresRes
        }
        case entity.maquilero: {
          const maquilero = initialization
            .filter(key => key !== 'nombre' && removeFechas(key))
            .map(key => ({
              name: getName(key),
              selector: row => row[key],
              cell: row => (
                <div style={{ width: getWidth(key) }} className="elipsisBreakLine">
                  {row[key]}
                </div>
              ),
              sortable: true,
            }))
            .filter(maquilero => maquilero.name !== 'Id')

          const maquilerosRes = [
            {
              name: getName('acciones'),
              width: getWidth('acciones'),
              allowOverflow: true,
              cell: row => <StandardEntityTableCellComponent entity="maquileros" id={row.id} />,
            },
            ...maquilero,
            ...fechas,
          ]
          return maquilerosRes
        }
        case entity.producto: {
          const producto = initialization
            .filter(key => {
              return (
                key !== 'href' &&
                key !== 'descripcionClean' &&
                key !== 'descripcion' &&
                key !== 'categoriaId' &&
                key !== 'proveedorId' &&
                key !== 'productoId' &&
                key !== 'proveedor' &&
                key !== 'fechaCreacion' &&
                key !== 'ultimaModificacion' &&
                key !== 'imagenes'
              )
            })
            .map(key => ({
              name: getName(key === 'imagenes' ? 'imagen' : key),
              selector: row =>
                getDataDates(
                  row[key === 'imagenes' ? 'imagen' : key],
                  key === 'imagenes' ? 'imagen' : key,
                  true
                ),
              cell: row =>
                getDataDates(
                  key === 'precio'
                    ? formatPrecio(row[key])
                    : row[key === 'imagenes' ? 'imagen' : key],
                  key === 'imagenes' ? 'imagen' : key
                ),
              sortable: true,
            }))
            .filter(producto => producto.name !== 'Id')
          return [
            {
              name: getName('acciones'),
              width: getWidth('acciones'),
              allowOverflow: true,
              cell: row => <StandardEntityTableCellComponent entity="productos" id={row.id} />,
            },
            {
              name: getName('productoId'),
              width: getWidth('productoId'),
              selector: row => row.productoId,
              allowOverflow: true,
              sortable: true,
              cell: row => row.productoId,
            },
            {
              name: getName('imagen'),
              width: getWidth('imagen'),
              allowOverflow: true,
              cell: (row, index, column, id) => <ImageThumbnailTableCell row={row} />,
            },
            ...producto,
            {
              name: getName('proveedor'),
              width: getWidth('proveedor'),
              allowOverflow: true,
              cell: (row, index, column, id) => row.proveedor?.nombre,
            },
            ...fechas,
          ]
        }
        default:
          break
      }
    }

    const getHtmlTags = (productos: Producto[]) => {
      return productos.map(producto => {
        return {
          ...producto,
          imagen: producto.imagenes.length > 0 ? producto.imagenes[0].imagen : 'Sin imagenes',
        }
      })
    }

    const getProductFit = (dataColumn: any) =>
      dataColumn.map(column =>
        column.field === 'descripcion'
          ? {
              ...column,
              attributes: {
                className: 'sorting descripcion-column',
              },
            }
          : column
      )

    const dataParser = () => {
      const parsing = {
        columns: getColums(),
        rows: entityData.map(row => {
          if (entityName === entity.cotizacion) {
            const cotizacionData = JSON.parse(row.cotizacionJson)

            return {
              ...row,
              status: row.status === 1 ? 'Publicado' : 'Borrador',
              cotizacionNumero: row.cotizacionNumero || 'N/A',
              cliente: cotizacionData.cliente.nombre ?? '',
              sucursal: cotizacionData.sucursal.nombre ?? '',
              contactos: cotizacionData.contactos.reduce((acc, curr) => {
                return `${acc}${acc ? ' • ' : ''}${curr.nombres} ${curr.apellidos}`
              }, ''),
              usuario: cotizacionData.usuario.nombres ?? '',
              vendedor: cotizacionData.vendedor.nombres ?? '',
              fecha: moment(row.fecha).format('DD/MMM/YYYY hh:mma').replace('.', ''),
              productos:
                cotizacionData.productos.reduce((acc, curr) => {
                  return curr
                    ? `${acc}${acc ? ' • ' : ''}${curr.bsku} - ${curr.productoNombre}`
                    : 'Cargando...'
                }, '') ?? '',
              fechaCreacion: getDataDates(row.fechaCreacion),
              ultimaModificacion: getDataDates(row.ultimaModificacion),
            }
          } else {
            return {
              ...row,
              cliente: row.cliente?.nombre ?? '',
              contactos:
                row.contactos?.reduce((acc, curr) => {
                  return curr
                    ? `${acc}${acc ? ' • ' : ''}${curr.nombres} ${curr.apellidos}`
                    : 'Cargando...'
                }, '') || 'No hay contactos asignados.',
              entregaDireccion:
                `${row.direccionEntregaNombre}, ${row.direccionEntregaEstado}` ?? 'Cargando...',
              productos:
                row.productos?.reduce((acc, curr) => {
                  return curr
                    ? `${acc}${acc ? ' • ' : ''}${curr.bsku} - ${curr.productoNombre}`
                    : 'Cargando...'
                }, '') ?? '',
              sucursal:
                entityName !== entity.cotizacion
                  ? row.sucursal?.reduce(
                      (acc, curr) => `${acc}${acc ? ' • ' : ''}${curr.nombre}`,
                      ''
                    ) ?? ''
                  : '',
              rfc: Array.isArray(row.rfc)
                ? row.rfc.reduce((acc, curr) => `${acc}${acc ? ' • ' : ''}${curr.rfc}`, '')
                : row.rfc,
              fechaCreacion: getDataDates(row.fechaCreacion),
              ultimaModificacion: getDataDates(row.ultimaModificacion),
              vendedor: row.vendedor?.nombre ?? '',
            }
          }
        }),
      }

      if (entityName === entity.producto) {
        return {
          columns: getProductFit(parsing.columns),
          rows: getHtmlTags(parsing.rows),
        }
      } else {
        return parsing
      }
    }

    const entityParsed = dataParser()

    return { columns: entityParsed.columns, rows: entityParsed.rows }
  }, [entityName, entityData])

  return { dataEntity, isFetching, errorMessage }
}
export default useDataTables
