// @flow
import { ToastContainer } from 'mdbreact/dist/mdbreact.esm'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { useLocation } from 'react-router-dom'

import Copyrights from 'components/Footer'
import ImageModal from 'components/ImageModal'
import ShareModal from 'components/ShareModal'
import SideNavigation from 'components/SideNavigation'
import TopNavigation from 'components/TopNavigation'
import AdminProtectedRoutes from 'routes'

import '../App.scss'

const Admin = () => {
  const location = useLocation()
  const isAuthenticated = useSelector<State, boolean>(state => state.auth.isAuthenticated)
  const [windowWidth, setWindowWidth] = useState(0)
  const [currentPage, setCurrentPage] = useState('')
  const [sideNavToggled, setSideNavToggled] = useState(false)
  const breakWidth = 1400

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    assessLocation(checkForEdition(location.pathname))

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [location])

  const handleResize = () => setWindowWidth(window.innerWidth)

  const toggleSideNav = () => {
    if (windowWidth < breakWidth) {
      setSideNavToggled(!sideNavToggled)
    }
  }

  const checkForEdition = location => {
    if (location.includes('editar')) {
      const edit = location.split('/')
      return edit.slice(0, -1).join('/')
    }

    return location
  }

  const assessLocation = (location?: string) => {
    let locationString = ''
    switch (location) {
      // Dashboards:
      case '/admin':
        locationString = 'Dashboard'
        break
      case '/admin/cotizaciones':
        locationString = 'Cotizaciones'
        break
      case '/admin/cotizaciones/crear':
        locationString = 'Crear cotización'
        break
      case '/admin/clientes':
        locationString = 'Clientes'
        break
      case '/admin/clientes/crear':
        locationString = 'Crear Cliente'
        break
      case '/admin/clientes/editar':
        locationString = 'Editar Cliente'
        break
      case '/admin/contactos':
        locationString = 'Contactos'
        break
      case '/admin/contactos/crear':
        locationString = 'Crear Contacto'
        break
      case '/admin/contactos/editar':
        locationString = 'Editar Contacto'
        break
      case '/admin/proveedores':
        locationString = 'Proveedores'
        break
      case '/admin/proveedores/crear':
        locationString = 'Crear Proveedor'
        break
      case '/admin/proveedores/editar':
        locationString = 'Editar Proveedor'
        break
      case '/admin/maquileros':
        locationString = 'Maquileros'
        break
      case '/admin/maquileros/crear':
        locationString = 'Crear Maquilero'
        break
      case '/admin/maquileros/editar':
        locationString = 'Editar Maquilero'
        break
      case '/admin/productos':
        locationString = 'Productos'
        break
      case '/admin/productos/crear':
        locationString = 'Crear Producto'
        break
      case '/admin/productos/editar':
        locationString = 'Editar Producto'
        break
      case '/admin/crawlers':
        locationString = 'Crawlers'
        break
      case '/admin/micuenta':
        locationString = 'Mi Cuenta'
        break

      case '/admin/dashboards/v1':
        locationString = 'Dashboard v.1'
        break
      case '/admin/dashboards/v2':
        locationString = 'Dashboard v.2'
        break
      case '/admin/dashboards/v3':
        locationString = 'Dashboard v.3'
        break
      case '/admin/dashboards/v4':
        locationString = 'Dashboard v.4'
        break
      case '/admin/dashboards/v5':
        locationString = 'Dashboard v.5'
        break
      case '/admin/dashboards/v6':
        locationString = 'Dashboard v.6'
        break

      // Pages
      case '/admin/pages/customers':
        locationString = 'Customers page'
        break
      case '/admin/pages/page-creator':
        locationString = 'Page creator'
        break
      case '/admin/pages/invoice':
        locationString = 'Invoice page'
        break
      case '/admin/pages/chat':
        locationString = 'Chat page'
        break
      case '/admin/pages/support':
        locationString = 'Support page'
        break

      // Profiles
      case '/admin/profile/v1':
        locationString = 'Profile v.1'
        break
      case '/admin/profile/v2':
        locationString = 'Profile v.2'
        break
      case '/admin/profile/extended':
        locationString = 'Extended profile page'
        break

      // CSS
      case '/admin/css/animations':
        locationString = 'Animations'
        break
      case '/admin/css/grid':
        locationString = 'Grid system'
        break
      case '/admin/css/utilities':
        locationString = 'Utilities & helpers'
        break
      case '/admin/css/typography':
        locationString = 'Typography'
        break
      case '/admin/css/colors':
        locationString = 'Colors'
        break
      case '/admin/css/shadows':
        locationString = 'Shadows'
        break
      case '/admin/css/code':
        locationString = 'Code'
        break
      case '/admin/css/icons':
        locationString = 'Icons'
        break
      case '/admin/css/images':
        locationString = 'Images'
        break
      case '/admin/css/masks':
        locationString = 'Masks'
        break
      case '/admin/css/hover':
        locationString = 'Hover'
        break
      case '/admin/css/media':
        locationString = 'Media'
        break

      // Components
      case '/admin/components/buttons':
        locationString = 'Buttons'
        break
      case '/admin/components/cards':
        locationString = 'Cards'
        break
      case '/admin/components/list':
        locationString = 'List group'
        break
      case '/admin/components/panels':
        locationString = 'Panels'
        break
      case '/admin/components/progress':
        locationString = 'Progress bars'
        break
      case '/admin/components/tooltips':
        locationString = 'Tooltips'
        break
      case '/admin/components/popovers':
        locationString = 'Popovers'
        break
      case '/admin/components/tabs':
        locationString = 'Tabs & pills'
        break
      case '/admin/components/tags':
        locationString = 'Tags, labels & badges'
        break
      case '/admin/components/date':
        locationString = 'Date picker'
        break
      case '/admin/components/time':
        locationString = 'Time picker'
        break
      case '/admin/components/stepper':
        locationString = 'Stepper'
        break
      case '/admin/components/pagination':
        locationString = 'Pagination'
        break
      case '/admin/components/collapse':
        locationString = 'Collapse'
        break

      // Forms
      case '/admin/forms/basic':
        locationString = 'Inputs'
        break
      case '/admin/forms/validation':
        locationString = 'Validation'
        break
      case '/admin/forms/extended':
        locationString = 'Forms'
        break

      // Tables
      case '/admin/tables/basic':
        locationString = 'Tables basic'
        break
      case '/admin/tables/extended':
        locationString = 'Tables extended'
        break
      case '/admin/tables/datatable':
        locationString = 'Datatable'
        break

      // Maps
      case '/admin/maps/google':
        locationString = 'Google maps'
        break
      case '/admin/maps/full':
        locationString = 'Full height map'
        break
      case '/admin/maps/vector':
        locationString = 'Vector world map'
        break

      // others
      case '/admin/alerts':
        locationString = 'Alerts'
        break
      case '/admin/modals':
        locationString = 'Modals'
        break
      case '/admin/charts':
        locationString = 'Charts'
        break
      case '/admin/sections':
        locationString = 'Sections'
        break
      case '/admin/calendar':
        locationString = 'Full page calendar'
        break
      default:
    }

    setCurrentPage(locationString)
  }

  const dynamicLeftPadding = {
    paddingLeft: windowWidth > breakWidth ? '240px' : '0',
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>Berit Publicidad{currentPage ? ` - ${currentPage}` : ''}</title>
      </Helmet>
      <div className="app">
        <div className="white-skin">
          <SideNavigation
            breakWidth={breakWidth}
            style={{ transition: 'all 0.3s' }}
            triggerOpening={sideNavToggled}
            onLinkClick={() => toggleSideNav()}
          />
        </div>

        <div className="flexible-content white-skin">
          <TopNavigation
            toggle={windowWidth < breakWidth}
            onSideNavToggleClick={toggleSideNav}
            routeName={currentPage}
            className="white-skin"
          />

          <main style={{ ...dynamicLeftPadding, margin: '6rem 3%' }}>
            <AdminProtectedRoutes />
          </main>

          <Copyrights
            style={{
              ...dynamicLeftPadding,
              position: 'fixed',
              width: '100%',
            }}
            className="d-none d-lg-block"
          />
        </div>
      </div>

      <ImageModal />
      <ShareModal />
      <ToastContainer autoClose={10000} />
    </>
  )
}

export default (Admin: () => React$Node)
