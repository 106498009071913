/* eslint-disable import/order */
// @flow
import { Route, Switch } from 'react-router-dom'

// Berit
import ContactosCrear from 'components/ContactosCrear'
import CotizacionCrear from 'components/CotizacionCrear'
import CotizacionVer from 'components/CotizacionVer'
import Crawler from 'components/Crawler'
import Crawlers from 'components/Crawlers'
import Dashboard from 'components/Dashboard'
import ClientesCrear from 'components/ClientesCrear'
import MiCuenta from 'components/MiCuenta'
import MaquilerosCrear from 'components/MaquilerosCrear'
import PedidosCrear from 'components/PedidosCrear'
import PedidoVer from 'components/PedidoVer'
import ProductosCrear from 'components/ProductosCrear'
import ProveedoresCrear from 'components/ProveedoresCrear'
import VerEntidad from 'components/VerEntidad'

const fourtOFour = () => <h1 className="text-center">404</h1>

const ProtectedRoutes = () => (
  <Switch>
    <Route path="/admin" exact component={Dashboard} />
    <Route
      path={[
        '/admin/pedidos/seguimiento/:pedidoId([0-9]+)',
        '/admin/pedidos/revisar/:pedidoId([0-9]+)',
      ]}
      exact
      component={PedidoVer}
    />
    <Route
      path={['/admin/pedidos/crear', '/admin/pedidos/crear/:cotId']}
      exact
      component={PedidosCrear}
    />

    <Route
      path="/admin/:entity(cotizaciones|clientes|contactos|proveedores|maquileros|productos|pedidos)"
      exact
      component={VerEntidad}
    />

    <Route path="/admin/clientes/crear" exact component={ClientesCrear} />
    <Route path="/admin/clientes/editar/:id" exact component={ClientesCrear} />
    <Route path="/admin/contactos/crear" exact component={ContactosCrear} />
    <Route path="/admin/contactos/editar/:id" exact component={ContactosCrear} />
    <Route path="/admin/proveedores/crear" exact component={ProveedoresCrear} />
    <Route path="/admin/proveedores/editar/:id" exact component={ProveedoresCrear} />
    <Route path="/admin/maquileros/crear" exact component={MaquilerosCrear} />
    <Route path="/admin/maquileros/editar/:id" exact component={MaquilerosCrear} />
    <Route path="/admin/productos/crear" exact component={ProductosCrear} />
    <Route path="/admin/productos/editar/:bsku" exact component={ProductosCrear} />
    <Route path="/admin/crawlers" exact component={Crawlers} />
    <Route path="/admin/crawler/4" exact component={Crawler} />
    <Route path="/admin/micuenta" exact component={MiCuenta} />
    <Route
      path={['/admin/cotizaciones/crear', '/admin/cotizaciones/editar/:cotizacionId([0-9]+)']}
      exact
      component={CotizacionCrear}
    />
    <Route path="/admin/cotizaciones/ver/:cotizacionId([0-9]+)" exact component={CotizacionVer} />

    <Route component={fourtOFour} />
  </Switch>
)

export default (ProtectedRoutes: () => React$Node)
