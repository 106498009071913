// @flow

import { formatPrecio } from 'utils/cotizacionesUtils'

import './VerTotal.scss'

type verTotalProps = {
  productos: any,
  isCotizacion?: boolean,
}

const VerTotal = ({ productos, isCotizacion = false }: verTotalProps) => {
  const getAllTotals = key => {
    const totalByItem =
      (productos?.length ?? 0) > 0
        ? productos?.map(product =>
            product.variantes
              .map(variant =>
                isCotizacion
                  ? variant.cantidad * variant.precioUnitario
                  : variant.cantidad * variant.precioUnitarioCliente
              )
              .reduce((acc, curr) => acc + curr)
          )
        : ''

    const total = Array.isArray(totalByItem)
      ? Number(totalByItem.reduce((acc, curr) => acc + curr)).toFixed(2)
      : totalByItem

    return Number(total)
  }

  const allTotals = getAllTotals('precioUnitario')
  return (
    <div className="mb-3" style={{ backgroundColor: 'rgb(240,240,240)', padding: 10 }}>
      <div className="text-right">
        <strong className="verTotalSize">Subtotal</strong>
        <span className="verTotalSize">{formatPrecio(allTotals)}</span>
      </div>
      <div className="text-right">
        <strong className="verTotalSize">IVA</strong>
        <span className="verTotalSize">{formatPrecio(allTotals * 0.16)}</span>
      </div>
      <div className="text-right">
        <strong className="verTotalSize">Total</strong>
        <span className="verTotalSize">{formatPrecio(allTotals * 1.16)}</span>
      </div>
    </div>
  )
}

export default (VerTotal: verTotalProps => React$Node)
