// @flow
import moment from 'moment'

type MainDataProps = {
  [number]: number[],
}
type ChartDataProps = {
  month: string,
  entity: number,
}

type ColorsType = { [month: string]: string }

const Months: { [index: string | number]: string } = {
  [`${0}`]: 'Enero',
  [`${1}`]: 'Febrero',
  [`${2}`]: 'Marzo',
  [`${3}`]: 'Abril',
  [`${4}`]: 'Mayo',
  [`${5}`]: 'Junio',
  [`${6}`]: 'Julio',
  [`${7}`]: 'Agosto',
  [`${8}`]: 'Septiembre',
  [`${9}`]: 'Octubre',
  [`${10}`]: 'Noviembre',
  [`${11}`]: 'Diciembre',
}

const colors: ColorsType = {
  Enero: 'rgba(255, 0, 0, 0.4)',
  Febrero: 'rgba(0, 0, 255, 0.4)',
  Marzo: 'rgba(194, 207, 0, 0.4)',
  Abril: 'rgba(255, 0, 255, 0.4)',
  Mayo: 'rgba(0, 255, 0, 0.4)',
  Junio: 'rgba(158, 0, 255, 0.4)',
  Julio: 'rgba(255, 0, 0, 0.4)',
  Agosto: 'rgba(0, 0, 255, 0.4)',
  Septiembre: 'rgba(194, 207, 0, 0.4)',
  Octubre: 'rgba(255, 0, 255, 0.4)',
  Noviembre: 'rgba(0, 255, 0, 0.4)',
  Diciembre: 'rgba(158, 0, 255, 0.4)',
}
const colors2: ColorsType = {
  Enero: 'rgba(1, 2, 22, 0.4)',
  Febrero: 'rgba(99, 12, 23, 0.4)',
  Marzo: 'rgba(14, 207, 95, 0.4)',
  Abril: 'rgba(25, 25, 235, 0.4)',
  Mayo: 'rgba(0, 255, 223, 0.4)',
  Junio: 'rgba(158, 42, 255, 0.4)',
  Julio: 'rgba(255, 11, 44, 0.4)',
  Agosto: 'rgba(0, 67, 255, 0.4)',
  Septiembre: 'rgba(194, 207, 99, 0.4)',
  Octubre: 'rgba(25, 51, 25, 0.4)',
  Noviembre: 'rgba(150, 255, 78, 0.4)',
  Diciembre: 'rgba(158, 225, 24, 0.4)',
}

const colorMonths = (cotizaciones, name) =>
  cotizaciones.map((cotizacion, index) =>
    name === 'Cotizaciones' ? colors[cotizacion.month] : colors2[cotizacion.month]
  )

const chartLabels = cotizaciones => cotizaciones.map((cotizacion, index) => cotizacion.month)

const chartValues = cotizaciones => cotizaciones.map((cotizacion, index) => cotizacion.entity)

export const getChartData = (cotizaciones, name) => ({
  labels: chartLabels(cotizaciones),
  datasets: [
    {
      label: 'Indice de cotizaciones',
      data: chartValues(cotizaciones),
      backgroundColor: colorMonths(cotizaciones, name),
      borderWidth: 2,
      borderColor: colorMonths(cotizaciones, name),
    },
  ],
})

const getMonthsAgoFormat = (format: string, monthsAgo: number = 6) =>
  +moment().subtract(monthsAgo, 'months').format(`${format}`) - (format === 'M' ? 1 : 0)

export const parseBarChart = (mainData: MainDataProps, name: string) => {
  if (mainData) {
    const yearNow = getMonthsAgoFormat('YYYY', 0)
    const sixMonthsAgoYear = getMonthsAgoFormat('YYYY')

    const chartData: ChartDataProps[] = []
    for (let index = 6; index > 0; index--) {
      const monthIndex = getMonthsAgoFormat('M', index)
      const currentYear = getMonthsAgoFormat('YYYY', index)

      chartData.push({
        month: Months[monthIndex + 1],
        entity: mainData[currentYear][monthIndex + 1],
      })
    }

    if ((mainData[yearNow] && yearNow === sixMonthsAgoYear) || yearNow > sixMonthsAgoYear) {
      return {
        table: chartData,
        chart: getChartData(chartData, name),
        name: name.toLowerCase(),
      }
    }
  }
}
