// @flow
import moment from 'moment'

import { entity } from 'redux/constants'
import { configUtils } from 'utils'

const convertArrayOfObjectsToCSV = (array, entityName) => {
  let result

  const columnDelimiter = ', '
  const lineDelimiter = '\n'

  const keys = Object.keys(array.rows[0])

  const getCsv = keysFiltered => {
    result = ''
    result += keysFiltered.join(columnDelimiter)
    result += lineDelimiter

    array.rows.forEach(item => {
      let ctr = 0
      keysFiltered.forEach(key => {
        if (ctr > 0) result += columnDelimiter
        result +=
          typeof item[key] === 'object'
            ? item[key].props?.children?.props?.children
            : typeof item[key] === 'string'
            ? key === 'fechaCreacion' || key === 'ultimaModificacion'
              ? moment(item[key]).format('DD/MMM/YYYY hh:mma').replace('.', '')
              : item[key].replace(/, /g, ' • ')
            : item[key]

        ctr++
      })
      result += lineDelimiter
    })

    return result
  }

  switch (entityName) {
    case entity.cotizacion: {
      const keysFiltered = keys.filter(
        row =>
          row !== 'cotizacionJson' &&
          row !== 'productosJson' &&
          row !== 'hash' &&
          row !== 'cotizacion'
      )

      return getCsv(keysFiltered)
    }
    case entity.pedido: {
      const keysFiltered = keys.filter(
        row =>
          row !== 'pedidoId' &&
          row !== 'contactos' &&
          row !== 'sucursal' &&
          row !== 'rfc' &&
          row !== 'cliente'
      )

      return getCsv(keysFiltered)
    }
    case entity.cliente: {
      const keysFiltered = keys.filter(
        row =>
          row !== 'clienteId' &&
          row !== 'contactos' &&
          row !== 'entregaDireccion' &&
          row !== 'productos' &&
          row !== 'cliente' &&
          row !== 'vendedor'
      )

      return getCsv(keysFiltered)
    }
    case entity.contacto: {
      const keysFiltered = keys.filter(
        row =>
          row !== 'contactoId' &&
          row !== 'contactos' &&
          row !== 'entregaDireccion' &&
          row !== 'productos' &&
          row !== 'sucursal' &&
          row !== 'rfc' &&
          row !== 'vendedor'
      )

      return getCsv(keysFiltered)
    }
    case entity.proveedor: {
      const keysFiltered = keys.filter(
        row =>
          row !== 'proveedorId' &&
          row !== 'contactos' &&
          row !== 'entregaDireccion' &&
          row !== 'productos' &&
          row !== 'sucursal' &&
          row !== 'rfc' &&
          row !== 'cliente' &&
          row !== 'vendedor'
      )

      return getCsv(keysFiltered)
    }
    case entity.maquilero: {
      const keysFiltered = keys.filter(
        row =>
          row !== 'maquileroId' &&
          row !== 'contactos' &&
          row !== 'entregaDireccion' &&
          row !== 'productos' &&
          row !== 'cliente' &&
          row !== 'vendedor'
      )

      return getCsv(keysFiltered)
    }
    case entity.producto: {
      const keysFiltered = keys.filter(
        row =>
          row !== 'productoId' &&
          row !== 'categoriaId' &&
          row !== 'imagen' &&
          row !== 'contactos' &&
          row !== 'rfc' &&
          row !== 'entregaDireccion' &&
          row !== 'sucursal' &&
          row !== 'descripcion' &&
          row !== 'productos' &&
          row !== 'cliente' &&
          row !== 'vendedor'
      )

      return getCsv(keysFiltered)
    }
    default:
      break
  }
}

export const downloadTableCSV = (array, entityName) => {
  const link = document.createElement('a')
  const csv = convertArrayOfObjectsToCSV(array, entityName)
  if (csv == null) return

  const filename = `${entityName}_${moment().format('DDMMMYYYY_hhmmssa').replace('.', '')}.csv`
  const encoding = 'data:text/csv;charset=utf-8,%EF%BB%BF'

  link.setAttribute('href', encoding + encodeURI(csv))
  link.setAttribute('download', filename)
  link.click()
}

export const getImageThumbnail = (path: string) => `${configUtils.createConfig().beritImg}${path}`
