// @flow
import Modal from '@material-ui/core/Modal'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { MODAL_IMG_TOGGLE } from 'redux/constants'
import { getModalImageState } from 'redux/selectors/stateSelectors'

const ImageModal = () => {
  const dispatch = useDispatch()
  const {
    isOpen,
    data: { src },
  } = useSelector(getModalImageState, shallowEqual)

  const handleClose = () => dispatch({ type: MODAL_IMG_TOGGLE })

  const modalStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  }
  return (
    <Modal style={{ ...modalStyles }} open={isOpen} onClose={handleClose}>
      <div
        style={{
          position: 'absolute',
          width: '45%',
        }}
      >
        <img src={src} alt={src} style={{ width: '100%', height: '100%' }} />
      </div>
    </Modal>
  )
}

export default (ImageModal: () => React$Node)
