// @flow
import { MODAL_SET, MODAL_TOGGLE } from 'redux/constants'

const initialState = {
  isOpen: false,
  data: {
    hash: 0,
    status: 0,
  },
}

type ModalTypes = ModalState

export type ModalAction = {
  +type: string,
  payload: {
    status: number | string,
    hash: number,
  },
}

const modalReducer = (state: ModalTypes = initialState, action: ModalAction) => {
  switch (action.type) {
    case MODAL_SET:
      return {
        ...state,
        data: action.payload,
      }
    case MODAL_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    default:
      return state
  }
}

export default (modalReducer: (state: ModalState, action: ModalAction) => ModalTypes)
