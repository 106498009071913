// @flow
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
} from 'mdbreact/dist/mdbreact.esm'
import { Link } from 'react-router-dom'

/*
fetch('https://www.promoline.com.mx/Main/Home/getProductDefault', {
  headers: { 'content-type': 'application/json; charset=UTF-8' },
  body: '{"lista":"7","mostrar":1000,"pagina":1,"preciomin":0,"preciomax":0,"invsku":0,"clavemadre":0,"transito":0,"transitom":0,"tipom":"733686d5-2d6a-4758-9dd9-e403e2138bbc","porcentaje":"0","ordenar":"ClaveM_ASC","haswebp":true}',
  method: 'POST',
  credentials: 'include'
})
  .then(response => response.json())
  .then(json => window.prompt('Copia el siguiente texto: Ctrl+C', JSON.stringify(json)))
*/

/* fetch('https://4promotional.net/productos?opcBusquedaAvanzada=0&buscar=')
  .then(response => response.text())
  .then(text => {
    console.warn(text)
  })

const fetchPromoline = async () => {
  console.warn('Promoline response', response)
} */

const Crawlers = () => {
  return (
    <div className="Crawlers">
      <h2>Crawlers</h2>
      <MDBCol md="4" className="mb-4">
        {/* <h4 id='' className='mb-4 font-weight-bold dark-grey-text'>
        <strong>Basic examplen</strong>
      </h4> */}

        <section>
          <MDBCard>
            <MDBCardImage
              className="img-fluid"
              src="/img/proveedor_4.png"
              waves={true}
              alt="For Promotional"
            />
            <MDBCardBody>
              <MDBCardTitle>
                <strong>For Promotional</strong>
              </MDBCardTitle>
              <MDBCardText>
                Última ejecución: <b>19 febrero, 2021</b>
              </MDBCardText>
              <Link to="/admin/crawler/4" className="d-flex justify-content-end">
                <MDBBtn color="pink" rounded>
                  Ir al crawler
                </MDBBtn>
              </Link>
            </MDBCardBody>
          </MDBCard>
        </section>
      </MDBCol>
    </div>
  )
}

export default (Crawlers: () => React$Node)
