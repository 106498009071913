// @flow
import Chip from '@material-ui/core/Chip'

import BeritAutocomplete from 'components/BeritAutocomplete'
import './BeritSelectObject.scss'

type BeritTagAutocompleteProps = {
  autoSelect?: boolean,
  className?: string,
  control: Control,
  disableClearable?: boolean,
  emptyOption?: {
    [key: string]: any,
  },
  filterSelectedOptions?: boolean,
  getOptionDisabled?: any => boolean,
  getOptionLabel?: any => string,
  label?: string,
  multiple?: boolean,
  name: string,
  noOptionsText?: string,
  options: any[],
  renderTags?: any => React$Node,
  rules?: string | Rules,
}

const BeritTagAutocomplete = ({
  className = '',
  control,
  disableClearable = true,
  emptyOption,
  filterSelectedOptions = true,
  getOptionDisabled = () => false,
  label,
  multiple = true,
  name,
  noOptionsText,
  options,
  rules,
  ...beritAutocompleteProps
}: BeritTagAutocompleteProps) => {
  return (
    <BeritAutocomplete
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            key={option.id}
            label={`${option.prefijo} ${option.nombre}${
              option.correo ? ` (${option.correo})` : ''
            }`}
            {...getTagProps({ index })}
          />
        ))
      }
      getOptionLabel={option =>
        option.nombre &&
        `${option.prefijo} ${option.nombre}${option.correo ? ` (${option.correo})` : ''}`
      }
      {...beritAutocompleteProps}
      className={`BeritTagAutocomplete ${className}`}
      control={control}
      disableClearable={disableClearable}
      emptyOption={emptyOption}
      filterSelectedOptions={filterSelectedOptions}
      getOptionDisabled={getOptionDisabled}
      label={label}
      multiple={multiple}
      name={name}
      noOptionsText={noOptionsText}
      options={options}
      rules={rules}
    />
  )
}

export default (BeritTagAutocomplete: BeritTagAutocompleteProps => React$Node)
