// @flow

import Skeleton from '@material-ui/core/Skeleton'
import { MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useMemo } from 'react'
import { useParams } from 'react-router'

import Entrega from './Entrega'
import Facturacion from './Facturacion'
import BeritAutocomplete from 'components/BeritAutocomplete'
import BeritCheckbox from 'components/BeritCheckbox'
import BeritDatePicker from 'components/BeritDatePicker'
import BeritInput from 'components/BeritInput'
import BeritSelectObject from 'components/BeritSelectObject'
import BeritTagAutocomplete from 'components/BeritTagAutocomplete'
import SubheaderBar from 'components/SubheaderBar'
import { useGet } from 'utils'

const emptyOption = { id: 0, nombre: '' }
type PedidoEditarHeaderProps = {
  banner: string,
  finishDate: boolean,
  form: {
    control: Control,
    reset: Reset,
    watch: Watch,
    setValue: SetValue,
    getValues: GetValues,
  },
  isEdit?: boolean,
  pedido: Pedido | any,
  permisos?: {
    [string]: boolean,
  },
  setFinishDate: (boolean: boolean) => void,
}

const PedidoEditarHeader = ({
  isEdit = false,
  banner,
  permisos,
  pedido,
  finishDate,
  setFinishDate,
  form,
}: PedidoEditarHeaderProps) => {
  const { control, watch, setValue } = form
  const { pedidoId } = useParams()
  const clientes: Cliente[] = useGet('clientes')
  const contactos: Contacto[] = useGet('contactos')
  const empleados: Empleado[] = useGet('empleados')

  const {
    cliente: { id: clienteId },
    vendedor: { id: vendedorId },
  } = watch()

  const empleadosOptions = useMemo(
    () => empleados.sort((a, b) => a.nombre.localeCompare(b.nombre)),
    [empleados]
  )

  const clienteOptions = useMemo(
    () =>
      clientes
        .sort((a, b) => -b.nombre.localeCompare(a.nombre))
        .sort((a, b) => -b.asignacion.localeCompare(a.asignacion)),
    [clientes]
  )

  const clienteSelected = useMemo(
    () => clientes.find(cliente => cliente.id === clienteId),
    [clienteId, clientes]
  )

  const rfcOptions = useMemo(() => {
    const rfcOptions =
      clienteSelected?.rfc.map(rfc => ({
        ...rfc,
        id: rfc.rfcId,
      })) ?? []

    const rfcs = [
      { id: 0, rfcId: 0, nombre: 'Sin Factura', rfc: '', razonSocial: '', direccion: '' },
      ...rfcOptions,
    ]
    return rfcs
  }, [clienteSelected?.rfc])

  const sucursalOptions = useMemo(
    () =>
      clienteSelected?.sucursal.map(sucursal => ({
        ...sucursal,
        id: sucursal.sucursalId,
      })) ?? [],
    [clienteSelected?.sucursal]
  )

  const contactosOptions = contactos.filter(contacto => contacto.cliente.id === clienteId)

  return (
    <>
      <MDBRow>
        <MDBCol middle className="logo" size="12" md="4">
          <img src={banner} alt="Banner Berit" className="pedidos-banner-berit" />
        </MDBCol>
        <MDBCol size="12" md="8">
          <MDBRow>
            <MDBCol size="6">
              <BeritInput
                control={control}
                name="pedidoBerit"
                label="No. PEDIDO C/F"
                onChange={e => setValue('pedidoBerit', Number(e.target.value))}
                rules={{
                  require: 'Este campo es obligatorio',
                  valueAsNumber: true,
                  validate: {
                    positive: v => Number(v) > 0 || 'Proporciona un Id válido',
                  },
                }}
                type="number"
              />
            </MDBCol>
            <MDBCol size="6">
              <BeritDatePicker
                control={control}
                label="Fecha del pedido"
                name="fechaPedido"
                rules="La fecha del pedido es obligatoria"
              />
            </MDBCol>

            <MDBCol size="6">
              {empleadosOptions.length > 1 && vendedorId ? (
                <BeritSelectObject
                  control={control}
                  label="Vendedor"
                  name="vendedor"
                  options={empleadosOptions}
                  rules="Elige un vendedor"
                />
              ) : (
                <Skeleton height={60} animation="wave" />
              )}
              <BeritInput control={control} name="ordenCompra" label="Órden de compra" rules="" />
            </MDBCol>
            <MDBCol size="6">
              <BeritDatePicker
                control={control}
                rules="La fecha de entrega es obligatoria"
                name="fechaEntregaIni"
                label="Fecha de entrega al cliente"
              />
              {!finishDate ? (
                <BeritCheckbox
                  control={control}
                  name="hasFechaEntregaFin"
                  label="Fecha de entrega final"
                  onChange={() => setFinishDate(!finishDate)}
                />
              ) : (
                <MDBRow>
                  <MDBCol size="10">
                    <BeritDatePicker
                      control={control}
                      name="fechaEntregaClienteFin"
                      label="Fecha de entrega final al cliente"
                    />
                  </MDBCol>
                  <MDBCol size="2">
                    <i
                      className="fas fa-times-circle removeTimes-md"
                      title="Quitar fecha de entrega final al cliente"
                      onClick={() => setFinishDate(false)}
                    />
                  </MDBCol>
                </MDBRow>
              )}
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12">
          {clienteOptions.length > 1 ? (
            <BeritAutocomplete
              control={control}
              emptyOption={{ id: 0, nombre: '', asignacion: '' }}
              groupBy={option => option.asignacion}
              label="Cliente"
              name="cliente"
              noOptionsText="No se encontraron clientes"
              onChange={(_, cliente, reason) => {
                if (reason === 'clear') {
                  setValue('rfc', rfcOptions[0] ?? emptyOption)
                  setValue('sucursal', cliente?.sucursal[0] ?? emptyOption)
                } else {
                  setValue('cliente', cliente, { shouldValidate: true })
                  setValue('rfc', rfcOptions[0] ?? emptyOption)
                  setValue('sucursal', cliente?.sucursal[0] ?? emptyOption)
                }
                setValue('contactos', [])
              }}
              options={clienteOptions}
              rules="El cliente es obligatorio"
            />
          ) : (
            <Skeleton height={60} animation="wave" />
          )}
        </MDBCol>
      </MDBRow>

      {clienteId > 0 && (
        <>
          {!pedidoId && (
            <>
              <SubheaderBar label="Contacto" />

              <MDBRow>
                <MDBCol className="BeritAutocomplete">
                  <BeritTagAutocomplete
                    control={control}
                    label="Buscar Contactos"
                    name="contactos"
                    noOptionsText="No se encontraron contactos"
                    options={contactosOptions}
                  />
                </MDBCol>
              </MDBRow>
            </>
          )}

          <Entrega form={form} sucursalOptions={sucursalOptions} />

          <Facturacion form={form} rfcOptions={rfcOptions} />
        </>
      )}
    </>
  )
}

export default (PedidoEditarHeader: PedidoEditarHeaderProps => React$Node)
