// @flow
import { useLocation } from 'react-router'

import CotizacionHeaderCalc from './CotizacionHeaderCalc'
import MaquilerosFieldsProductRow from './MaquilerosFieldsProductRow'

type CalculadoraHeaderProps = {
  bsku: string,
}

const CalculadoraHeader = ({ bsku }: CalculadoraHeaderProps) => {
  const { pathname } = useLocation()

  return pathname.includes('/admin/pedidos/crear') ? (
    <MaquilerosFieldsProductRow bsku={bsku} />
  ) : (
    <CotizacionHeaderCalc bsku={bsku} />
  )
}

export default (CalculadoraHeader: CalculadoraHeaderProps => React$Node)
