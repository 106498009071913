// @flow
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { FilePond, registerPlugin } from 'react-filepond'
import { Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { configUtils } from 'utils'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css'
import 'filepond/dist/filepond.min.css'
import './ImageUploader.scss'

type ImageUploaderTypes = {
  name: string,
  form: Form,
  maxFiles?: number,
  isEdit: boolean,
}

const ImageUploader = ({ name, form, maxFiles = 12, isEdit }: ImageUploaderTypes) => {
  const { control, setValue } = form
  const dispatch = useDispatch()

  registerPlugin(FilePondPluginImagePreview, FilePondPluginFilePoster, FilePondPluginFileMetadata)

  const uploadsStart = file =>
    !file.getMetadata('id') && dispatch({ type: `${name.toLocaleUpperCase()}_SUBMIT_REQUEST` })

  const uploadsComplete = () => dispatch({ type: `${name.toLocaleUpperCase()}_SUBMIT_SUCCESS` })

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules="Este campo es obligatorio"
        render={({ field: { ref, value, ...field }, fieldState: { invalid, error } }) => (
          <FilePond
            files={value}
            allowMultiple={true}
            allowReorder={true}
            allowProcess={true}
            allowFilePoster={true}
            maxFiles={maxFiles}
            labelIdle={`Arrastra aquí tus imágenes,
          <span class="filepond--label-action">Elegir imágenes...</span>.`}
            className="FilePondCustom"
            onaddfilestart={uploadsStart}
            onprocessfiles={() => uploadsComplete()}
            server={{
              url: `${configUtils.createConfig().beritImg}upload/`,
              load: (source, load, error) => {
                if (source) {
                  fetch(source, {
                    mode: 'no-cors',
                  })
                    .then(res => {
                      return res.blob()
                    })
                    .then(blob => {
                      return load(blob)
                    })
                    .catch(error)
                }
              },
            }}
            maxParallelUploads={maxFiles}
            onprocessfile={_ => isEdit && uploadsComplete()}
            onupdatefiles={fileItems => setValue(name, fileItems)}
            filePosterHeight={200}
            imagePreviewHeight={200}
          />
        )}
      />
    </>
  )
}
/**
 *  ==ImageUploader Props==
 * - name: This is the name of the controller and must be a string. Also this prop must an array value from RHF (Required):
 * e.g: [ { source: 'url', options: { type: 'local' } } ]
 * - form: The form prop it is the form property extracted from useForm() hook from RHF(Required).
 * - maxFiles: This is the max number of files that you want to allow in the component, by default are 12 files, this must be a number.
 */
export default (ImageUploader: ImageUploaderTypes => React$Node)
