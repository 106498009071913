// @flow
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBMask,
  MDBRow,
  MDBView,
} from 'mdbreact/dist/mdbreact.esm'
import { Helmet } from 'react-helmet'

import Header from './Header'

import logoBerit from 'assets/img/logo-berit.png'

const Login = () => {
  return (
    <>
      <Helmet>
        <title>Berit Publicidad - Soluciones empresariales</title>
      </Helmet>
      <div className="classic-form-page Login">
        <Header />

        <MDBView>
          <MDBMask
            className="d-flex justify-content-center align-items-center"
            overlay="blue-grey-strong"
          >
            <MDBContainer>
              <MDBRow>
                <MDBCol md="10" lg="6" xl="5" sm="12" className="mx-auto">
                  <MDBCard>
                    <MDBCardBody>
                      <a href="http://beritpromocionales.com">
                        <img src={logoBerit} alt="Berit" style={{ width: '100%' }} />
                      </a>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </div>
    </>
  )
}

export default (Login: () => React$Node)
