// Actions
export const API_ADD_ELEMENT = 'API_ADD_ELEMENT'
export const API_UPDATE_ELEMENT = 'API_UPDATE_ELEMENT'
export const API_UPDATE_SINGLE_ELEMENT = 'API_UPDATE_SINGLE_ELEMENT'

export const COTIZACIONES_SET_USER_INFO = 'COTIZACIONES_SET_USER_INFO'
export const COTIZACIONES_SET_COTIZACION = 'COTIZACIONES_SET_COTIZACION'
export const COTIZACIONES_ADD_VARIANTE = 'COTIZACIONES_ADD_VARIANTE'
export const COTIZACION_CHANGE_PRODUCT_DESCRIPTION = 'COTIZACION_CHANGE_PRODUCT_DESCRIPTION'
export const COTIZACION_CLEAR_COTIZACIONES = 'COTIZACION_CLEAR_COTIZACIONES'
export const COTIZACION_DELETE_PRODUCT = 'COTIZACION_DELETE_PRODUCT'

export const BUSQUEDA_TYPING = 'BUSQUEDA_TYPING'
export const CHANGE_COLOR_TALLA_VARIANTE = 'CHANGE_COLOR_TALLA_VARIANTE'
export const CHANGE_COLOR_TALLA_VARIANTE_PEDIDO = 'CHANGE_COLOR_TALLA_VARIANTE_PEDIDO'
export const COTIZACION_ADD_PRODUCT = 'COTIZACION_ADD_PRODUCT'
export const COTIZACION_REFRESH_PRODUCT = 'COTIZACION_REFRESH_PRODUCT'
export const COTIZACION_UPDATE_PRODUCTO_PROP = 'COTIZACION_UPDATE_PRODUCTO_PROP'
export const COTIZACION_CLEAR_PRODUCTS = 'COTIZACION_CLEAR_PRODUCTS'
export const COTIZACION_HEADER_INPUT_CHANGE = 'COTIZACION_HEADER_INPUT_CHANGE'
export const COTIZACION_HEADER_SELECT_CHANGE = 'COTIZACION_HEADER_SELECT_CHANGE'
export const SAVE_BUSQUEDA_RESPONSE = 'SAVE_BUSQUEDA_RESPONSE'
export const SAVE_BUSQUEDA_RESPONSE_PEDIDOS = 'SAVE_BUSQUEDA_RESPONSE_PEDIDOS'
export const CLIENTE_CHANGE = 'CLIENTE_CHANGE'
export const CLIENTE_SEARCH = 'CLIENTE_SEARCH'
export const FECHA_CHANGE = 'FECHA_CHANGE'
export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'
export const UPDATE_CALCULADORA = 'UPDATE_CALCULADORA'
export const UPDATE_CALCULADORA_HEADER = 'UPDATE_CALCULADORA_HEADER'
export const UPDATE_CALCULADORA_PEDIDOS = 'UPDATE_CALCULADORA_PEDIDOS'
export const UPDATE_CALCULADORA_HEADER_PEDIDOS = 'UPDATE_CALCULADORA_HEADER_PEDIDOS'
export const UPDATE_CALCULADORA_PU_VARIANTES = 'UPDATE_CALCULADORA_PU_VARIANTES'
export const UPDATE_CALCULADORA_PU_VARIANTES_PEDIDOS = 'UPDATE_CALCULADORA_PU_VARIANTES_PEDIDOS'

export const EMPLEADO_GETALL_SUCCESS = 'EMPLEADO_GETALL_SUCCESS'
export const EMPLEADO_GETALL_REQUEST = 'EMPLEADO_GETALL_REQUEST'
export const EMPLEADO_GETALL_ERROR = 'EMPLEADO_GETALL_ERROR'

export const CLEAN_ERRORS_MESSAGES = 'CLEAN_ERRORS_MESSAGES'
export const CLEAN_SUCCESS = 'CLEAN_SUCCESS'

export const CLIENTE_GETBYID_REQUEST = 'CLIENTE_GETBYID_REQUEST'
export const CLIENTE_GETBYID_SUCCESS = 'CLIENTE_GETBYID_SUCCESS'
export const CLIENTE_GETBYID_ERROR = 'CLIENTE_GETBYID_ERROR'
export const CLIENTE_SUBMIT_REQUEST = 'CLIENTE_SUBMIT_REQUEST'
export const CLIENTE_SUBMIT_SUCCESS = 'CLIENTE_SUBMIT_SUCCESS'
export const CLIENTE_SUBMIT_ERROR = 'CLIENTE_SUBMIT_ERROR'
export const CLIENTE_GETALL_REQUEST = 'CLIENTE_GETALL_REQUEST'
export const CLIENTE_GETALL_SUCCESS = 'CLIENTE_GETALL_SUCCESS'
export const CLIENTE_GETALL_ERROR = 'CLIENTE_GETALL_ERROR'

export const CONTACTO_GETBYID_REQUEST = 'CONTACTO_GETBYID_REQUEST'
export const CONTACTO_GETBYID_SUCCESS = 'CONTACTO_GETBYID_SUCCESS'
export const CONTACTO_GETBYID_ERROR = 'CONTACTO_GETBYID_ERROR'
export const CONTACTO_SUBMIT_REQUEST = 'CONTACTO_SUBMIT_REQUEST'
export const CONTACTO_SUBMIT_SUCCESS = 'CONTACTO_SUBMIT_SUCCESS'
export const CONTACTO_SUBMIT_ERROR = 'CONTACTO_SUBMIT_ERROR'
export const CONTACTO_GETALL_REQUEST = 'CONTACTO_GETALL_REQUEST'
export const CONTACTO_GETALL_SUCCESS = 'CONTACTO_GETALL_SUCCESS'
export const CONTACTO_GETALL_ERROR = 'CONTACTO_GETALL_ERROR'

export const COTIZACION_GETBYID_REQUEST = 'COTIZACION_GETBYID_REQUEST'
export const COTIZACION_GETBYHASH_REQUEST = 'COTIZACION_GETBYHASH_REQUEST'
export const COTIZACION_GETBYID_SUCCESS = 'COTIZACION_GETBYID_SUCCESS'
export const COTIZACION_GETBYHASH_SUCCESS = 'COTIZACION_GETBYHASH_SUCCESS'
export const COTIZACION_GETBYID_ERROR = 'COTIZACION_GETBYID_ERROR'
export const COTIZACION_GETBYHASH_ERROR = 'COTIZACION_GETBYHASH_ERROR'
export const COTIZACION_SUBMIT_REQUEST = 'COTIZACION_SUBMIT_REQUEST'
export const COTIZACION_SUBMIT_SUCCESS = 'COTIZACION_SUBMIT_SUCCESS'
export const COTIZACION_SUBMIT_ERROR = 'COTIZACION_SUBMIT_ERROR'
export const COTIZACION_GETALL_REQUEST = 'COTIZACION_GETALL_REQUEST'
export const COTIZACION_GETALL_SUCCESS = 'COTIZACION_GETALL_SUCCESS'
export const COTIZACION_GETALL_ERROR = 'COTIZACION_GETALL_ERROR'

export const PRODUCTO_GETBYID_REQUEST = 'PRODUCTO_GETBYID_REQUEST'
export const PRODUCTO_GETBYID_SUCCESS = 'PRODUCTO_GETBYID_SUCCESS'
export const PRODUCTO_GETBYID_ERROR = 'PRODUCTO_GETBYID_ERROR'
export const PRODUCTO_SUBMIT_REQUEST = 'PRODUCTO_SUBMIT_REQUEST'
export const PRODUCTO_SUBMIT_SUCCESS = 'PRODUCTO_SUBMIT_SUCCESS'
export const PRODUCTO_SUBMIT_ERROR = 'PRODUCTO_SUBMIT_ERROR'
export const PRODUCTO_GETALL_REQUEST = 'PRODUCTO_GETALL_REQUEST'
export const PRODUCTO_GETALL_SUCCESS = 'PRODUCTO_GETALL_SUCCESS'
export const PRODUCTO_GETALL_ERROR = 'PRODUCTO_GETALL_ERROR'

export const PROVEEDOR_GETBYID_REQUEST = 'PROVEEDOR_GETBYID_REQUEST'
export const PROVEEDOR_GETBYID_SUCCESS = 'PROVEEDOR_GETBYID_SUCCESS'
export const PROVEEDOR_GETBYID_ERROR = 'PROVEEDOR_GETBYID_ERROR'
export const PROVEEDOR_SUBMIT_REQUEST = 'PROVEEDOR_SUBMIT_REQUEST'
export const PROVEEDOR_SUBMIT_SUCCESS = 'PROVEEDOR_SUBMIT_SUCCESS'
export const PROVEEDOR_SUBMIT_ERROR = 'PROVEEDOR_SUBMIT_ERROR'
export const PROVEEDOR_GETALL_REQUEST = 'PROVEEDOR_GETALL_REQUEST'
export const PROVEEDOR_GETALL_SUCCESS = 'PROVEEDOR_GETALL_SUCCESS'
export const PROVEEDOR_GETALL_ERROR = 'PROVEEDOR_GETALL_ERROR'

export const MAQUILERO_GETBYID_REQUEST = 'MAQUILERO_GETBYID_REQUEST'
export const MAQUILERO_GETBYID_SUCCESS = 'MAQUILERO_GETBYID_SUCCESS'
export const MAQUILERO_GETBYID_ERROR = 'MAQUILERO_GETBYID_ERROR'
export const MAQUILERO_SUBMIT_REQUEST = 'MAQUILERO_SUBMIT_REQUEST'
export const MAQUILERO_SUBMIT_SUCCESS = 'MAQUILERO_SUBMIT_SUCCESS'
export const MAQUILERO_SUBMIT_ERROR = 'MAQUILERO_SUBMIT_ERROR'
export const MAQUILERO_GETALL_REQUEST = 'MAQUILERO_GETALL_REQUEST'
export const MAQUILERO_GETALL_SUCCESS = 'MAQUILERO_GETALL_SUCCESS'
export const MAQUILERO_GETALL_ERROR = 'MAQUILERO_GETALL_ERROR'

export const PEDIDOS_ADD_PRODUCTO = 'PEDIDOS_ADD_PRODUCTO'
export const PEDIDOS_CLEAR_PEDIDOS = 'PEDIDOS_CLEAR_PEDIDO'
export const PEDIDO_ADD_VARIANTE = 'PEDIDO_ADD_VARIANTE'
export const PEDIDO_CHANGE_PRODUCT_DESCRIPTION = 'PEDIDO_CHANGE_PRODUCT_DESCRIPTION'
export const PEDIDO_SAVE_SELECTED_PEDIDO = 'PEDIDO_SAVE_SELECTED_PEDIDO'
export const PEDIDO_DELETE_PRODUCT = 'PEDIDO_DELETE_PRODUCT'

export const PEDIDO_GETBYID_REQUEST = 'PEDIDO_GETBYID_REQUEST'
export const PEDIDO_GETBYID_SUCCESS = 'PEDIDO_GETBYID_SUCCESS'
export const PEDIDO_GETBYID_ERROR = 'PEDIDO_GETBYID_ERROR'
export const PEDIDO_SUBMIT_REQUEST = 'PEDIDO_SUBMIT_REQUEST'
export const PEDIDO_SUBMIT_SUCCESS = 'PEDIDO_SUBMIT_SUCCESS'
export const PEDIDO_SUBMIT_ERROR = 'PEDIDO_SUBMIT_ERROR'
export const PEDIDO_GETALL_REQUEST = 'PEDIDO_GETALL_REQUEST'
export const PEDIDO_GETALL_SUCCESS = 'PEDIDO_GETALL_SUCCESS'
export const PEDIDO_GETALL_ERROR = 'PEDIDO_GETALL_ERROR'

export const REPORTE_GETALL_REQUEST = 'REPORTE_GETALL_REQUEST'
export const REPORTE_GETALL_SUCCESS = 'REPORTE_GETALL_SUCCESS'
export const REPORTE_GETALL_ERROR = 'REPORTE_GETALL_ERROR'

export const SUCURSAL_GETALL_REQUEST = 'SUCURSAL_GETALL_REQUEST'
export const SUCURSAL_GETALL_SUCCESS = 'SUCURSAL_GETALL_SUCCESS'
export const SUCURSAL_GETALL_ERROR = 'SUCURSAL_GETALL_ERROR'

export const TECNICA_GETALL_REQUEST = 'TECNICA_GETALL_REQUEST'
export const TECNICA_GETALL_SUCCESS = 'TECNICA_GETALL_SUCCESS'
export const TECNICA_GETALL_ERROR = 'TECNICA_GETALL_ERROR'

export const MODAL_SET = 'MODAL_SET'
export const MODAL_TOGGLE = 'MODAL_TOGGLE'
export const MODAL_IMG_SET = 'MODAL_IMG_SET'
export const MODAL_IMG_TOGGLE = 'MODAL_IMG_TOGGLE'

// tablecases
export const entity = {
  cotizacion: 'cotizaciones',
  cliente: 'clientes',
  contacto: 'contactos',
  proveedor: 'proveedores',
  maquilero: 'maquileros',
  producto: 'productos',
  pedido: 'pedidos',
}

// table labels
export const LABELS_ES = {
  pagination: ['Anterior', 'Siguiente'],
  infoLabel: ['Mostrando', 'a', 'de', 'Registros'],
  noRecordsFoundLabel: 'No hay registros que coincidan con la busqueda',
  entriesLabel: 'Mostrar Registros',
  searchingLabel: 'Buscar',
  entriesOptions: [50, 100, 200],
}

export const COSTOS_TECNICAS = [
  {
    nombre: 'Baja Temperatura',
    precio: 10,
    id: 1,
  },
  {
    nombre: 'Bordado',
    precio: 8,
    id: 2,
  },
  {
    nombre: 'Brand Patch',
    precio: 25,
    id: 3,
  },
  {
    nombre: 'Corte de Vinil',
    precio: 8,
    id: 4,
  },
  {
    nombre: 'Corte de Vinil Reflejante',
    precio: 15,
    id: 5,
  },
  {
    nombre: 'Encapsulado + Embozado',
    precio: 20,
    id: 6,
  },
  {
    nombre: 'Encapsulado Textil',
    precio: 99.99,
    id: 7,
  },
  {
    nombre: 'Flexografía',
    precio: 99.99,
    id: 8,
  },
  {
    nombre: 'Gota de Resuna / Encapsulado',
    precio: 4.5,
    id: 9,
  },
  {
    nombre: 'Grabado a Color',
    precio: 50,
    id: 10,
  },
  {
    nombre: 'Grabado en Seco / Embozado',
    precio: 12,
    id: 11,
  },
  {
    nombre: 'Grabado Láser',
    precio: 8,
    id: 12,
  },
  {
    nombre: 'Grabado Pantógrado',
    precio: 12,
    id: 13,
  },
  {
    nombre: 'Grabado Punta Diamante',
    precio: 8,
    id: 14,
  },
  {
    nombre: 'Hot Stamping',
    precio: 20,
    id: 15,
  },
  {
    nombre: 'Impresión Digital / Full Color',
    precio: 18,
    id: 16,
  },
  {
    nombre: 'Offset',
    precio: 1,
    id: 17,
  },
  {
    nombre: 'Photoprint',
    precio: 30,
    id: 18,
  },
  {
    nombre: 'Sand Blast',
    precio: 18,
    id: 19,
  },
  {
    nombre: 'Serigrafía',
    precio: 0.8,
    id: 20,
  },
  {
    nombre: 'Serigrafía Textil',
    precio: 0.8,
    id: 21,
  },
  {
    nombre: 'Sublimación',
    precio: 18,
    id: 22,
  },
  {
    nombre: 'Sublimación Textil',
    precio: 50,
    id: 23,
  },
  {
    nombre: 'Tampografía',
    precio: 1.2,
    id: 24,
  },
  {
    nombre: 'Termocalca / Transfer',
    precio: 12,
    id: 25,
  },
  {
    nombre: 'Transfer No Cut',
    precio: 15,
    id: 26,
  },
  {
    nombre: 'Vinil Textil',
    precio: 8,
    id: 27,
  },
  {
    nombre: 'Vinil Textil Reflejante',
    precio: 15,
    id: 28,
  },
  {
    nombre: 'Vitrificante',
    precio: 18,
    id: 29,
  },
]
