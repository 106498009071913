// @flow
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from 'mdbreact/dist/mdbreact.esm'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import ProfileCard from './ProfileCard'
import BeritInline from 'components/BeritInline'
import { getUsuarioState } from 'redux/selectors'

import './MiCuenta.scss'

const hierarchy = {
  '1': 'Visitante',
  '2': 'Cliente',
  '3': 'Empleado',
  '4': 'Administrador',
  '5': 'SuperAdmin',
}

const PV1 = () => {
  const form = useForm()
  const { reset, watch } = form
  const { nombre, departamento } = watch()
  const usuario = useSelector<State, Usuario>(getUsuarioState)

  useEffect(() => {
    reset({ ...usuario, rol: hierarchy[usuario.rol] })
  }, [reset, usuario])

  return (
    <div id="profile-v1" className="mb-5">
      <MDBContainer fluid className="mb-5">
        <section className="section team-section mb-5">
          <MDBRow center className="text-center">
            <MDBCol md="8" className="mb-r">
              <MDBCard cascade className="cascading-admin-card user-card">
                <div className="admin-up d-flex justify-content-start">
                  <MDBIcon icon="users" className="info-color py-4 z-depth-2" />{' '}
                  <div className="data">
                    <h5 className="font-weight-bold dark-grey-text">Tu Perfil</h5>
                  </div>
                </div>
                <MDBCardBody>
                  <MDBRow className="mb-4">
                    <MDBCol lg="4">
                      <BeritInline form={form} label="Nombre" name="nombre" aligned />
                    </MDBCol>
                    <MDBCol lg="4">
                      <BeritInline form={form} label="Prefijo" name="prefijo" aligned />
                    </MDBCol>
                    <MDBCol lg="4">
                      <BeritInline form={form} label="Rol" name="rol" aligned />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-4">
                    <MDBCol md="6">
                      <BeritInline form={form} label="Departamento" name="departamento" aligned />
                    </MDBCol>
                    <MDBCol md="6">
                      <BeritInline form={form} label="Puesto" name="puesto" aligned />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol lg="6" md="12">
                      <BeritInline form={form} label="Teléfono" name="telefono" aligned />
                    </MDBCol>
                    <MDBCol lg="6" md="12">
                      <BeritInline form={form} label="Correo" name="correo" aligned />
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md="4" className="mb-r">
              <ProfileCard nombre={nombre} departamento={departamento} />
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>
    </div>
  )
}

export default (PV1: () => React$Node)
