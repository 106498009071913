// @flow
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import BeritCard from 'components/BeritCard'
import BeritInput from 'components/BeritInput'
import { numberUtils, useToast } from 'utils'

const CostoMaquilero = () => {
  const [totalOp, setTotal] = useState('0')
  const {
    watch,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ defaultValues: { maquileroId: '', costoUnitario: 0, cantidad: 0, total: 0 } })
  const toast = useToast()
  const { cantidad, costoUnitario } = watch()

  useEffect(() => {
    const ccu = cantidad * costoUnitario
    if (ccu > 0) {
      setTotal(numberUtils.round(ccu))
    } else {
      setTotal('0')
    }
  }, [setTotal, cantidad, costoUnitario])

  const onSubmit = costoMaq => {
    const costoTotalMaquilador = {
      ...costoMaq,
      total: totalOp,
    }
    console.warn(costoTotalMaquilador)
  }

  const onError = (error, e) => {
    e.target.className += ' was-submitted'
    toast('ERROR: Por favor, llena correctamente los campos requeridos.', 'error')
    console.error({ errorSubmit: error })
  }

  return (
    <BeritCard classes="mb-5">
      <h5 className="text-center">Maquileros</h5>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
        <MDBRow>
          <MDBCol md="6" sm="12" className="text-center">
            <BeritInput
              control={control}
              errors={errors}
              rules="Este campo es obligatorio"
              name="maquileroId"
              label="Maquilero"
            />
          </MDBCol>

          <MDBCol md="6" sm="12" className="text-center">
            <BeritInput
              control={control}
              errors={errors}
              rules={{
                required: 'Este campo es obligatorio',
                valueAsNumber: true,
              }}
              name="costoUnitario"
              label="Costo unitario"
              type="number"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6" sm="12" className="text-center">
            <BeritInput
              control={control}
              errors={errors}
              rules={{
                required: 'Este campo es obligatorio',
                valueAsNumber: true,
              }}
              name="cantidad"
              iconClass="grey-text"
              label="Cantidad"
              type="number"
            />
          </MDBCol>
          <MDBCol md="6" sm="12">
            <BeritInput
              control={control}
              errors={errors}
              name="total"
              className="text-center font-weight-bold"
              label="Total"
              value={totalOp}
              disabled
            />
          </MDBCol>
        </MDBRow>
        <div className="d-flex justify-content-center">
          <MDBBtn type="submit" color="blue">
            Guardar
          </MDBBtn>
        </div>
      </form>
    </BeritCard>
  )
}

export default (CostoMaquilero: () => React$Node)
